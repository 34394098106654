import React from 'react';
import PropTypes from 'prop-types';
import globalconfig from '../../common/config';
import RavenDataStore from '../../common/ravenDataStore';
import { ModalContainer, ConfirmationDialog } from '../../modals/Modal2';
import { ORDER_STATUS } from '../orders/panels/ActiveOrdersTable/ActiveOrdersTable';
import ProgressOverlay from '../../common/ProgressOverlay';

const ADD_ACCOUNT_TIMEOUT = 15000;
const ADD_SHIPPING_TIMEOUT = 15000;
const ADD_ORDER_TIMEOUT = 15000;
const GET_RATE_PLANS_TIMEOUT = 15000;
const GET_ITEM_TYPES_TIMEOUT = 15000;
const RATE_PLANS_INPUT_NAME_PREFIX = "ratePlanTotal_";
const ACCESSORY_ITEM_TYPE_INPUT_NAME_PREFIX = "accessoryItemTypeTotal_";

export const ORDER_TYPES = {
    RAVEN: "RAVEN",
    RAVEN_GT: "RAVEN_GT",
    RAVEN_GTS: "RAVEN_GTS"
};

export const ACCOUNT_INTEGRATIONS_TYPES = {
    GEOTAB: "GEOTAB",
    NONE: "NONE"
}

export const RATE_PLAN_TYPES = {
    WITH_WIFI: "5GB",
    NO_WIFI: "2GB"
}

export const PRIMARY_SECTION_FOCUS = {
    EXTERNAL_ACCOUNT: "EXTERNAL_ACCOUNT",
    ACCOUNT_INTEGRATIONS_TYPES: "ACCOUNT_INTEGRATIONS_TYPES",
    RAVEN_ACCOUNT: "RAVEN_ACCOUNT",
    ORDER: "ORDER",
    SHIPPING: "SHIPPING",
    NONE: "NONE" // default
}

export default class OrderForm extends React.PureComponent {

    static propTypes = {
        stage: PropTypes.string.isRequired,
        orderForEditMode: PropTypes.object, // set this for edit order mode
        //accountForEditMode: PropTypes.object // accountForEditMode is in state for now
        primarySectionFocus: PropTypes.string,
        ratePlans: PropTypes.array, // will attempt to load if not provided
        itemTypes: PropTypes.array, // will attempt to load if not provided
        onCancel: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        updateUnsavedEditsFound: PropTypes.func,
        setReloadOnDismiss: PropTypes.func
    }

    constructor(props) {
        super(props);

        // DOM References are only used to fine tune the max-heights for smoother expand/collapse animations
        this.accountIntegrationsSectionDOMReference = React.createRef();
        this.externalAccountSectionDOMReference = React.createRef();
        this.ravenAccountSectionDOMReference = React.createRef();
        this.orderSectionDOMReference = React.createRef();
        this.orderUnitsSectionDOMReference = React.createRef();
        this.orderAccessoriesSectionDOMReference = React.createRef();
        this.shippingSectionDOMReference = React.createRef();

        let accountIntegrationsSectionCollapsed = true;
        let externalAccountSectionCollapsed = true;
        let ravenAccountSectionCollapsed = true;
        let orderSectionCollapsed = true;
        let shippingSectionCollapsed = true;
        switch (this.props.primarySectionFocus) {
            case PRIMARY_SECTION_FOCUS.EXTERNAL_ACCOUNT:
                externalAccountSectionCollapsed = false;
                break;
            case PRIMARY_SECTION_FOCUS.ACCOUNT_INTEGRATIONS_TYPES:
                externalAccountSectionCollapsed = false;
                accountIntegrationsSectionCollapsed = false;
                break;
            case PRIMARY_SECTION_FOCUS.RAVEN_ACCOUNT:
                accountIntegrationsSectionCollapsed = false;
                externalAccountSectionCollapsed = false;
                ravenAccountSectionCollapsed = false;
                break;
            case PRIMARY_SECTION_FOCUS.ORDER:
                orderSectionCollapsed = false;
                break;
            case PRIMARY_SECTION_FOCUS.SHIPPING:
                shippingSectionCollapsed = false;
                break;
            case PRIMARY_SECTION_FOCUS.NONE:
            default:
                externalAccountSectionCollapsed = false;
                break;
        }

        const accountExternalId = undefined;

        this.ordersFulfillmentEnabled = false;
        if (globalconfig.features) {
            if (globalconfig.features.orders) {
                this.ordersFulfillmentEnabled = globalconfig.features.orders.fulfillmentEnabled;
            }
        }

        this.primaryActionName = "Add New Order";
        if (!this.ordersFulfillmentEnabled) {
            this.primaryActionName = "Add New Account";
        }

        this.accountExternalIdTitle = "Account Number";
        if (globalconfig.features &&
            globalconfig.features.accounts &&
            globalconfig.features.accounts.externalIdOptionalTitle) {

            this.accountExternalIdTitle = globalconfig.features.accounts.externalIdOptionalTitle;
        }

        this.state = {

            addingAllNewAccountOrderAndShipping: false, // overrides this.isEditMode as primaryFormSubmitAction requests are processed

            externalAccountSectionCollapsed: externalAccountSectionCollapsed,
            externalAccountSectionPending: undefined,
            externalAccountSectionConfirmedForSave: undefined, // the account number is essentially "applied" for add account
            externalAccountSectionSaved: undefined,
            externalAccountSectionErrors: [],

            accountForEditMode: undefined, // populated in either this.prefillFormForEditMode or this.addAccount response
            accountExternalId: accountExternalId,
            accountExternalIdErrorOccurred: undefined,

            accountIntegrationsSectionCollapsed: accountIntegrationsSectionCollapsed,
            accountIntegrationsSectionPending: undefined,
            accountIntegrationsSectionSaved: undefined,
            accountIntegrationsSectionErrors: [],

            accountIntegrations: undefined, // on user select or edit mode account load, this is set to one of ACCOUNT_INTEGRATIONS_TYPES (multiple selections are not support yet, either geotab or none for now)
            accountIntegrationsErrorOccurred: undefined,

            ravenAccountSectionCollapsed: ravenAccountSectionCollapsed,
            ravenAccountSectionPending: undefined,
            ravenAccountSectionSaved: undefined,
            ravenAccountSectionErrors: [],

            email: undefined,
            emailErrorOccurred: undefined,

            welcomeName: undefined,
            welcomeNameErrorOccurred: undefined,

            notes: undefined,
            notesErrorOccurred: undefined,

            sendWelcomeEmail: true,

            ratePlanType: RATE_PLAN_TYPES.WITH_WIFI, // default 5GB WITH_WIFI, other option is 2GB NO_WIFI

            orderSectionCollapsed: orderSectionCollapsed,
            orderSectionPending: undefined,
            ratePlans: this.props.ratePlans,
            itemTypes: this.props.itemTypes,
            orderSectionSaved: undefined,
            orderSectionErrors: [],

            orderForEditMode: this.props.orderForEditMode || undefined, // also populated in this.addOrder response
            orderExternalId: undefined,
            orderExternalIdErrorOccurred: undefined,

            orderType: undefined,
            orderTypeErrorOccurred: undefined,

            totalRavens: undefined,
            totalRavensErrorOccurred: undefined,

            totalAccessories: undefined,
            totalAccessoriesErrorOccurred: undefined,

            totalSDCards: undefined,
            totalSDCardsErrorOccurred: undefined,

            totalBeacons: undefined,

            orderComment: undefined,
            orderCommentErrorOccurred: undefined,

            shippingSectionCollapsed: shippingSectionCollapsed,
            shippingSectionPending: undefined,
            shippingSectionSaved: undefined,
            shippingSectionErrors: [],

            shippingAddressStreetLine1: undefined,
            shippingAddressStreetLine1ErrorOccurred: undefined,

            shippingAddressStreetLine2: undefined,
            shippingAddressStreetLine2ErrorOccurred: undefined,

            shippingAddressCity: undefined,
            shippingAddressCityErrorOccurred: undefined,

            shippingAddressZipCode: undefined,
            shippingAddressZipCodeErrorOccurred: undefined,

            shippingAddressRegion: undefined,
            shippingAddressRegionErrorOccurred: undefined,

            shippingAddressCountry: undefined,
            shippingAddressCountryErrorOccurred: undefined,

            newAccountTemporaryPassword: undefined,

            confirmationDialog: null,

            validateGeotabOnDismiss: false // for edit mode to capture when the Geotab integration is enabled by order type (Geotab GT or GTS) is not selected.
        }

        this.addAccountTimeoutId = undefined;
        this.addShippingTimeoutId = undefined;
        this.addOrderTimeoutId = undefined;
        this.ratePlansTimeoutId = undefined;
        this.itemTypesTimeoutId = undefined;
        this.allowAccessoriesOnlyOrders = true; // RAV-5667 Allow Accessories-Only orders to existing accounts (Beacon or other accessories)          // RAV-5517 Don't allow beacon-only orders for new accounts

        this.ravenDataStore = new RavenDataStore(() => {});
    }

    prefillFormForEditMode = () => {

        if (!this.state.orderForEditMode) {
            return;
        }

        if (!this.state.ratePlans) {
            console.warn("Dynamic loading of rate plans not fully supported in edit mode yet");
            return;
        }

        if (!this.state.itemTypes) {
            console.warn("Dynamic loading of item types not fully supported in edit mode yet");
            return;
        }

        console.log(JSON.stringify(this.state.orderForEditMode));
        const orderAccountExternalId = this.state.orderForEditMode.account ? this.state.orderForEditMode.account.externalId : undefined;

        if (orderAccountExternalId) {

            const orderSectionErrors = [];
            const ratePlans = JSON.parse(JSON.stringify(this.state.ratePlans));
            const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));
            let ratePlanType = undefined;
            let totalRavens = 0;
            let totalAccessories = 0;

            if (this.state.orderForEditMode.items) {

                const ratePlanTotalsByRatePlanId = {};
                this.state.orderForEditMode.items.forEach( (item) => {
                    switch (item.itemType) {
                        //case "RAVEN": (not supported by this form)
                        case "RAVEN_PLUS":
                            if(!ratePlanType){
                                // Assuming that all the items in the order are of the same rate plan type
                                // We can set the rate plan type based on the first item
                                ratePlanType = this.state.ratePlans.find(ratePlan => ratePlan.id === item.ratePlanId).size;
                            }
                            if (!ratePlanTotalsByRatePlanId.hasOwnProperty(item.ratePlanId)) {
                                ratePlanTotalsByRatePlanId[item.ratePlanId] = 0;
                            }
                            ratePlanTotalsByRatePlanId[item.ratePlanId] += 1;
                            totalRavens += 1;        
                            break;
                        default:
                            let itemCategory = undefined;

                            for (const itemTypeIndex in itemTypes) {

                                if (itemTypes[itemTypeIndex].id === item.itemType) {

                                    itemCategory = itemTypes[itemTypeIndex].category;
                                    
                                    if (["ACCESSORY", "BEACON"].includes(itemCategory)) {

                                        if (!itemTypes[itemTypeIndex].hasOwnProperty("quantity")) {
                                            itemTypes[itemTypeIndex].quantity = 0;
                                        }
                                        itemTypes[itemTypeIndex].quantity += 1;
                                        totalAccessories += 1;
                                        break;
                                    }
                                }
                            }
                            if (itemCategory === "DEVICE") {
                                console.error("OrderForm prefillFormForEditMode orderForEditMode items included unsupported ratePlan DEVICE " + item.itemType);
                            }
                            return;
                    }
                });

                for (let ratePlanId in ratePlanTotalsByRatePlanId) {
                    ratePlans.forEach((ratePlan) => {
                        if (ratePlan.id === ratePlanId) {
                            ratePlan.quantity = ratePlanTotalsByRatePlanId[ratePlanId];
                        }
                    });
                }
            }

            const orderExternalId = this.state.orderForEditMode.orderNumber ? this.state.orderForEditMode.orderNumber : undefined;
            let orderExternalIdErrorOccurred = false;
            if (!orderExternalId) {
                orderSectionErrors.push("Order Number not found");
                orderExternalIdErrorOccurred = true;
            }

            const orderType = this.state.orderForEditMode.orderType ? this.state.orderForEditMode.orderType : undefined;

            const orderComment = this.state.orderForEditMode.comment ? this.state.orderForEditMode.comment : undefined;

            this.setState({
                orderSectionPending: false,
                orderSectionErrors: orderSectionErrors,
                orderExternalId: orderExternalId,
                orderType: orderType,
                totalRavens: totalRavens,
                ratePlans: ratePlans,
                totalAccessories: totalAccessories,
                itemTypes: itemTypes.filter(item => item.active || item.quantity > 0), // only active item types or those with quantities (for previous orders that include inactive item types)
                orderComment: orderComment,
                ratePlanType: ratePlanType ? ratePlanType : RATE_PLAN_TYPES.WITH_WIFI,
            });

            this.getAccount(orderAccountExternalId)
            .then(
                (account) => {},
                (error) => {}
            );
        }
    }

    getRatePlans = () => {
        this.setState({
            orderSectionPending: true,
        });

        this.ratePlansTimeoutId = setTimeout(() => {this.timeoutGetRatePlans()}, GET_RATE_PLANS_TIMEOUT);

        this.ravenDataStore.getRatePlans(this.props.stage).then(
            (ratePlans) => {
                clearTimeout(this.ratePlansTimeoutId);
                this.setState({
                    orderSectionPending: false,
                    ratePlans: ratePlans // [{"id":"1","name":"3 year"},{"id":"2","name":"MTM"}]
                })
            },
            (error) => {
                clearTimeout(this.ratePlansTimeoutId);
                this.setState({
                    orderSectionPending: false,
                    orderSectionErrors: [error && error.message ? "Rate plans error: " + error.message : "Rate plans error occurred"]
                });
            }
        );
    }

    getItemTypes = () => {
        this.setState({
            orderSectionPending: true,
        });

        this.itemTypesTimeoutId = setTimeout(() => {this.timeoutGetItemTypes()}, GET_ITEM_TYPES_TIMEOUT);

        this.ravenDataStore.getItemTypes(this.props.stage).then(
            (itemTypes) => {
                clearTimeout(this.itemTypesTimeoutId);
                const activeItemTypes = itemTypes.filter(item => item.active);
                this.setState({
                    orderSectionPending: false,
                    itemTypes: activeItemTypes // [{"id":"RAVEN","name":"string","category":"DEVICE"}]
                })
            },
            (error) => {
                clearTimeout(this.itemTypesTimeoutId);
                this.setState({
                    orderSectionPending: false,
                    orderSectionErrors: [error && error.message ? "Item types error: " + error.message : "Item types error occurred"]
                });
            }
        );
    }

    componentDidMount() {

        this.fineTuneFormSectionMaxHeightAnimations(); // (Fine tunes Technique 1 in https://css-tricks.com/using-css-transitions-auto-dimensions/)

        this.setState({
            externalAccountSectionPending: false,
            ravenAccountSectionPending: false,
            orderSectionPending: false,
            shippingSectionPending: false
        });



        this.prefillFormForEditMode();

        if (this.ordersFulfillmentEnabled) {
            if (!this.state.ratePlans) {
                this.getRatePlans();
            }

            if (!this.state.itemTypes) {
                this.getItemTypes();
            }
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.state.externalAccountSectionCollapsed !== prevState.externalAccountSectionCollapsed ||
            this.state.accountIntegrationsSectionCollapsed !== prevState.accountIntegrationsSectionCollapsed ||
            this.state.ravenAccountSectionCollapsed !== prevState.ravenAccountSectionCollapsed ||
            this.state.orderSectionCollapsed !== prevState.orderSectionCollapsed ||
            this.state.shippingSectionCollapsed !== prevState.shippingSectionCollapsed) {
            this.fineTuneFormSectionMaxHeightAnimations();
        }
    }

    timeoutGetRatePlans = () => {
        this.setState({
            orderSectionErrors: ["It is taking too long to load available rate plans."]
        });
    }

    timeoutGetItemTypes = () => {
        this.setState({
            orderSectionErrors: ["It is taking too long to load available item types."]
        });
    }

    componentWillUnmount() {
        clearTimeout(this.ratePlansTimeoutId);
    }

    SwitchPlanType = (newPlanType) => {
        const ratePlans = JSON.parse(JSON.stringify(this.state.ratePlans));

        const currentActivePlans = ratePlans.filter(plan => plan.size === this.state.ratePlanType);
        
        currentActivePlans.forEach(plan => {
            const correspondingPlan = ratePlans.find(p => p.size === newPlanType && p.name === plan.name);
            
            correspondingPlan.quantity = plan.quantity;
            plan.quantity = 0;
        });

        this.setState({
            ratePlans: ratePlans,
            ratePlanType: newPlanType
        });
    }

    updateAccessriesQuantities = (orderType) => {
        // if(!orderType) return;

        const ratePlans = JSON.parse(JSON.stringify(this.state.ratePlans));
        const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));

        let totalRavens = 0;
        ratePlans.forEach((ratePlan) => {
            if (ratePlan.quantity) {
                totalRavens += ratePlan.quantity;
            }
        });

        let totalAccessories = 0;
        let totalSDCards = 0;
        let totalBeacons = 0;

        itemTypes.forEach((itemType) => {

            // Update included accessories based on totalRavens
            // If current quantity is not equal to the what is required for the order type, set it to the minimum
            if(itemType.included && itemType.included[orderType]){
                const minQuantity = totalRavens * itemType.included[orderType];
                const currentQuantity = itemType.quantity ? itemType.quantity : 0;
                if (currentQuantity !== minQuantity) {
                    itemType.quantity = minQuantity;
                }
            }

            // If the order type is not RAVEN (it's geotab integration), then remove all BEACON accessories
            if(orderType !== ORDER_TYPES.RAVEN){
                if (itemType.id.startsWith("BEACON") && itemType.quantity) {
                    itemType.quantity = 0;
                }
            }

            // update total accessories
            if (itemType.quantity) {
                totalAccessories += itemType.quantity;
                if (itemType.id.startsWith("SD_CARD")) {
                    totalSDCards += itemType.quantity;
                }
                if (itemType.id.startsWith("BEACON")) {
                    totalBeacons += itemType.quantity;
                }
            }
        });

        this.setState({
            totalAccessories: totalAccessories,
            totalSDCards: totalSDCards,
            totalBeacons: totalBeacons,
            itemTypes: itemTypes
        });
    }

    onInputChange = (event) => {

        let totalRavens = 0;
        let totalAccessories = 0;
        let totalSDCards = 0;
        let totalBeacons = 0;

        if (this.props.updateUnsavedEditsFound) {
            this.props.updateUnsavedEditsFound(true); // RAV-1843 basic support for both add and edit order modes for consistent 'discard changes' prompts. This does not support user "undoing" all changes manually.  But it's nice and simple.
        } else {
            console.warn("OrderForm onInputChange updateUnsavedEditsFound function not provided.");
        }

        switch (event.target.name) {
            case "accountExternalId": this.setState({accountExternalId: event.target.value}); return;
            case "accountintegrations":
                const orderType = event.target.value === ACCOUNT_INTEGRATIONS_TYPES.NONE ? ORDER_TYPES.RAVEN : undefined;
                this.setState({
                    accountIntegrations: event.target.value,
                    accountIntegrationsSectionErrors: [],
                    accountExternalIdErrorOccurred: false,
                    // if no integrations, the orderType is RAVEN (otherwise leave orderType undefined to force user choice)
                    orderType: orderType
                });

                this.updateAccessriesQuantities(orderType);

                if (!this.state.accountForEditMode) { // if this is a new order, not editing an existing account's config
                    // auto-expanding remaining sections upon integration selection, similar to accountExternalId's "apply" UX for new orders
                    this.setState({
                        ravenAccountSectionCollapsed: false,
                        orderSectionCollapsed: false,
                        shippingSectionCollapsed: false
                    });
                }
                return;
            case "email": this.setState({email: event.target.value}); return;
            case "welcomeName": this.setState({welcomeName: event.target.value}); return;
            case "notes": this.setState({notes: event.target.value}); return;
            case "sendWelcomeEmail": this.setState({sendWelcomeEmail: event.target.checked}); return;
            case "orderExternalId": this.setState({orderExternalId: event.target.value}); return;
            case "orderComment": this.setState({orderComment: event.target.value}); return;
            case "ratePlanType": this.SwitchPlanType(event.target.checked ? RATE_PLAN_TYPES.NO_WIFI : RATE_PLAN_TYPES.WITH_WIFI); return;
            case "orderType":
                if (this.isEditMode()) {
                    this.setState({orderType: event.target.value}, this.updateGeotabOrderType); // apply change immediately when editing a pre-existing order
                } else {
                    this.setState({orderType: event.target.value});
                }
                this.updateAccessriesQuantities(event.target.value);
            return;
            //case "totalRavens": this.setState({totalRavens: event.target.value}); return;
            case "shippingAddressStreetLine1": this.setState({shippingAddressStreetLine1: event.target.value}); return;
            case "shippingAddressStreetLine2": this.setState({shippingAddressStreetLine2: event.target.value}); return;
            case "shippingAddressCity": this.setState({shippingAddressCity: event.target.value}); return;
            case "shippingAddressZipCode": this.setState({shippingAddressZipCode: event.target.value.toUpperCase()}); return;
            case "shippingAddressRegion": this.setState({shippingAddressRegion: event.target.value}); return;
            case "shippingAddressCountry": this.setState({shippingAddressCountry: event.target.value}); return;
            case "shippingPhoneNumber": this.setState({shippingPhoneNumber: event.target.value}); return;
            default:
                if (event.target.name.startsWith(RATE_PLANS_INPUT_NAME_PREFIX)) {

                    if (isNaN(event.target.value)) return;

                    const ratePlanId = event.target.name.substr(RATE_PLANS_INPUT_NAME_PREFIX.length);
                    const ratePlans = JSON.parse(JSON.stringify(this.state.ratePlans));
                    const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));

                    totalRavens = 0;
                    ratePlans.forEach((ratePlan) => {
                        if (ratePlan.id === ratePlanId) {
                            ratePlan.quantity = parseInt(event.target.value);
                        }
                        if (ratePlan.quantity) {
                            totalRavens += ratePlan.quantity;
                        }
                    });

                    // Update included accessories based on totalRavens
                    // If current quantity is not equal to the what is required for the order type, set it to the minimum
                    totalAccessories = 0;
                    totalSDCards = 0;
                    totalBeacons = 0;
                    itemTypes.forEach((itemType) => {
                        if(itemType.included && itemType.included[this.state.orderType]){
                            const minQuantity = totalRavens * itemType.included[this.state.orderType];
                            const currentQuantity = itemType.quantity ? itemType.quantity : 0;
                            if (currentQuantity !== minQuantity) {
                                itemType.quantity = minQuantity;
                            }
                        }

                        if (itemType.quantity) {
                            totalAccessories += itemType.quantity;
                            if (itemType.id.startsWith("SD_CARD")) {
                                totalSDCards += itemType.quantity;
                            }
                            if (itemType.id.startsWith("BEACON")) {
                                totalBeacons += itemType.quantity;
                            }
                        }
                    });

                    this.setState({
                        totalRavens: totalRavens,
                        totalAccessories: totalAccessories,
                        totalBeacons: totalBeacons,
                        totalSDCards: totalSDCards,
                        ratePlans: ratePlans,
                        itemTypes: itemTypes
                    })
                    return;
                }
                if (event.target.name.startsWith(ACCESSORY_ITEM_TYPE_INPUT_NAME_PREFIX)) {

                    if (isNaN(event.target.value)) return;

                    const itemTypeId = event.target.name.substr(ACCESSORY_ITEM_TYPE_INPUT_NAME_PREFIX.length);
                    const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));
                    totalAccessories = 0; // SDCards and other accessories are counted together
                    totalSDCards = 0; // SDCards are counted separately. This is for validation purposes. Each Raven requires an SD Card.
                    totalBeacons = 0; // Beacons are counted separately. This is for validation purposes. To allow beacon-only orders.
                    itemTypes.forEach((itemType) => {
                        if (itemType.id === itemTypeId) {
                            itemType.quantity = parseInt(event.target.value);
                        }
                        if (itemType.quantity) {
                            totalAccessories += itemType.quantity;
                            if (itemType.id.startsWith("SD_CARD")) {
                                totalSDCards += itemType.quantity;
                            }
                            if (itemType.id.startsWith("BEACON")) {
                                totalBeacons += itemType.quantity;
                            }
                        }
                    });
                    this.setState({
                        totalAccessories: totalAccessories,
                        totalSDCards: totalSDCards,
                        totalBeacons: totalBeacons,
                        itemTypes: itemTypes
                    })
                    return;
                }
                console.error("OrderForm onInputChange invalid input name " + event.target.name);
        }
    }

    emailInvalidErrorMessage = (email) => { // returns reason string
        // TODO these validations are missing regex for valid characters
        if (email.length < 3) { // x@y
            return "Email is too short.";
        }
        const components = email.split("@");
        if (components.length !== 2) { // x@y
            return "Email is invalid.";
        }
        if (components[0].length < 1) { // x is empty
            return "Email is invalid.";
        }
        // validate email prefix valid characters/combos here (components[0])
        if (components[1].length < 3) { // domain name is too short
            return "Email domain is too short.";
        }
        // validate email domain characters here (components[1])
        const domainComponents = components[1].split(".");
        if (domainComponents.length < 2) {
            return "Email domain is invalid."; // root domain
        }
        if (domainComponents[0].length === 0) {
            return "Email domain name is invalid";
        }
        if (domainComponents[1].length < 2 ) {
            return "Email top level domain name is invalid";
        }
        return null;
    }

    validateRavenAccountForm = () => {

        const ravenAccountSectionErrors = [];
        let emailErrorOccurred = false;
        let welcomeNameErrorOccurred = false;

        let failedValidation = false;

        if (!this.state.email) {
            ravenAccountSectionErrors.push("Email Address required");
            emailErrorOccurred = true;
            failedValidation = true;
        } else {
            const emailErrorMessage = this.emailInvalidErrorMessage(this.state.email);
            if (emailErrorMessage) {
                ravenAccountSectionErrors.push(emailErrorMessage);
                emailErrorOccurred = true;
                failedValidation = true;
            }
        }

        if (!this.state.welcomeName) {
            ravenAccountSectionErrors.push("Welcome Email Name required");
            welcomeNameErrorOccurred = true;
            failedValidation = true;
        }

        this.setState({
            ravenAccountSectionErrors: ravenAccountSectionErrors,
            emailErrorOccurred: emailErrorOccurred,
            welcomeNameErrorOccurred: welcomeNameErrorOccurred,
        });

        return failedValidation;
    }

    orderExternalIdIsValid = () => {

        if (this.state.orderExternalId) {
            return true;
        }

        return false;
    }

    validateOrderForm = () => {

        const orderSectionErrors = [];
        let orderExternalIdErrorOccurred = false;
        let orderTypeErrorOccurred = false;
        let totalRavensErrorOccurred = false;
        let totalAccessoriesErrorOccurred = false;
        let totalSDCardsErrorOccurred = false;
        let totalIncludedAccessoriesErrorOccurred = false;
        let orderCommentErrorOccurred = false;

        let failedValidation = false;

        if (!this.orderExternalIdIsValid()) {
            orderSectionErrors.push("Order Number required");
            orderExternalIdErrorOccurred = true;
            failedValidation = true;
        }

        if (!this.state.orderType) { // order type is either RAVEN by default or manually set to RAVEN_GT or RAVEN_GTS for Geotab orders
            orderSectionErrors.push("Geotab Order Type is required");
            orderTypeErrorOccurred = true;
            failedValidation = true;
        }

        if (this.state.orderComment && this.state.orderComment.length > 255) {
            orderSectionErrors.push("Comment cannot be more than 255 characters.");
            orderCommentErrorOccurred = true;
            failedValidation = true;
        }

        if (!failedValidation) { // avoid confusing compounded errors

            const hasRavens = this.state.totalRavens && this.state.totalRavens > 0;
            const hasSDCards = this.state.totalSDCards && this.state.totalSDCards > 0;
            const hasAccessories = this.state.totalAccessories && this.state.totalAccessories > 0;

            if (!hasRavens) {
                // Ordering accessories with no raven is allowed for existing accounts
                // So we show error only if no accessories are ordered OR there are accessories BUT accessories-only orders are not allowed (it's a new account)
                if(!hasAccessories || !this.allowAccessoriesOnlyOrders) {
                    orderSectionErrors.push("Total Ravens to Order required");
                    totalRavensErrorOccurred = true;
                    failedValidation = true;
                }
            }else{
                // If ravens are ordered then SD Cards are required
                if (!hasSDCards) {
                    orderSectionErrors.push("Total SD Cards to Order required");
                    totalSDCardsErrorOccurred = true;
                    failedValidation = true;
                }

                // Each Raven requires an SD Card
                if (this.state.totalSDCards < this.state.totalRavens) { // each Raven requires an SD Card
                    orderSectionErrors.push("Each Raven requires an SD Card accessory.");
                    totalRavensErrorOccurred = true;
                    totalAccessoriesErrorOccurred = true;
                    failedValidation = true;
                }

                // // TODO: With totalSDCards check in place, this check seems to be redundant. Remove it.
                if (!this.state.totalAccessories) {
                    orderSectionErrors.push("Total Accessories to Order required");
                    totalAccessoriesErrorOccurred = true;
                    failedValidation = true;
                }

                // Check included accessories. Their quantity should not be less than the minimum required for the order type.
                const includedAccessories = this.state.itemTypes.filter(item => item.included && item.included[this.state.orderType]);
                includedAccessories.forEach((item) => {
                    const minQuantity = item.included[this.state.orderType] * this.state.totalRavens;
                    if (!item.quantity || item.quantity < minQuantity) {
                        orderSectionErrors.push("Each Raven requires " + item.included[this.state.orderType] + " " + item.name + ".");
                        totalIncludedAccessoriesErrorOccurred = true;
                        failedValidation = true;
                    }
                });

            }
        }

        this.setState({
            orderSectionErrors: orderSectionErrors,
            orderExternalIdErrorOccurred: orderExternalIdErrorOccurred,
            orderTypeErrorOccurred: orderTypeErrorOccurred,
            orderCommentErrorOccurred: orderCommentErrorOccurred,
            totalRavensErrorOccurred: totalRavensErrorOccurred,
            totalSDCardsErrorOccurred: totalSDCardsErrorOccurred,
            totalAccessoriesErrorOccurred: totalAccessoriesErrorOccurred
        });

        return failedValidation;
    }

    validateShippingForm = () => {

        const shippingSectionErrors = [];
        let shippingAddressStreetLine1ErrorOccurred = false;
        let shippingAddressCityErrorOccurred = false;
        let shippingAddressZipCodeErrorOccurred = false;
        let shippingAddressRegionErrorOccurred = false;
        let shippingAddressCountryErrorOccurred = false;
        let shippingPhoneNumberErrorOccurred = false;

        let failedValidation = false;

        if (!this.state.shippingAddressStreetLine1) {
            shippingSectionErrors.push("Street Address Line 1 required");
            shippingAddressStreetLine1ErrorOccurred = true;
            failedValidation = true;
        }

        if (!this.state.shippingAddressCity) {
            shippingSectionErrors.push("City required");
            shippingAddressCityErrorOccurred = true;
            failedValidation = true;
        }

        if (!this.state.shippingAddressZipCode) {
            shippingSectionErrors.push("ZIP/Postal Code required");
            shippingAddressZipCodeErrorOccurred = true;
            failedValidation = true;
        }

        if (!this.state.shippingAddressRegion) {
            shippingSectionErrors.push("State/Province required");
            shippingAddressRegionErrorOccurred = true;
            failedValidation = true;
        }

        if (!this.state.shippingAddressCountry) {
            shippingSectionErrors.push("Country required");
            shippingAddressCountryErrorOccurred = true;
            failedValidation = true;
        }

        /*if (!this.state.shippingPhoneNumber) {
            shippingSectionErrors.push("Phone number required");
            shippingPhoneNumberErrorOccurred = true;
            failedValidation = true;
        }*/

        this.setState({
            shippingSectionErrors: shippingSectionErrors,
            shippingAddressStreetLine1ErrorOccurred: shippingAddressStreetLine1ErrorOccurred,
            shippingAddressCityErrorOccurred: shippingAddressCityErrorOccurred,
            shippingAddressZipCodeErrorOccurred: shippingAddressZipCodeErrorOccurred,
            shippingAddressRegionErrorOccurred: shippingAddressRegionErrorOccurred,
            shippingAddressCountryErrorOccurred: shippingAddressCountryErrorOccurred,
            shippingPhoneNumberErrorOccurred: shippingPhoneNumberErrorOccurred
        });

        return failedValidation;
    }

    externalIdIsValid = () => {

        if (this.state.accountExternalId) {
            return true;
        }

        return false;
    }

    validateExternalAccountForm = () => {

        const externalAccountSectionErrors = [];
        let accountExternalIdErrorOccurred = false;

        let failedValidation = false;

        if (!this.externalIdIsValid()) {
            externalAccountSectionErrors.push("Account Number required");
            accountExternalIdErrorOccurred = true;
            failedValidation = true;
        }

        this.setState({
            externalAccountSectionErrors: externalAccountSectionErrors,
            accountExternalIdErrorOccurred: accountExternalIdErrorOccurred,
        })

        return failedValidation;
    }

    allFormsAreValid = () => {
        let failedValidation = false;

        const failedExternalAccountFormValidation = this.validateExternalAccountForm();
        if (failedExternalAccountFormValidation) {
            failedValidation = true;
        }

        const failedRavenAccountFormValidation = this.validateRavenAccountForm();
        if (failedRavenAccountFormValidation) {
            failedValidation = true;
        }

        if (this.ordersFulfillmentEnabled) {            
            const failedOrderFormValidation = this.validateOrderForm();
            if (failedOrderFormValidation) {
                failedValidation = true;
            }
        }

        const failedShippingOrderFormValidation = this.validateShippingForm();
        if (failedShippingOrderFormValidation) {
            failedValidation = true;
        }

        return !failedValidation;
    }

    applyAccountToState = (account) => {
        const externalAccountSectionErrors = [];
        const accountExternalId = account.accountId;
        let accountExternalIdErrorOccurred = false;

        if (!accountExternalId) {
            externalAccountSectionErrors.push("Account Id not found");
            accountExternalIdErrorOccurred = true;
        } else if (accountExternalId !== accountExternalId) {
            externalAccountSectionErrors.push("Account Id does not match order");
            accountExternalIdErrorOccurred = true;
        }

        const integrations = account.integrations;
        let accountIntegrations = ACCOUNT_INTEGRATIONS_TYPES.NONE; // MULTIPLE INTEGRATIONS NOT SUPPORT YET ... setting to one of ACCOUNT_INTEGRATIONS_TYPES
        let accountIntegrationsErrorOccurred = false;
        let accountIntegrationsSectionErrors = [];
        if (integrations) {
            integrations.forEach( integration => {
                if (integration.type === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB) {
                    accountIntegrations = ACCOUNT_INTEGRATIONS_TYPES.GEOTAB // only GEOTAB supported by the order form at this time
                }
            });
        } else { // empty array is unexpected
            accountIntegrationsErrorOccurred = true;
            accountIntegrationsSectionErrors.push("Account integrations not found. Please contact support.");
        }

        const ravenAccountSectionErrors = [];
        const email = account.owner && account.owner.email;
        let emailErrorOccurred = false;
        if (!email) {
            ravenAccountSectionErrors.push("Account owner email not found");
            emailErrorOccurred = true;
        }

        const welcomeName = account.primaryAddress && account.primaryAddress.name;
        let welcomeNameErrorOccurred = false;
        if (!welcomeName) {
            ravenAccountSectionErrors.push("Account primary address name not found");
            welcomeNameErrorOccurred = true;
        }

        const notes = account.notes;

        const shippingSectionErrors = [];
        const shippingAddressStreetLine1 = account.primaryAddress && account.primaryAddress.address1;
        let shippingAddressStreetLine1ErrorOccurred = false;
        if (!shippingAddressStreetLine1) {
            shippingSectionErrors.push("Account primary address street address line 1 is missing");
            shippingAddressStreetLine1ErrorOccurred = true;
        }

        const shippingAddressStreetLine2 = account.primaryAddress && account.primaryAddress.address2;

        const shippingAddressCity = account.primaryAddress && account.primaryAddress.city;
        let shippingAddressCityErrorOccurred = false;
        if (!shippingAddressCity) {
            shippingSectionErrors.push("Account primary address city is missing");
            shippingAddressCityErrorOccurred = true;
        }

        const shippingAddressZipCode = account.primaryAddress && account.primaryAddress.postcode;
        let shippingAddressZipCodeErrorOccurred = false;
        if (!shippingAddressZipCode) {
            shippingSectionErrors.push("Account primary address zip/postal code is missing");
            shippingAddressZipCodeErrorOccurred = true;
        }

        const shippingAddressRegion = account.primaryAddress && account.primaryAddress.state;
        let shippingAddressRegionErrorOccurred = false;
        if (!shippingAddressRegion) {
            shippingSectionErrors.push("Account primary address region (state or provinc) is missing");
            shippingAddressRegionErrorOccurred = true;
        }

        const shippingAddressCountry = account.primaryAddress && account.primaryAddress.country;
        let shippingAddressCountryErrorOccurred = false;
        if (!shippingAddressCountry) {
            shippingSectionErrors.push("Account primary address country is missing");
            shippingAddressCountryErrorOccurred = true;
        }

        const shippingPhoneNumber = account.primaryAddress && account.primaryAddress.phoneNumber;
        let shippingPhoneNumberErrorOccurred = false;
        /*if (!shippingPhoneNumber) { // if phone number required
            shippingSectionErrors.push("Account primary address phone number is missing");
            shippingPhoneNumberErrorOccurred = true;
        }*/


        this.setState({

            accountForEditMode: account,

            externalAccountSectionPending: false,
            externalAccountSectionErrors: externalAccountSectionErrors,

            accountExternalId: accountExternalId,
            accountExternalIdErrorOccurred: accountExternalIdErrorOccurred,

            accountIntegrationsSectionPending: false,
            accountIntegrations: accountIntegrations,

            accountIntegrationsErrorOccurred: accountIntegrationsErrorOccurred,
            accountIntegrationsSectionErrors: accountIntegrationsSectionErrors,

            ravenAccountSectionPending: false,
            ravenAccountSectionErrors: ravenAccountSectionErrors,

            email: email,
            emailErrorOccurred: emailErrorOccurred,

            welcomeName: welcomeName,
            welcomeNameErrorOccurred: welcomeNameErrorOccurred,

            notes: notes,

            shippingSectionPending: false,
            shippingSectionErrors: shippingSectionErrors,

            shippingAddressStreetLine1: shippingAddressStreetLine1,
            shippingAddressStreetLine1ErrorOccurred: shippingAddressStreetLine1ErrorOccurred,

            shippingAddressStreetLine2: shippingAddressStreetLine2,

            shippingAddressCity: shippingAddressCity,
            shippingAddressCityErrorOccurred: shippingAddressCityErrorOccurred,

            shippingAddressZipCode: shippingAddressZipCode,
            shippingAddressZipCodeErrorOccurred: shippingAddressZipCodeErrorOccurred,

            shippingAddressRegion: shippingAddressRegion,
            shippingAddressRegionErrorOccurred: shippingAddressRegionErrorOccurred,

            shippingAddressCountry: shippingAddressCountry,
            shippingAddressCountryErrorOccurred: shippingAddressCountryErrorOccurred,

            shippingPhoneNumber: shippingPhoneNumber,
            shippingPhoneNumberErrorOccurred: shippingPhoneNumberErrorOccurred
        });
    }

    getAccount = (accountExternalId, applyAccountToState = true) => {

        if (!accountExternalId) {
            console.error("OrderForm getAccount with invalid accountExternalId");
            return Promise.reject(new Error("OrderForm getAccount with invalid accountExternalId"));
        }

        this.setState({
            externalAccountSectionPending: true,
            ravenAccountSectionPending: true,
            shippingSectionPending: true,
        });

        return this.ravenDataStore.getAccount(this.props.stage, accountExternalId)
        .then(
            (account) => {
                //console.log(JSON.stringify(account));

                if (applyAccountToState) {
                    this.applyAccountToState(account);
                }

                return Promise.resolve(account);
            },
            (error) => {

                //console.log("OrderForm getAccount error occurred");
                console.error(error);
                const errorMessage = error && error.message ? error.message : "An error occurred";

                return Promise.reject(error);
            }
        );
    }

    addAccountTimeoutHandler = () => {
        const timeoutMessage = "The request is taking too long.";
        this.setState({
            externalAccountSectionErrors: [timeoutMessage],
            ravenAccountSectionErrors: [timeoutMessage]
        });
    }

    addShippingTimeoutHandler = () => {
        const timeoutMessage = "The request is taking too long.";
        this.setState({
            shippingSectionErrors: [timeoutMessage]
        });        
    }

    addOrderTimeoutHandler = () => {
        const timeoutMessage = "The request is taking too long.";
        this.setState({
            orderSectionErrors: [timeoutMessage]
        });
    }

    primaryFormSubmitAction = () => {

        const formIsValid = this.allFormsAreValid();
        if (!formIsValid) {
            return;
        }

        this.setState({
            addingAllNewAccountOrderAndShipping: true, // see this.isEditMode; locks remainder of this session in !this.isEditMode as responses are processed or fail
            externalAccountSectionPending: true,
            accountIntegrationsSectionPending: true,
            ravenAccountSectionPending: true,
            orderSectionPending: true,
            shippingSectionPending: true
        });


        let errorAppliedToState = false; // set to true to avoid reject chain clobbering previous error states

        return this.addAccount()
        .then(
            (accountId) => { return this.addShipping(accountId); },
            () => {//error
                if (!errorAppliedToState) {
                    errorAppliedToState = true;
                    this.setState({
                        addingAllNewAccountOrderAndShipping: false, // no changes applied yet, can safely "unlock" this mode
                        externalAccountSectionPending: false,
                        ravenAccountSectionPending: false,
                        orderSectionPending: false,
                        orderSectionErrors: ["Not saved. Account error occurred"],
                        shippingSectionPending: false,
                        shippingSectionErrors: ["Not saved. Account error occurred"]
                    });
                }
                return Promise.reject(); // must return a reject, not returning anything defaults to resolve promise (syntax sugar support?)
            }
        )
        .then(
            (accountId) => {
                if (this.ordersFulfillmentEnabled) {
                    return this.addOrder(accountId);
                }
                return Promise.resolve();
            },
            () => {//error
                if (!errorAppliedToState) {
                    errorAppliedToState = true;
                    this.setState({
                        externalAccountSectionPending: false,
                        ravenAccountSectionPending: false,
                        accountIntegrationsSectionPending: false,
                        orderSectionPending: false,
                        shippingSectionPending: false,
                        shippingSectionErrors: ["Not saved. Order request error occurred"]
                    });    
                }
                return Promise.reject();
            }
        )
        .then(
            () => { return Promise.resolve(); },
            () => {//error
                if (!errorAppliedToState) {
                    errorAppliedToState = true;
                    this.setState({
                        externalAccountSectionPending: false,
                        ravenAccountSectionPending: false,
                        accountIntegrationsSectionPending: false,
                        orderSectionPending: false,
                        shippingSectionPending: false
                    });
                }
            }
        );
    }

    addNewOrder = () => {
        const confirmedAccountId = this.state.accountForEditMode ? this.state.accountForEditMode.accountId : undefined;
        if (!confirmedAccountId) {
            console.error("OrderForm renderForEditMode " + this.primaryActionName + " requested without an accountForEditMode.accountId");
            return;
        }
        this.addOrder(confirmedAccountId)
        .then(
            (order) => {
                //console.log("addNewOrder success");
            },
            (error) => {
                //console.log("addNewOrder fail");
            }
        );
    }

    addAccount = () => {

        const failedExternalAccountFormValidation = this.validateExternalAccountForm();
        const failedRavenAccountFormValidation = this.validateRavenAccountForm();
        if (failedExternalAccountFormValidation || failedRavenAccountFormValidation) {
            return Promise.reject();
        }

        clearTimeout(this.addAccountTimeoutId);

        this.addAccountTimeoutId = setTimeout(() => {this.addAccountTimeoutHandler();}, ADD_ACCOUNT_TIMEOUT);

        if (!this.state.externalAccountSectionPending || !this.state.ravenAccountSectionPending) {
            this.setState({
                externalAccountSectionPending: true,
                ravenAccountSectionPending: true,
            });    
        }

        const integrationsForRequest = [];
        const accountIntegrations = this.state.accountIntegrations;

        if (accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB) { // only GEOTAB and NONE are supported
            integrationsForRequest.push(accountIntegrations);
        }

        return this.ravenDataStore.addAccount(
            this.props.stage,
            this.state.email.toLowerCase(),
            this.state.accountExternalId,
            this.state.welcomeName,
            this.state.notes,
            this.state.sendWelcomeEmail,
            integrationsForRequest)
        .then(
            (response) => {

                clearTimeout(this.addAccountTimeoutId);

                if (this.state.accountExternalId !== response.accountId) {
                    this.setState({externalAccountSectionErrors: ["Warning: Different Account Number in add account request response. Responded with " + response.accountId + ", but expected " + this.state.accountExternalId]});
                }

                const accountForEditMode = { // mimics the response from a get account request
                    /* e.g.
                    {"accountId":"9000000006","notes":null,"status":"ACTIVE","owner":{"cognitoId":"us-east-1:4f1197a4-e60e-41d2-be1a-9f85b5f431db","email":"rob+order9006@klashwerks.com","snsTopicArn":"arn:aws:sns:us-east-1:393080794103:raven-kloud2-user-4f1197a4-e60e-41d2-be1a-9f85b5f431db","dateCreated":"2020-11-19T12:26:23"},"addresses":[{"name":"Rob 9006","address1":"9000 Nice Thousand Street","address2":null,"city":"Ninethowton","state":"Nineland","country":"Nine","postcode":"O9O9O9","phoneNumber":null,"email":null,"addressId":16}],"primaryAddress":{"name":"Rob 9006","address1":"9000 Nice Thousand Street","address2":null,"city":"Ninethowton","state":"Nineland","country":"Nine","postcode":"O9O9O9","phoneNumber":null,"email":null,"addressId":16},"dateCreated":"2020-11-19T12:26:23"}
                    */
                    accountId: response.accountId,
                    notes: this.state.notes,
                    owner: {
                        email: response.email
                    },
                    primaryAddress: {
                        name: this.state.welcomeName
                    },
                    integrations: response.integrations
                }

                this.setState({
                    externalAccountSectionPending: false,
                    externalAccountSectionSaved: true,
                    accountIntegrationsSectionPending: false,
                    accountIntegrationsSectionSaved: true,
                    ravenAccountSectionPending: false,
                    ravenAccountSectionSaved: true,
                    accountForEditMode: accountForEditMode,
                    newAccountTemporaryPassword: response.password ? response.password : undefined
                });

                if (this.props.setReloadOnDismiss) {
                    this.props.setReloadOnDismiss(true); // RAV-1843 basic support for both add and edit order modes for consistent refresh for saved edits.
                } else {
                    console.warn("OrderForm addAccount setReloadOnDismiss function not provided.");
                }

                return Promise.resolve(response.accountId);
            },
            (error) => {

                clearTimeout(this.addAccountTimeoutId);

                console.error(error);
                const externalAccountSectionErrors = [];
                const ravenAccountSectionErrors = [];
                let emailErrorOccurred = false;

                const errorCode = error ?  error.code : undefined;
                switch (errorCode) {
                    case 409:
                        // Email address conflict
                        ravenAccountSectionErrors.push("Email address already used for a different " + this.accountExternalIdTitle);
                        emailErrorOccurred = true;
                        break;
                    default:
                        const errorMessage = error.message ? error.message : "Unknown error occurred";
                        externalAccountSectionErrors.push(errorMessage);
                        ravenAccountSectionErrors.push(errorMessage);
                        break;
                }

                this.setState({
                    externalAccountSectionPending: false,
                    externalAccountSectionErrors: externalAccountSectionErrors,
                    ravenAccountSectionPending: false,
                    ravenAccountSectionErrors: ravenAccountSectionErrors,
                    emailErrorOccurred: emailErrorOccurred
                });

                return Promise.reject(error);
            }
        );
    }

    updateAccount = (accountId, updateDelta) => {

        this.setState({
            externalAccountSectionPending: true,
            ravenAccountSectionPending: true,
            shippingSectionPending: true
        });    

        return this.ravenDataStore.updateAccount(this.props.stage, accountId, updateDelta)
        .then(
            (accountForEditMode) => {

                clearTimeout(this.addShippingTimeoutId);
                clearTimeout(this.addAccountTimeoutId);

                this.setState({
                    accountForEditMode: accountForEditMode,
                    externalAccountSectionPending: false,
                    //externalAccountSectionSaved: true,
                    accountIntegrationsSectionPending: false,
                    //accountIntegrationsSectionSaved: true,
                    ravenAccountSectionPending: false,
                    //ravenAccountSectionSaved: true,
                    shippingSectionPending: false,
                    //shippingSectionSaved: true,
                });

                if (this.props.setReloadOnDismiss) {
                    this.props.setReloadOnDismiss(true); // RAV-1843 basic support for both add and edit order modes for consistent refresh for saved edits.
                } else {
                    console.warn("OrderForm updateAccount setReloadOnDismiss function not provided.");
                }

                return Promise.resolve(accountId);
            },
            (error) =>{

                clearTimeout(this.addShippingTimeoutId);
                clearTimeout(this.addAccountTimeoutId);

                //console.error(error);

                //const errorMessage = error.message ? error.message : "Unknown error occurred";
                this.setState({
                    externalAccountSectionPending: false,
                    //externalAccountSectionErrors: [errorMessage],
                    ravenAccountSectionPending: false,
                    //ravenAccountSectionErrors: [errorMessage],
                    shippingSectionPending: false,
                    //shippingSectionErrors: [errorMessage]
                });

                return Promise.reject(error);
            }
        );

    }

    updateRavenAccountExternalId = () => {

        if (!this.state.accountForEditMode) {
            console.error("OrderForm renderForEditMode update raven account id requested without an accountForEditMode");
            return;
        }
        const confirmedAccountId = this.state.accountForEditMode.accountId;
        const accountExternalId = this.state.accountExternalId;

        if (confirmedAccountId === accountExternalId) {
            console.warn("OrderForm renderForEditMode update raven account id requested for the same id");
            return;
        }

        const updateDelta = {
            accountId: accountExternalId
        }

        clearTimeout(this.addAccountTimeoutId);

        this.addAccountTimeoutId = setTimeout(() => {this.addAccountTimeoutHandler();}, ADD_ACCOUNT_TIMEOUT);

        this.setState({
            externalAccountSectionSaved: false
        });

        this.updateAccount(confirmedAccountId, updateDelta)
        .then(
            () => {
                this.setState({
                    externalAccountSectionSaved: true
                });
            },
            (error) => {
                const errorMessage = error.message ? error.message : "Unknown error occurred";
                this.setState({
                    externalAccountSectionErrors: [errorMessage]
                });
            }
        );

    }

    updateAccountIntegrationsDeleteGeotab = (confirmedExternalAccountId) => {
        return this.ravenDataStore.deleteAccountGeotabIntegration(this.props.stage, confirmedExternalAccountId)
        .then(
            (deleteAccountGeotabIntegrationResponse) => {
                const orderNumber = this.state.orderForEditMode.orderNumber;
                const updateDelta = {
                    orderType: null // reset order type accordingly
                };
                return this.ravenDataStore.updateOrder(this.props.stage, orderNumber, updateDelta)
                .then(
                    (updateOrderResponse) => {
                        return Promise.resolve(deleteAccountGeotabIntegrationResponse);
                    },
                    (orderUpdateError) => {
                        return Promise.reject(orderUpdateError);
                    }
                );
            },
            (deleteAccountGeotabIntegrationError) => {
                return Promise.reject(deleteAccountGeotabIntegrationError);
            }
        );
    }

    updateAccountIntegrationsAddGeotab = (confirmedExternalAccountId) => {
        return this.ravenDataStore.addAccountGeotabIntegration(this.props.stage, confirmedExternalAccountId);
    }

    updateRavenAccount = () => {
        const failedValidation = this.validateRavenAccountForm();
        if (failedValidation) {
            return;
        }

        if (!this.state.accountForEditMode) {
            console.error("OrderForm renderForEditMode update raven account requested without an accountForEditMode");
            return;
        }
        const confirmedAccountId = this.state.accountForEditMode.accountId;

        //const confirmedAccountPrimaryAddress = {...this.state.accountForEditMode.primarySectionFocus};
        //confirmedAccountPrimaryAddress.name = this.state.welcomeName;

        const updateDelta = {
            notes: this.state.notes,
            //primaryAddress: confirmedAccountPrimaryAddress
        }

        clearTimeout(this.addAccountTimeoutId);

        this.addAccountTimeoutId = setTimeout(() => {this.addAccountTimeoutHandler();}, ADD_ACCOUNT_TIMEOUT);

        this.setState({
            ravenAccountSectionSaved: false
        });

        this.updateAccount(confirmedAccountId, updateDelta)
        .then(
            () => {
                this.setState({
                    ravenAccountSectionSaved: true
                });
            },
            (error) => {
                const errorMessage = error.message ? error.message : "Unknown error occurred";
                this.setState({
                    ravenAccountSectionErrors: [errorMessage]
                });
            }
        );
    }

    addShipping = (accountId) => {

        const failedValidation = this.validateShippingForm();
        if (failedValidation) {
            return Promise.reject();
        }

        clearTimeout(this.addShippingTimeoutId);

        this.addShippingTimeoutId = setTimeout(() => {this.addShippingTimeoutHandler();}, ADD_SHIPPING_TIMEOUT);

        if (!this.state.shippingSectionPending) {
            this.setState({
                shippingSectionPending: true
            });    
        }

        return this.ravenDataStore.addShippingAddress(
            this.props.stage,
            {
                accountId: accountId,
                name: this.state.welcomeName, // TODO name: this.state.shippingName
                address1: this.state.shippingAddressStreetLine1,
                address2: this.state.shippingAddressStreetLine2,
                city: this.state.shippingAddressCity,
                state: this.state.shippingAddressRegion,
                country: this.state.shippingAddressCountry,
                postcode: this.state.shippingAddressZipCode,
                phoneNumber: this.state.shippingPhoneNumber
            })
        .then(
            (address) => {

                clearTimeout(this.addShippingTimeoutId);

                this.setState({
                    shippingSectionPending: false,
                    shippingSectionSaved: true,
                });

                if (this.props.setReloadOnDismiss) {
                    this.props.setReloadOnDismiss(true); // RAV-1843 basic support for both add and edit order modes for consistent refresh for saved edits.
                } else {
                    console.warn("OrderForm addShipping setReloadOnDismiss function not provided.");
                }

                return Promise.resolve(accountId);
            },
            (error) =>{

                clearTimeout(this.addShippingTimeoutId);

                console.error(error);

                const errorMessage = error.message ? error.message : "Unknown error occurred";
                this.setState({
                    shippingSectionPending: false,
                    shippingSectionErrors: [errorMessage]
                });

                return Promise.reject(error);
            }
        );

    }

    updateShipping = () => {

        const failedValidation = this.validateShippingForm();
        if (failedValidation) {
            return;
        }

        const confirmedAccountId = this.state.accountForEditMode ? this.state.accountForEditMode.accountId : undefined;
        if (!confirmedAccountId) {
            console.error("OrderForm renderForEditMode update shipping requested without an accountForEditMode.accountId");
            return;
        }

        const updateDelta = {
            primaryAddress: {
                name: this.state.welcomeName, // TODO name: this.state.shippingName
                address1: this.state.shippingAddressStreetLine1,
                address2: this.state.shippingAddressStreetLine2,
                city: this.state.shippingAddressCity,
                state: this.state.shippingAddressRegion,
                country: this.state.shippingAddressCountry,
                postcode: this.state.shippingAddressZipCode,
                phoneNumber: this.state.shippingPhoneNumber
            }
        }

        clearTimeout(this.addShippingTimeoutId);

        this.addShippingTimeoutId = setTimeout(() => {this.addShippingTimeoutHandler();}, ADD_SHIPPING_TIMEOUT);

        this.setState({
            shippingSectionSaved: false
        });

        this.updateAccount(confirmedAccountId, updateDelta)
        .then(
            () => {
                this.setState({
                    shippingSectionSaved: true
                });
            },
            (error) => {
                const errorMessage = error.message ? error.message : "Unknown error occurred";
                this.setState({
                    shippingSectionErrors: [errorMessage]
                });
            }
        );
    }

    addOrder = (accountId) => {

        const failedValidation = this.validateOrderForm();
        if (failedValidation) {
            return Promise.reject();
        }

        clearTimeout(this.addOrderTimeoutId);

        this.addOrderTimeoutId = setTimeout(() => {this.addOrderTimeoutHandler();}, ADD_ORDER_TIMEOUT);

        if (!this.state.orderSectionPending) {
            this.setState({
                orderSectionPending: true
            });    
        }

        const items = [];
        this.state.ratePlans.forEach((ratePlan) => {
            if (ratePlan.quantity && ratePlan.quantity != 0) {
                for (let i = 0; i < ratePlan.quantity; i += 1) {
                    let ratePlanIds = ratePlan.id.split("."); // "<ratePlanId>.<termId>" // RAV-2762 rush job
                    if (ratePlanIds.length === 2) {
                        items.push({
                            ratePlanId: ratePlanIds[0],
                            rateTermId: ratePlanIds[1],
                            itemType: "RAVEN_PLUS"
                        })
                    } else {
                        // backwards compatibility
                        items.push({
                            ratePlanId: ratePlan.id,
                            itemType: "RAVEN_PLUS"
                        });    
                    }
                }
            }
        });

        const accessoryItemTypes = this.accessoryItemTypesFromState();
        accessoryItemTypes.forEach((itemType) => {
            if (itemType.quantity && itemType.quantity != 0) {
                for (let i = 0; i < itemType.quantity; i += 1) {
                    items.push({
                        itemType: itemType.id
                    });    
                }
            }
        });


        return this.ravenDataStore.addOrder(
            this.props.stage,
            {
                accountId: accountId,
                orderNumber: this.state.orderExternalId,
                orderType: this.state.orderType,
                comment: this.state.orderComment,
                items: items
            })
        .then(
            (order) => {

                clearTimeout(this.addOrderTimeoutId);

                this.setState({
                    orderForEditMode: order,
                    orderSectionPending: false,
                    orderSectionSaved: true,
                });

                if (this.props.setReloadOnDismiss) {
                    this.props.setReloadOnDismiss(true); // RAV-1843 basic support for both add and edit order modes for consistent refresh for saved edits.
                } else {
                    console.warn("OrderForm addOrder setReloadOnDismiss function not provided.");
                }

                return Promise.resolve(accountId);
            },
            (error) => {

                clearTimeout(this.addOrderTimeoutId);

                console.error(error);

                let errorMessage = error.message ? error.message : "Unknown error occurred";
                const errorCode = error ?  error.code : undefined;
                if (errorCode === 409) {
                    errorMessage = "Order number has already been used for a different order.";
                }
                this.setState({
                    orderSectionPending: false,
                    orderSectionErrors: [errorMessage]
                });

                return Promise.reject(error);
            }
        );
    }

    updateGeotabOrderType = () => {

        if (!this.state.orderForEditMode) {
            console.error("OrderForm renderForEditMode update order type requested without an orderForEditMode");
            return;
        }
        const orderNumber = this.state.orderForEditMode.orderNumber;

        const orderType = this.state.orderForEditMode.orderType;
        const updatedOrderType = this.state.orderType;
        if (orderType === updatedOrderType) {
            console.warn("OrderForm renderForEditMode update order comment requested to apply the same comment (no change)");
            return;
        }

        const updateDelta = {
            orderType: updatedOrderType
        };

        clearTimeout(this.addOrderTimeoutId);

        this.addOrderTimeoutId = setTimeout(() => {this.addOrderTimeoutHandler();}, ADD_ORDER_TIMEOUT);

        this.setState({
            orderSectionPending: true,
            orderSectionSaved: false
        });

        this.ravenDataStore.updateOrder(this.props.stage, orderNumber, updateDelta)
        .then(
            (updatedOrder) => {

                clearTimeout(this.addOrderTimeoutId);

                this.setState({
                    orderForEditMode: updatedOrder,
                    orderSectionPending: false,
                    orderSectionSaved: true
                }, () => {this.validateOrderForm()});

                if (this.props.setReloadOnDismiss) {
                    this.props.setReloadOnDismiss(true); // RAV-1843 basic support for both add and edit order modes for consistent refresh for saved edits.
                } else {
                    console.warn("OrderForm updateGeotabOrderType setReloadOnDismiss function not provided.");
                }

            },
            (error) => {
                const errorMessage = error.message ? error.message : "Unknown error occurred";
                this.setState({
                    orderSectionPending: false,
                    orderSectionErrors: [errorMessage]
                });
            }
        );
    }

    updateOrderComment = () => {

        if (!this.state.orderForEditMode) {
            console.error("OrderForm renderForEditMode update order comment requested without an orderForEditMode");
            return;
        }
        const orderNumber = this.state.orderForEditMode.orderNumber;

        const orderComment = this.state.orderForEditMode.comment;
        const updatedOrderComment = this.state.orderComment;

        if (orderComment === updatedOrderComment) {
            console.warn("OrderForm renderForEditMode update order comment requested to apply the same comment (no change)");
            return;
        }

        const updateDelta = {
            comment: updatedOrderComment
        }

        clearTimeout(this.addOrderTimeoutId);

        this.addOrderTimeoutId = setTimeout(() => {this.addOrderTimeoutHandler();}, ADD_ORDER_TIMEOUT);

        this.setState({
            orderSectionPending: true,
            orderSectionSaved: false
        });

        this.ravenDataStore.updateOrder(this.props.stage, orderNumber, updateDelta)
        .then(
            (updatedOrder) => {

                clearTimeout(this.addOrderTimeoutId);

                this.setState({
                    orderForEditMode: updatedOrder,
                    orderSectionPending: false,
                    orderSectionSaved: true
                });

                if (this.props.setReloadOnDismiss) {
                    this.props.setReloadOnDismiss(true); // RAV-1843 basic support for both add and edit order modes for consistent refresh for saved edits.
                } else {
                    console.warn("OrderForm updateOrderComment setReloadOnDismiss function not provided.");
                }

            },
            (error) => {
                const errorMessage = error.message ? error.message : "Unknown error occurred";
                this.setState({
                    orderSectionPending: false,
                    orderSectionErrors: [errorMessage]
                });
            }
        );

    }

    cancelOrder = () => {


        if (!this.orderExternalIdIsValid()) {
            this.setState({
                orderSectionErrors: ["Order Number required"],
                orderExternalIdErrorOccurred: true
            });
            return Promise.reject();
        }

        const completeCancellingOrder = () => {
            clearTimeout(this.addOrderTimeoutId);

            this.addOrderTimeoutId = setTimeout(() => {this.addOrderTimeoutHandler();}, ADD_ORDER_TIMEOUT);
    
            if (!this.state.orderSectionPending) {
                this.setState({
                    orderSectionPending: true,
                    confirmationDialog: null
                });    
            }
    
            const orderExternalId = this.state.orderExternalId;
    
            return this.ravenDataStore.cancelOrder(this.props.stage, orderExternalId)
            .then(
                () => {
    
                    clearTimeout(this.addOrderTimeoutId);
    
                    this.setState({
    
                        orderSectionPending: false,
                        ratePlans: this.props.ratePlans, // reset quantities
                        itemTypes: this.props.itemTypes, // reset quantities
                        orderSectionSaved: true,
                        orderSectionErrors: ["Order " + orderExternalId + " successfully cancelled."],
    
                        orderForEditMode: undefined,
                        orderExternalId: undefined,
                        orderType: undefined,
    
                        totalRavens: undefined,
                        totalAccessories: undefined
                    });
    
                    if (!this.props.ratePlans) {
                        this.getRatePlans(); // load rate plans if reseting quantities removed then altogether
                    }

                    if (!this.props.itemTypes) {
                        this.getItemTypes(); // load item types if reseting quantities removed then altogether
                    }

                    if (this.props.setReloadOnDismiss) {
                        this.props.setReloadOnDismiss(true); // RAV-1843 basic support for both add and edit order modes for consistent refresh for saved edits.
                    } else {
                        console.warn("OrderForm completeCancellingOrder setReloadOnDismiss function not provided.");
                    }
        
                    return Promise.resolve(orderExternalId);
                },
                (error) => {
    
                    clearTimeout(this.addOrderTimeoutId);
    
                    console.error(error);
    
                    const errorMessage = error.message ? error.message : "Unknown error occurred";
                    this.setState({
                        orderSectionPending: false,
                        orderSectionErrors: [errorMessage],
                    });
    
                    return Promise.reject(error);
                }
            );    
        }

        const title = "Cancel Order?";
        const message = "You have selected to cancel order #"+ this.state.orderExternalId;
        let cancelButtonTitle = "No, Do Not Cancel";
        let submitButtonTitle = "Yes, Cancel This Order";

        this.setState({
            confirmationDialog: <ConfirmationDialog
                title={title}
                message={message}
                cancelButtonAction={() => {this.setState({confirmationDialog: null})}}
                cancelButtonTitle={cancelButtonTitle}
                hideHeaderCloseButton={true}
                submitButtonAction={completeCancellingOrder}
                submitButtonTitle={submitButtonTitle}
            />
        })
    }

    inputSelectAll = (event) => {

        const target = event.target;

        setTimeout(() => {
            if (!target) return;
            target.select();
        }, 100); // helps ensure a single click select all
    }

    // Fine tunes Technique 1 in https://css-tricks.com/using-css-transitions-auto-dimensions/
    // The animations work without fine tuning; however, these fine tune adjustments nullify the "downsides" discussed in above link
    fineTuneFormSectionMaxHeightAnimations = () => {

        if (this.externalAccountSectionDOMReference && this.externalAccountSectionDOMReference.current) {

            this.externalAccountSectionDOMReference.current.style.transition = "max-height 0.33s";

            if (this.state.externalAccountSectionCollapsed) {
                this.externalAccountSectionDOMReference.current.style.maxHeight = "0";
            } else {
                this.externalAccountSectionDOMReference.current.style.maxHeight = "85px"; // ballpark, 75px is real max for mobile/small resolution stacked WITH "APPLY"(?) BUTTON (via chrome inspect)
            }    
        }


        if (this.accountIntegrationsSectionDOMReference && this.accountIntegrationsSectionDOMReference.current) {

            this.accountIntegrationsSectionDOMReference.current.style.transition = "max-height 0.33s";

            if (this.state.accountIntegrationsSectionCollapsed) {
                this.accountIntegrationsSectionDOMReference.current.style.maxHeight = "0";
            } else {
                this.accountIntegrationsSectionDOMReference.current.style.maxHeight = "85px"; // ballpark, 75px is real max for mobile/small resolution stacked WITH "APPLY"(?) BUTTON (via chrome inspect)
            }    
        }


        if (this.ravenAccountSectionDOMReference && this.ravenAccountSectionDOMReference.current) {

            this.ravenAccountSectionDOMReference.current.style.transition = "max-height 0.33s";

            if (this.state.ravenAccountSectionCollapsed) {
                this.ravenAccountSectionDOMReference.current.style.maxHeight = "0";
            } else {
                this.ravenAccountSectionDOMReference.current.style.maxHeight = "175px"; // ballpark, 144px is real max for mobile/small resolution stacked (via chrome inspect)
            }
        }



        if (this.orderUnitsSectionDOMReference && this.orderUnitsSectionDOMReference.current) {
            this.orderUnitsSectionDOMReference.current.style.transition = "max-height 0.33s";
        }

        if (this.orderAccessoriesSectionDOMReference && this.orderAccessoriesSectionDOMReference.current) {
            this.orderAccessoriesSectionDOMReference.current.style.transition = "max-height 0.33s";
        }

        if (this.orderSectionDOMReference && this.orderSectionDOMReference.current) {
            this.orderSectionDOMReference.current.style.transition = "max-height 0.33s";

            if (this.state.orderSectionCollapsed) {
                this.orderSectionDOMReference.current.style.maxHeight = "0";
                if (this.orderUnitsSectionDOMReference && this.orderUnitsSectionDOMReference.current) {
                    this.orderUnitsSectionDOMReference.current.style.maxHeight = "0";
                }
                if (this.orderAccessoriesSectionDOMReference && this.orderAccessoriesSectionDOMReference.current) {
                    this.orderAccessoriesSectionDOMReference.current.style.maxHeight = "0";
                }
            } else {
                this.orderSectionDOMReference.current.style.maxHeight = "160px";
                if (this.orderUnitsSectionDOMReference && this.orderUnitsSectionDOMReference.current) {
                    this.orderUnitsSectionDOMReference.current.style.maxHeight = "1000px"; // ballpark, 132px is real max for two rate plans on mobile/small resolution stacked (via chrome inspect)
                }
                if (this.orderAccessoriesSectionDOMReference && this.orderAccessoriesSectionDOMReference.current) {
                    this.orderAccessoriesSectionDOMReference.current.style.maxHeight = "1000px"; // ballpark, 132px is real max for two rate plans on mobile/small resolution stacked (via chrome inspect)
                }
            }    
        }



        if (this.shippingSectionDOMReference && this.shippingSectionDOMReference.current) {

            this.shippingSectionDOMReference.current.style.transition = "max-height 0.33s";

            if (this.state.shippingSectionCollapsed) {
                this.shippingSectionDOMReference.current.style.maxHeight = "0";
            } else {
                this.shippingSectionDOMReference.current.style.maxHeight = "250px"; // ballpark, 230px is real max for mobile/small resolution stacked (via chrome inspect)
            }
        }
    }

    updateAccountIntegrationsApplyClick = () => {
        let updateIsToDeleteTheIntegration = false;
        const confirmedAccountId = this.state.accountForEditMode.accountId;
        const confirmedIntegrations = this.state.accountForEditMode && this.state.accountForEditMode.integrations ? this.state.accountForEditMode.integrations : [];
        const accountIntegrations = this.state.accountIntegrations ? this.state.accountIntegrations : ACCOUNT_INTEGRATIONS_TYPES.NONE;

        let accountForEditModeGeotabIntegrationIndex = -1;

        for (let i = 0; i < confirmedIntegrations.length; i += 1) {
            if (confirmedIntegrations[i].type === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB) {
                accountForEditModeGeotabIntegrationIndex = i;
                break;
            }
        }

        if (this.state.accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB) { // request to add geotab integration

            if (accountForEditModeGeotabIntegrationIndex === -1) { // integration not currently set for the account
                updateIsToDeleteTheIntegration = false;

            } else {
                console.warn("OrderForm renderForEditMode updateAccountIntegrationsApplyClick requested to add geotab, but it is already found in the account's integrations.");
                updateIsToDeleteTheIntegration = true;
            }

        } else { // order form currently only supports GEOTAB and NONE, assuming NONE

            if (accountForEditModeGeotabIntegrationIndex > -1) {
                updateIsToDeleteTheIntegration = true;
            } else {
                console.warn("OrderForm renderForEditMode updateAccountIntegrationsApplyClick requested to disable geotab, but it is already not found in the account's integrations.");
                updateIsToDeleteTheIntegration = false;
            }
        }

        const onCancel = () => {
            this.setState({
                confirmationDialog: null,
                accountIntegrations: accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB ? ACCOUNT_INTEGRATIONS_TYPES.NONE : ACCOUNT_INTEGRATIONS_TYPES.GEOTAB
            });
        }

        const onApply = () => {

            clearTimeout(this.addAccountTimeoutId);

            this.addAccountTimeoutId = setTimeout(() => {this.addAccountTimeoutHandler();}, ADD_ACCOUNT_TIMEOUT);
    
            this.setState({
                confirmationDialog: null,
                accountIntegrationsSectionSaved: false,
                accountIntegrationsSectionPending: true
            });
    
            const applyAccountToState = true;
            if (updateIsToDeleteTheIntegration) {

                this.updateAccountIntegrationsDeleteGeotab(confirmedAccountId)
                .then(
                    () => {
                        this.setState({
                            accountIntegrationsSectionSaved: true,
                            //orderType: ORDER_TYPES.RAVEN
                        }, () => {this.validateOrderForm()});
                        clearTimeout(this.addAccountTimeoutId);
                        this.getAccount(confirmedAccountId, applyAccountToState)
                        .then(
                            () => {this.setState({accountIntegrationsSectionPending: false})},
                            () => {this.setState({accountIntegrationsSectionPending: false})}
                        );
                        // reload orders to reflect change
                        if (this.props.setReloadOnDismiss) {
                            this.props.setReloadOnDismiss(true); // RAV-1843 basic support for both add and edit order modes for consistent refresh for saved edits.
                        } else {
                            console.warn("OrderForm updateAccountIntegrationsApplyClick onApply setReloadOnDismiss function not provided.");
                        }
                        // make sure Geotab integration type is selected in this session before dismiss
                        this.setState({validateGeotabOnDismiss: true});
                    },
                    (error) => {
                        const errorMessage = error.message ? error.message : "Unknown error occurred";
                        this.setState({
                            externalAccountSectionErrors: [errorMessage]
                        });
                        clearTimeout(this.addAccountTimeoutId);
                        this.getAccount(confirmedAccountId, applyAccountToState)
                        .then(
                            () => {this.setState({accountIntegrationsSectionPending: false})},
                            () => {this.setState({accountIntegrationsSectionPending: false})}
                        );
                    }
                );
        
            } else {
                this.updateAccountIntegrationsAddGeotab(confirmedAccountId)
                .then(
                    () => {
                        this.setState({
                            accountIntegrationsSectionSaved: true,
                            validateGeotabOnDismiss: true,
                            orderType: undefined
                        }, () => {this.validateOrderForm()});
                        clearTimeout(this.addAccountTimeoutId);
                        this.getAccount(confirmedAccountId, applyAccountToState)
                        .then(
                            () => {this.setState({accountIntegrationsSectionPending: false})},
                            () => {this.setState({accountIntegrationsSectionPending: false})}
                        );
                    },
                    (error) => {
                        const errorMessage = error.message ? error.message : "Unknown error occurred";
                        this.setState({
                            externalAccountSectionErrors: [errorMessage]
                        });
                        clearTimeout(this.addAccountTimeoutId);
                        this.getAccount(confirmedAccountId, applyAccountToState)
                        .then(
                            () => {this.setState({accountIntegrationsSectionPending: false})},
                            () => {this.setState({accountIntegrationsSectionPending: false})}
                        );
                    }
                );
            }
        }

        let title = "Please Confirm";

        let message = "This update will add Geotab integration for this " + this.accountExternalIdTitle + ". This change will affect all ravens on this account. Are you sure you want to add Geotab integration for this account?";
        let cancelButtonTitle = "No, cancel this change";
        let submitButtonTitle = "Yes, add Geotab integration";
        if (updateIsToDeleteTheIntegration) {
            message = "This update will remove Geotab integration for this " + this.accountExternalIdTitle + ". This change will affect all ravens on this account.  Are you sure you want to remove Geotab integration for this account?";
            submitButtonTitle = "Yes, remove Geotab integration";
        }

        this.setState({
            confirmationDialog: <ConfirmationDialog
                title={title}
                message={message}
                cancelButtonAction={onCancel}
                cancelButtonTitle={cancelButtonTitle}
                hideHeaderCloseButton={true}
                submitButtonAction={onApply}
                submitButtonTitle={submitButtonTitle}
            />
        })

    }

    onExternalAccountApplyClick = () => {

        // https://klashwerks.atlassian.net/browse/RAV-2814
        if (process.env.REACT_APP_FF_API_BASE_URL.toLowerCase().includes("telus")) {
            if (this.state.accountExternalId === "27378612") {
                this.setState({
                    externalAccountSectionCollapsed: false,
                    externalAccountSectionErrors: ["To use this BAN for new internal accounts please append .## to make it unique in our system","Review previous orders to see which new number to append, for example 27378612.11"],
                    accountExternalIdErrorOccurred: true
                });
                return;    
            }
        }

        if (!this.externalIdIsValid()) {
            this.setState({
                externalAccountSectionCollapsed: false,
                externalAccountSectionErrors: ["Account Number is required to continue to Raven Account details"],
                accountExternalIdErrorOccurred: true
            });
            return;
        }

        const cancelExternalAccountApply = () => {
            this.setState({
                confirmationDialog: null,
                externalAccountSectionPending: false,
                ravenAccountSectionPending: false,
                shippingSectionPending: false
            });
        };

        const switctToRavenAccountForExternalAccountId = (account) => {

            // orderType was cleared in preparation for this account switch, but the correct default is required for Geotab Integration validation purposes
            let orderType = ORDER_TYPES.RAVEN; // default
            const geotabIntegration = account.integrations ? account.integrations.find( integration => { return integration.type === "GEOTAB" }) : undefined;
            if (geotabIntegration) {
                // clear orderType to force user choice when placing the new order
                orderType = undefined;
            }

            this.setState({
                confirmationDialog: null,
                accountIntegrationsSectionCollapsed: false,
                ravenAccountSectionCollapsed: false,
                orderType: orderType, // resetting to default or clearing to force user choice for geotab
                orderSectionCollapsed: false
            });

            this.applyAccountToState(account);
        };

        const completeExternalAccountIdApply = () => {

            this.setState({
                confirmationDialog: null,
                externalAccountSectionPending: false,
                externalAccountSectionErrors: [],
                accountExternalIdErrorOccurred: false,
                accountIntegrationsSectionPending: false,
                ravenAccountSectionPending: false,
                shippingSectionPending: false
            });

            if (this.state.accountForEditMode) { // update account immediately
                this.updateRavenAccountExternalId();
                return;
            }
            // proceed to add New Raven Account details
            this.setState({
                externalAccountSectionConfirmedForSave: true,
                accountIntegrationsSectionCollapsed: false
            });
        }

        this.setState({
            externalAccountSectionConfirmedForSave: false
        });

        const applyAccountToState = false;

        this.getAccount(this.state.accountExternalId, applyAccountToState)
        .then(
            (account) => {

                let title = "Account Found";
                let message = "A Raven Account is already associated with this " + this.accountExternalIdTitle + ".  Would you like to add a new Order for this account?";
                if (this.ordersFulfillmentEnabled !== true) {
                    message = "A Raven Account is already associated with this " + this.accountExternalIdTitle + ".  Would you like to edit this account?";
                }
                let cancelButtonTitle = "No, Try A Different Account Number";
                let submitButtonTitle = "Yes, Continue With This Account";

                let clearOrderForEditMode = false;

                if (this.isEditMode()) {

                    clearOrderForEditMode = true;

                    message = "A Raven Account is already associated with this " + this.accountExternalIdTitle + ".  Would you like to switch to this Raven Account?";
                    submitButtonTitle = "Yes, Switch To This Account";
                }

                this.setState({
                    confirmationDialog: <ConfirmationDialog
                        title={title}
                        message={message}
                        cancelButtonAction={cancelExternalAccountApply}
                        cancelButtonTitle={cancelButtonTitle}
                        hideHeaderCloseButton={true}
                        submitButtonAction={() => {
                            if (clearOrderForEditMode) {
                                this.setState({
                                    orderSectionSaved: undefined,
                                    orderSectionErrors: [],

                                    ratePlans: this.props.ratePlans,
                                    itemTypes: this.props.itemTypes,

                                    orderForEditMode: undefined,
                                    orderExternalId: undefined,
                                    orderExternalIdErrorOccurred: undefined,
                                    orderType: undefined,
                                    orderTypeErrorOccurred: undefined,
                                    orderComment: undefined,
                                    orderCommentErrorOccurred: undefined,
                        
                                    totalRavens: undefined,
                                    totalRavensErrorOccurred: undefined,

                                    totalAccessories: undefined,
                                    totalAccessoriesErrorOccurred: undefined
                                });
                            }
                            switctToRavenAccountForExternalAccountId(account);
                            if (this.ordersFulfillmentEnabled) {
                                if (!this.props.ratePlans) {
                                    this.getRatePlans(); // load rate plans if resetting quantities removed them altogether
                                }

                                if (!this.props.itemTypes) {
                                    this.getItemTypes(); // load item types if resetting quantities removed them altogether
                                }
                            }
                        }}
                        submitButtonTitle={submitButtonTitle}
                    />
                })
            },
            (error) => {
                const errorCode = error ?  error.code : undefined;
                switch (errorCode) {
                    case 404:
                        // ASSUMPTION: this is for a new account
                        this.allowAccessoriesOnlyOrders = false; // RAV-5667 Only existng accounts can place accessories-only orders // RAV-5517 Not allowing beacon only orders for new accounts
                        completeExternalAccountIdApply();
                        return;
                    default:
                        this.setState({
                            externalAccountSectionErrors: [this.accountExternalIdTitle + " lookup failed.  An error occurred"],
                            accountExternalIdErrorOccurred: true,
                            externalAccountSectionPending: false
                        });
                        return;
                }
            }
        );
    }

    onExpandExternalAccountSectionClick = () => {
        this.setState({externalAccountSectionCollapsed: !this.state.externalAccountSectionCollapsed});
    }

    onExpandAccountIntegrationsSectionClick = () => {
        if (!this.state.accountIntegrationsSectionCollapsed) {
            this.setState({accountIntegrationsSectionCollapsed: true});
            return;
        }
        if (!this.state.accountForEditMode && !this.state.externalAccountSectionConfirmedForSave) { // adding a new account
            this.setState({
                externalAccountSectionCollapsed: false,
                externalAccountSectionErrors: ["Account Number is required to continue to Geotab Integration"],
                accountExternalIdErrorOccurred: true
            });
            return;
        }
        this.setState({accountIntegrationsSectionCollapsed: !this.state.accountIntegrationsSectionCollapsed});
    };

    onExpandRavenAccountSectionClick = () => {
        if (!this.state.ravenAccountSectionCollapsed) {
            this.setState({ravenAccountSectionCollapsed: true});
            return;
        }
        if (!this.state.accountForEditMode && !this.state.externalAccountSectionConfirmedForSave) { // adding a new account
            this.setState({
                externalAccountSectionCollapsed: false,
                externalAccountSectionErrors: ["Account Number is required to continue to Raven Account details"],
                accountExternalIdErrorOccurred: true,
                externalAccountSectionPending: false
            });
            return;
        }
        if (!this.state.accountIntegrations) {
            this.setState({
                accountIntegrationsSectionCollapsed: false,
                accountIntegrationsSectionErrors: ["Select 'Yes' or 'No' to continue to Raven Account details"],
                accountIntegrationsErrorOccurred: true
            });
            return;
        }
        this.setState({ravenAccountSectionCollapsed: !this.state.ravenAccountSectionCollapsed});
    }

    onExpandOrderSectionClick = () => {
        if (!this.state.orderSectionCollapsed) {
            this.setState({orderSectionCollapsed: true});
            return;
        }
        if (!this.state.accountForEditMode && !this.state.externalAccountSectionConfirmedForSave) { // adding a new account

            this.setState({
                externalAccountSectionCollapsed: false,
                externalAccountSectionErrors: ["Account details are required to continue to Order details"],
                accountExternalIdErrorOccurred: true
            });
            return;
        }
        if (!this.state.accountIntegrations) {
            this.setState({
                accountIntegrationsSectionCollapsed: false,
                accountIntegrationsSectionErrors: ["Select 'Yes' or 'No' to continue to Order details"],
                accountIntegrationsErrorOccurred: true
            });
            return;
        }
        this.setState({orderSectionCollapsed: !this.state.orderSectionCollapsed});
    }

    onExpandShippingSectionClick = () => {
        if (!this.state.shippingSectionCollapsed) {
            this.setState({shippingSectionCollapsed: true});
            return;
        }
        if (!this.state.accountForEditMode && !this.state.externalAccountSectionConfirmedForSave) { // adding a new account
            this.setState({
                externalAccountSectionCollapsed: false,
                externalAccountSectionErrors: ["Account details are required to continue to Shipping information"],
                accountExternalIdErrorOccurred: true
            });
            return;
        }
        if (!this.state.accountIntegrations) {
            this.setState({
                accountIntegrationsSectionCollapsed: false,
                accountIntegrationsSectionErrors: ["Select 'Yes' or 'No' to continue to Shipping information"],
                accountIntegrationsErrorOccurred: true
            });
            return;
        }
        this.setState({shippingSectionCollapsed: !this.state.shippingSectionCollapsed});
    }

    isEditMode = () => {

        if (this.state.addingAllNewAccountOrderAndShipping) {
            console.warn("OrderForm isEditMode locked to false for this.state.addingAllNewAccountOrderAndShipping");
            return false;
        }

        if (this.state.orderForEditMode) {
            return true;
        }
        if (this.state.accountForEditMode) {
            return true;
        }
        return false;
    }

    unsavedAccountEditsFound = () => {

        if (!this.state.accountForEditMode) {
            console.warn("OrderForm unsavedAccountEditsFound requested prior to populating accountForEditMode.");
            return false; // nothing to edit
        }

        if (!this.state.accountForEditMode.owner || !this.state.accountForEditMode.owner.email) {
            console.error("OrderForm unsavedAccountEditsFound checked while accountForEditMode missing or invalid owner property.");
            return false; // nothing to edit
        }

        if (!this.state.accountForEditMode.primaryAddress || !this.state.accountForEditMode.primaryAddress.name) {
            console.error("OrderForm unsavedAccountEditsFound checked while accountForEditMode missing or invalid primaryAddress property.");
            return false; // nothing to edit
        }

        if (this.state.email !== this.state.accountForEditMode.owner.email) {
            console.log("OrderForm unsavedAccountEditsFound for email");
            return true;
        }

        if (this.state.welcomeName !== this.state.accountForEditMode.primaryAddress.name) {
            console.log("OrderForm unsavedAccountEditsFound for welcomeName");
            return true;
        }

        const notes = this.state.notes ? this.state.notes : null;
        const accountForEditModeNotes = this.state.accountForEditMode.notes ? this.state.accountForEditMode.notes : null;
        if (notes !== accountForEditModeNotes) {
            console.log("OrderForm unsavedAccountEditsFound for notes");
            console.log(accountForEditModeNotes);
            return true;
        }

        return false;
    }

    unsavedOrderEditsFound = () => {

        if (!this.state.orderForEditMode) {
            console.warn("OrderForm unsavedOrderEditsFound requested prior to populating orderForEditMode.");
            return false; // nothing to edit
        }

        if (this.state.orderForEditMode.orderNumber !== this.state.orderExternalId) {
            console.log("OrderForm unsavedOrderEditsFound for orderNumber");
            return true;
        }

        const sanitizedOrderCommentFromState = this.state.orderComment ? this.state.orderComment : null; // DB using null, state uses undefined
        if (this.state.orderForEditMode.comment !== sanitizedOrderCommentFromState) {
            console.log("OrderForm unsavedOrderEditsFound for orderComment");
            return true;
        }

        /* orderType changes in edit mode are applied instantly
        if (this.state.orderForEditMode.orderType !== this.state.orderType) {
            console.log("OrderForm unsavedOrderEditsFound for orderType", this.state.orderForEditMode.orderType);
            return true;
        }*/

        return false;
    }

    unsavedShippingEditsFound = () => {

        if (!this.state.accountForEditMode) {
            console.warn("OrderForm unsavedShippingEditsFound requested prior to populating accountForEditMode.");
            return false; // nothing to edit
        }

        if (!this.state.accountForEditMode.primaryAddress || !this.state.accountForEditMode.primaryAddress.name) {
            console.error("OrderForm unsavedShippingEditsFound checked while accountForEditMode missing or invalid primaryAddress property.");
            return false; // nothing to edit
        }

        if (this.state.shippingAddressStreetLine1 !== this.state.accountForEditMode.primaryAddress.address1) {
            console.log("OrderForm unsavedShippingEditsFound for address1");
            return true;
        }

        const shippingAddressStreetLine2 = this.state.shippingAddressStreetLine2 ? this.state.shippingAddressStreetLine2 : null;
        if (shippingAddressStreetLine2 !== this.state.accountForEditMode.primaryAddress.address2) {
            console.log("OrderForm unsavedShippingEditsFound for address2");
            console.log(this.state.accountForEditMode.notes);
            return true;
        }

        if (this.state.shippingAddressCity !== this.state.accountForEditMode.primaryAddress.city) {
            console.log("OrderForm unsavedShippingEditsFound for city");
            return true;
        }

        if (this.state.shippingAddressZipCode !== this.state.accountForEditMode.primaryAddress.postcode) {
            console.log("OrderForm unsavedShippingEditsFound for postcode");
            return true;
        }

        if (this.state.shippingAddressRegion !== this.state.accountForEditMode.primaryAddress.state) {
            console.log("OrderForm unsavedShippingEditsFound for state");
            return true;
        }

        if (this.state.shippingAddressCountry !== this.state.accountForEditMode.primaryAddress.country) {
            console.log("OrderForm unsavedShippingEditsFound for country");
            return true;
        }

        if (this.state.shippingPhoneNumber !== this.state.accountForEditMode.primaryAddress.phoneNumber) {
            console.log("OrderForm unsavedShippingEditsFound for phone number");
            return true;
        }

        return false;
    }

    accessoryItemTypesFromState = () => {

        if (!this.state.itemTypes) {
            console.warn("OrderForm accessoryItemTypesFromState no itemTypes yet");
            return;
        }

        const availableCategories = this.state.orderType === ORDER_TYPES.RAVEN ? ["ACCESSORY", "BEACON"] : ["ACCESSORY"];

        const accessoryItemTypes = [];

        this.state.itemTypes.forEach( (itemType) => {
            if (availableCategories.includes(itemType.category)) {
                itemType.isIncluded = itemType.included && itemType.included[this.state.orderType] ? true : false;
                accessoryItemTypes.push(itemType);
            }
        });

        if (accessoryItemTypes.length === 0) {
            console.warn("OrderForm accessoryItemTypesFromState no Accessories yet");
            return undefined;
        }

        return accessoryItemTypes;
    }

    onClose = () => {
        if (this.isEditMode()) {
            if (this.state.validateGeotabOnDismiss) {
                if (!this.allFormsAreValid()) {
                    return;
                }
            }    
        }
        this.props.onCancel();
    };

    render () {

        const renderForEditMode = this.isEditMode();
        const collapsibleClass = " collapsible";
        let externalAccountCollapsibleClasses = collapsibleClass;
        let accountIntegrationsCollapsibleClasses = collapsibleClass;
        let ravenAccountCollapsibleClasses = collapsibleClass;
        let orderCollapsibleClasses = collapsibleClass;
        let shippingCollapsibleClasses = collapsibleClass;

        if (this.state.externalAccountSectionCollapsed) {externalAccountCollapsibleClasses += " collapsed"};
        if (this.state.accountIntegrationsSectionCollapsed) {accountIntegrationsCollapsibleClasses += " collapsed"};
        if (this.state.ravenAccountSectionCollapsed) {ravenAccountCollapsibleClasses += " collapsed"};
        if (this.state.orderSectionCollapsed) {orderCollapsibleClasses += " collapsed"};
        if (this.state.shippingSectionCollapsed) {shippingCollapsibleClasses += " collapsed"};

        let vendorAccountSectionTitle = "Linked Account";
        if (globalconfig.features &&
            globalconfig.features.accounts &&
            globalconfig.features.accounts.externalSectionOptionalTitle) {

            vendorAccountSectionTitle = globalconfig.features.accounts.externalSectionOptionalTitle;
        }

        let accountIntegrationsSectionTitle = "Geotab Integration?";
        let accountIntegrationsSelectionDescription = "Please select one before proceeding";
        let ravenAccountSectionTitle = "New Raven Account";
        let ravenAccountSectionSummaryDescription = undefined;

        if (this.state.accountIntegrations) {
            // currently, geotab or none are support (either or)
            if (this.state.accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB) {
                accountIntegrationsSelectionDescription = "Customer will have access to MyGeotab Portal";
                ravenAccountSectionTitle = "Email Address For Welcome Email";
                ravenAccountSectionSummaryDescription = "All Raven devices will be registered as Geotab devices. " +
                                                        "An email will be sent to the customer explaining how to activate the " +
                                                        "Raven Geotab Add-in in their MyGeotab account. " +
                                                        "No Raven web app username/password will be created.";
            } else {
                accountIntegrationsSelectionDescription = "Customer will use TELUS | Raven Web App";
            }
        }

        let ravenAccountNameLabel = "Welcome Email Name";

        let showExternalAccountApplyButton = false;
        let externalAccountApplyButtonTitle = "Continue to 'Geotab Integration'";

        let showAccountIntegrationsUpdateButton = false; // Edit mode only
        let accountIntegrationsUpdateButtonTitle = ""; // Edit mode only

        let showRavenAccountUpdateButton = false; // Edit mode only
        const ravenAccountUpdateButtonTitle = "Update Account Details"; // Edit mode only

        let showOrderUpdateButton = false; // Edit mode only
        const orderUpdateButtonTitle = "Update Order Comment"; // Edit mode only

        let showShippingUpdateButton = false; // Edit mode only
        const shippingUpdateButtonTitle = "Update Shipping Address";

        let accountExternalIdIsReadyOnly = false; // locked when externalAccountSectionConfirmedForSave is true 
        let orderIsReadOnly = false;

        const accessoryItemTypes = this.accessoryItemTypesFromState();

        if (renderForEditMode) {

            accountExternalIdIsReadyOnly = true; // RAV-2815

            if (this.state.orderForEditMode) {
                orderIsReadOnly = true;
            }

            ravenAccountSectionTitle = "Raven Account";
            ravenAccountNameLabel = "Name";

            if (this.state.accountForEditMode) { // populated in either this.prefillFormForEditMode or this.addAccount response
                if (this.state.accountExternalId !== this.state.accountForEditMode.accountId) {
                    externalAccountApplyButtonTitle = "Apply New Account Number"
                    showExternalAccountApplyButton = true;
                }


                let accountForEditModeGeotabIntegrationFound = false;

                if (this.state.accountForEditMode.integrations) {
                    for (let i = 0; i < this.state.accountForEditMode.integrations.length; i += 1) {
                        if (this.state.accountForEditMode.integrations[i].type === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB) {
                            accountForEditModeGeotabIntegrationFound = true;
                            break;
                        }
                    }
                }

                if (this.state.accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB) {

                    if (!accountForEditModeGeotabIntegrationFound) {
                        showAccountIntegrationsUpdateButton = true;
                        accountIntegrationsUpdateButtonTitle = "Enable Geotab Integration";
                    }

                } else { // order form currently only supports GEOTAB and NONE, assuming NONE

                    if (accountForEditModeGeotabIntegrationFound) {
                        showAccountIntegrationsUpdateButton = true;
                        accountIntegrationsUpdateButtonTitle = "Remove Geotab Integration";
                    }
                }
            }
            showRavenAccountUpdateButton = this.unsavedAccountEditsFound();
            //if (!orderIsReadOnly) {
                showOrderUpdateButton = this.unsavedOrderEditsFound();
            //}
            showShippingUpdateButton = this.unsavedShippingEditsFound();

        } else {
            if (this.state.accountExternalId) {
                if (!this.state.externalAccountSectionConfirmedForSave) {
                    showExternalAccountApplyButton = true;
                } else {
                    accountExternalIdIsReadyOnly = true; // new acount creation and external id has been confirmed (user should not be able to risk switching accounts at this stage)
                }
            }
        }

        const orderCommentMaxCharacters = 255;
        const orderCommentLength = this.state.orderComment? this.state.orderComment.length : 0;
        let orderCommentOverLimit = (orderCommentLength > orderCommentMaxCharacters);
        let orderCommentMaxCharacterIndicatorClass = orderCommentOverLimit ? "textarea-max-character-indicator overlimit" : "textarea-max-character-indicator";

        const availablePlansBasedOnSelectedType = this.state.ratePlans ? this.state.ratePlans.filter(rp => rp.size === this.state.ratePlanType) : undefined;

        return (
            <>
            <div className="order-form">
                
                <div className="required-legend">
                    <div><span className="required-badge">•</span> required</div>
                </div>

                <header>
                    {vendorAccountSectionTitle}
                    <button className={"disclosure" + externalAccountCollapsibleClasses} onClick={this.onExpandExternalAccountSectionClick}>
                        {this.state.externalAccountSectionCollapsed ?
                            <span className="material-icons">keyboard_arrow_up</span>
                        :
                            <span className="material-icons">keyboard_arrow_down</span>
                        }
                    </button>
                    <button className={"disclosure-overlay" + externalAccountCollapsibleClasses} onClick={this.onExpandExternalAccountSectionClick}></button>
                    {this.state.externalAccountSectionSaved ? <>✓<span className="success-badge">Saved</span></> : null}

                </header>
                {<ul>{this.state.externalAccountSectionErrors.map( (errorString, index) => {return <li className="error-message" key={index}>{errorString}</li>})}</ul>}

                <section ref={this.externalAccountSectionDOMReference} className={"external-account" + externalAccountCollapsibleClasses}>
                    <div>
                        <label>{this.accountExternalIdTitle}<span className="required-badge">•</span></label>
                        <input autoComplete="off" type="text" value={this.state.accountExternalId || ""} disabled={accountExternalIdIsReadyOnly} name="accountExternalId" onChange={this.onInputChange} className={this.state.accountExternalIdErrorOccurred? "error-border" : null}></input>
                        {/*<span className="material-icons">search</span>*/}
                    </div>
                    <div></div>
                    {showExternalAccountApplyButton ?
                        <div className="actions">
                            <button onClick={this.onExternalAccountApplyClick} >
                                {externalAccountApplyButtonTitle}
                            </button>
                        </div>
                    :
                        null
                    }
                    {this.state.externalAccountSectionPending ? <ProgressOverlay /> : null }
                </section>

                <div className="hr-with-shadow">
                    <hr />
                </div>

                <header>
                    {accountIntegrationsSectionTitle}
                    <button className={"disclosure" + accountIntegrationsCollapsibleClasses} onClick={this.onExpandAccountIntegrationsSectionClick}>
                        {this.state.accountIntegrationsSectionCollapsed ?
                            <span className="material-icons">keyboard_arrow_up</span>
                        :
                            <span className="material-icons">keyboard_arrow_down</span>
                        }
                    </button>
                    <button className={"disclosure-overlay" + accountIntegrationsCollapsibleClasses} onClick={this.onExpandAccountIntegrationsSectionClick}></button>
                    {this.state.accountIntegrationsSectionSaved ? <>✓<span className="success-badge">Saved</span></> : null}

                </header>
                {<ul>{this.state.accountIntegrationsSectionErrors.map( (errorString, index) => {return <li className="error-message" key={index}>{errorString}</li>})}</ul>}
                <section ref={this.accountIntegrationsSectionDOMReference} className={"account-integrations" + accountIntegrationsCollapsibleClasses}>
                    <div>
                        <div className="order-form-radio-wrapper">
                            <input autoComplete="off" type="radio" name="accountintegrations" checked={this.state.accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB} value={ACCOUNT_INTEGRATIONS_TYPES.GEOTAB} id={ACCOUNT_INTEGRATIONS_TYPES.GEOTAB} onChange={this.onInputChange} />
                            <label htmlFor={ACCOUNT_INTEGRATIONS_TYPES.GEOTAB}>Yes</label>
                        </div>

                        <div className="order-form-radio-wrapper">
                            <input autoComplete="off" type="radio" name="accountintegrations" checked={this.state.accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.NONE} value={ACCOUNT_INTEGRATIONS_TYPES.NONE} id={ACCOUNT_INTEGRATIONS_TYPES.NONE} onChange={this.onInputChange} />
                            <label htmlFor={ACCOUNT_INTEGRATIONS_TYPES.NONE}>No</label>
                        </div>

                        <span className="required-badge">•</span>{accountIntegrationsSelectionDescription}
                        {/*<span className="material-icons">search</span>*/}
                    </div>
                    <div></div>
                    {showAccountIntegrationsUpdateButton ?
                        <div className="actions">
                            <button onClick={this.updateAccountIntegrationsApplyClick} >
                                {accountIntegrationsUpdateButtonTitle}
                            </button>
                        </div>
                    :
                        null
                    }
                    {this.state.accountIntegrationsSectionPending ? <ProgressOverlay /> : null }
                </section>

                <div className="hr-with-shadow">
                    <hr />
                </div>

                <header>
                    {ravenAccountSectionTitle}
                    <button className={"disclosure" + ravenAccountCollapsibleClasses} onClick={this.onExpandRavenAccountSectionClick}>
                        {this.state.ravenAccountSectionCollapsed ?
                            <span className="material-icons">keyboard_arrow_up</span>
                        :
                            <span className="material-icons">keyboard_arrow_down</span>
                        }
                    </button>
                    <button className={"disclosure-overlay" + ravenAccountCollapsibleClasses} onClick={this.onExpandRavenAccountSectionClick}></button>
                    {this.state.ravenAccountSectionSaved ? <>✓<span className="success-badge">Saved</span></> : null}
                </header>
                {<ul>{this.state.ravenAccountSectionErrors.map( (errorString, index) => {return <li className="error-message" key={index}>{errorString}</li>})}</ul>}

                <section ref={this.ravenAccountSectionDOMReference} className={"account" + ravenAccountCollapsibleClasses}>
                    <div>
                        <label>Email Address<span className="required-badge">•</span></label>
                        <input autoComplete="new-password" disabled={renderForEditMode} type="text" value={this.state.email || ""} name="email" onChange={this.onInputChange} className={this.state.emailErrorOccurred? "error-border" : null}></input>
                    </div>
                    <div>
                        <label>{ravenAccountNameLabel}<span className="required-badge">•</span></label>
                        <input autoComplete="new-password" disabled={renderForEditMode} type="text" value={this.state.welcomeName || ""} name="welcomeName" onChange={this.onInputChange} className={this.state.welcomeNameErrorOccurred? "error-border" : null}></input>
                    </div>
                    <div className="textarea">
                        <label>Notes</label>
                        <textarea name="notes" value={this.state.notes || ""} onChange={this.onInputChange} className={this.state.notesErrorOccurred? "error-border" : null} />
                    </div>
                    {renderForEditMode ? 
                        null
                    :
                        <div>
                            <label>Send Welcome Email</label>
                            <input autoComplete="off" type="checkbox" checked={this.state.sendWelcomeEmail} name="sendWelcomeEmail" onChange={this.onInputChange}/>
                        </div>
                    }
                    {ravenAccountSectionSummaryDescription ?
                        <div>
                            {ravenAccountSectionSummaryDescription}
                        </div>
                    :
                        null
                    }
                    {showRavenAccountUpdateButton ?
                        <div className="actions">
                            <button onClick={this.updateRavenAccount} >
                                {ravenAccountUpdateButtonTitle}
                            </button>
                        </div>
                    :
                        null
                    }

                    {this.state.ravenAccountSectionPending ? <ProgressOverlay /> : null }
                </section>

                {this.ordersFulfillmentEnabled !== true ?
                    null
                :
                    <>
                        <div className="hr-with-shadow">
                            <hr />
                        </div>

                        <header>
                            Order
                            <button className={"disclosure" + orderCollapsibleClasses} onClick={this.onExpandOrderSectionClick}>
                                {this.state.orderSectionCollapsed ?
                                    <span className="material-icons">keyboard_arrow_up</span>
                                :
                                    <span className="material-icons">keyboard_arrow_down</span>
                                }
                            </button>
                            <button className={"disclosure-overlay" + orderCollapsibleClasses} onClick={this.onExpandOrderSectionClick}></button>
                            {this.state.orderSectionSaved ? <>✓<span className="success-badge">Saved</span></> : null}
                        </header>
                        {<ul>{this.state.orderSectionErrors.map( (errorString, index) => {return <li className="error-message" key={index}>{errorString}</li>})}</ul>}

                        <section ref={this.orderSectionDOMReference} className={"order-details" + orderCollapsibleClasses}>
                            <div>
                                <label>Order Number<span className="required-badge">•</span></label>
                                <input autoComplete="off" type="text" value={this.state.orderExternalId || ""} name="orderExternalId" disabled={orderIsReadOnly} onChange={this.onInputChange} className={this.state.orderExternalIdErrorOccurred? "error-border" : null}></input>
                            </div>
                            <div></div>
                            <div className="textarea">
                                <label>Comment</label>
                                <textarea name="orderComment" value={this.state.orderComment || ""} onChange={this.onInputChange} className={this.state.orderCommentErrorOccurred? "error-border" : null} />
                                <div className={orderCommentMaxCharacterIndicatorClass}>({orderCommentLength}/{orderCommentMaxCharacters})</div>
                            </div>

                            { this.state.accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB ?
                                <div>
                                    <label>Geotab Order Type<span className="required-badge">•</span></label>
                                    <div className="order-form-radio-wrapper">
                                        <input autoComplete="off" type="radio" name="orderType" checked={this.state.orderType === ORDER_TYPES.RAVEN_GT} value={ORDER_TYPES.RAVEN_GT} id={ORDER_TYPES.RAVEN_GT} onChange={this.onInputChange} />
                                        <label htmlFor={ORDER_TYPES.RAVEN_GT}>GT (paired with Go9)</label>
                                    </div>

                                    <div className="order-form-radio-wrapper">
                                        <input autoComplete="off" type="radio" name="orderType" checked={this.state.orderType === ORDER_TYPES.RAVEN_GTS} value={ORDER_TYPES.RAVEN_GTS} id={ORDER_TYPES.RAVEN_GTS} onChange={this.onInputChange} />
                                        <label htmlFor={ORDER_TYPES.RAVEN_GTS}>GTS (stand-alone)</label>
                                    </div>
                                </div>
                                :
                                null
                            }

                            {/*<div>
                                <label>Total Ravens to Order<span className="required-badge">•</span></label>
                                <input autoComplete="off" type="text" value={this.state.totalRavens || ""} name="totalRavens" onChange={this.onInputChange} className={this.state.totalRavensErrorOccurred? "error-border" : null}></input>
                            </div>*/}
                            {renderForEditMode && orderIsReadOnly ? // Editing an existing order
                                <div className="actions">
                                    {this.state.orderForEditMode.status !== ORDER_STATUS.COMPLETED ?
                                        <button className="destructive" onClick={this.cancelOrder} >
                                            Cancel Order
                                        </button>
                                    :
                                        null
                                    }
                                    {showOrderUpdateButton ?
                                        <button onClick={this.updateOrderComment} >
                                            {orderUpdateButtonTitle}
                                        </button>
                                    :
                                        null
                                    }

                                </div>
                            :
                                null
                            }
                            {this.state.orderSectionPending ? <ProgressOverlay /> : null }
                        </section>
                        {availablePlansBasedOnSelectedType?
                            <>
                            
                            <section ref={this.orderUnitsSectionDOMReference} className={"order-units" + orderCollapsibleClasses}>
                                <div className='order-form-checkbox-wrapper'>
                                    <input autoComplete="off" type="checkbox" name="ratePlanType" id="ratePlanType" checked={this.state.ratePlanType === RATE_PLAN_TYPES.NO_WIFI} onChange={this.onInputChange} />
                                    <label htmlFor="ratePlanType"> 2GB (No WiFi Hotspot) plan</label>
                                </div>
                                {this.state.ratePlanType === RATE_PLAN_TYPES.NO_WIFI && <div className='warning-message'>Notice: You've selected 2GB (No Wifi Hotspot) plan</div>}
                                <header>
                                    <div className="term">
                                        Term
                                    </div>
                                    <div className="device">
                                        Device
                                    </div>
                                    <div className="units">
                                        Units
                                    </div>
                                </header>
                                {availablePlansBasedOnSelectedType.map((ratePlan, index) => {
                                    return (<div key={index} className="unit">
                                        <div className="term">
                                            {ratePlan.name}
                                        </div>
                                        <div className="device">
                                            Raven Plus
                                        </div>
                                        <div className="units">
                                            <input autoComplete="off"
                                                value={ratePlan.quantity ? ratePlan.quantity : ""}
                                                name={RATE_PLANS_INPUT_NAME_PREFIX + ratePlan.id}
                                                onChange={this.onInputChange}
                                                type="text"
                                                placeholder="none"
                                            />
                                        </div>
                                    </div>);
                                })}
                                <div className="unit summary">
                                    <div className="term"></div>
                                    <div className="device total">Total Ravens to Order<span className="required-badge">•</span></div>
                                    <div className={this.state.totalRavensErrorOccurred? "units total error-border" : "units total"} >{this.state.totalRavens}</div>
                                </div>
                                {renderForEditMode && orderIsReadOnly ? // Editing an existing order
                                    <div className="action-disabled-overlay">
                                        <button className="icon" title="Edit quantities (add or remove items) in Orders table">
                                            <span className="disabled-status">Can't Edit Units Or Quantities Here</span>
                                            <span className="material-icons">info</span>
                                        </button>
                                    </div>
                                :
                                    this.state.accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB && !this.state.orderType ?
                                        <div className="action-disabled-overlay">
                                            <button className="icon" title="Set the type of Geotab Ravens for this order above">
                                                <span className="disabled-status">Select Geotab Order Type</span>
                                                <span className="material-icons">info</span>
                                            </button>
                                        </div>

                                    :
                                    null
                                }
                                {this.state.orderSectionPending ? <ProgressOverlay /> : null }
                            </section>
                            </>
                        :
                            null
                        }
                        {accessoryItemTypes ?
                            <section ref={this.orderAccessoriesSectionDOMReference} className={"order-accessories" + orderCollapsibleClasses}>
                                <header>
                                    <div className="device">
                                        Accessory
                                    </div>
                                    <div className="units">
                                        Totals
                                    </div>
                                </header>
                                {accessoryItemTypes.map((accessoryItemType, index) => {
                                    return (<div key={index} className="unit">
                                        <div className="device">
                                            {accessoryItemType.name} {accessoryItemType.isIncluded ? "(included)" : null}
                                        </div>
                                        <div className="units">
                                            <input autoComplete="off"
                                                value={accessoryItemType.quantity ? accessoryItemType.quantity : ""}
                                                name={ACCESSORY_ITEM_TYPE_INPUT_NAME_PREFIX + accessoryItemType.id}
                                                onChange={this.onInputChange}
                                                type="text"
                                                placeholder="none"
                                            />
                                        </div>
                                    </div>);
                                })}
                                <div className="unit summary">
                                <div className="device total">Total Accessories to Order{this.state.totalRavens ? " (at least " + this.state.totalRavens + " SD Cards)" : null}<span className="required-badge">•</span></div>
                                    <div className={this.state.totalAccessoriesErrorOccurred? "units total error-border" : "units total"} >{this.state.totalAccessories}</div>
                                </div>
                                {renderForEditMode && orderIsReadOnly ? // Editing an existing order
                                    <div className="action-disabled-overlay">
                                        <button className="icon" title="Edit quantities (add or remove items) in Orders table">
                                            <span className="disabled-status">Can't Edit Accessories Quantities Here</span>
                                            <span className="material-icons">info</span>
                                        </button>
                                    </div>
                                :
                                    this.state.accountIntegrations === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB && !this.state.orderType ?
                                        <div className="action-disabled-overlay">
                                            <button className="icon" title="Set the type of Geotab Ravens for this order above">
                                                <span className="disabled-status">Select Geotab Order Type</span>
                                                <span className="material-icons">info</span>
                                            </button>
                                        </div>

                                    :
                                        renderForEditMode && !orderIsReadOnly ? // Adding an order
                                            <div className="actions">
                                                <button onClick={this.addNewOrder} >{this.primaryActionName}</button>
                                            </div>
                                        :
                                            null
                                }
                                {this.state.orderSectionPending ? <ProgressOverlay /> : null }
                            </section>
                        :
                            null
                        }
                    </>
                }

                <div className="hr-with-shadow">
                    <hr />
                </div>

                <header>
                    Shipping
                    <button className={"disclosure" + shippingCollapsibleClasses} onClick={this.onExpandShippingSectionClick}>
                        {this.state.shippingSectionCollapsed ?
                            <span className="material-icons">keyboard_arrow_up</span>
                        :
                            <span className="material-icons">keyboard_arrow_down</span>
                        }
                    </button>
                    <button className={"disclosure-overlay" + shippingCollapsibleClasses} onClick={this.onExpandShippingSectionClick}></button>
                    {this.state.shippingSectionSaved ? <>✓<span className="success-badge">Saved</span></> : null}
                </header>
                {<ul>{this.state.shippingSectionErrors.map( (errorString, index) => {return <li className="error-message" key={index}>{errorString}</li>})}</ul>}

                <section ref={this.shippingSectionDOMReference} className={"shipping" + shippingCollapsibleClasses}>
                    <div>
                        <label>Phone Number</label>
                        <input type="text" value={this.state.shippingPhoneNumber || ""} name="shippingPhoneNumber" onChange={this.onInputChange} className={this.state.shippingPhoneNumberErrorOccurred? "error-border" : null}></input>
                    </div>
                    <div></div>{/* spacer div*/}
                    <div>
                        <label>Street Address Line 1<span className="required-badge">•</span></label>
                        <input autoComplete="new-password" type="text" value={this.state.shippingAddressStreetLine1 || ""} name="shippingAddressStreetLine1" onChange={this.onInputChange} className={this.state.shippingAddressStreetLine1ErrorOccurred? "error-border" : null}></input>
                    </div>
                    <div>
                        <label>Street Address Line 2</label>
                        <input autoComplete="new-password" type="text" value={this.state.shippingAddressStreetLine2 || ""} name="shippingAddressStreetLine2" onChange={this.onInputChange} className={this.state.shippingAddressStreetLine2ErrorOccurred? "error-border" : null}></input>
                    </div>
                    <div>
                        <label>City<span className="required-badge">•</span></label>
                        <input autoComplete="new-password"  type="text" value={this.state.shippingAddressCity || ""} name="shippingAddressCity" onChange={this.onInputChange} className={this.state.shippingAddressCityErrorOccurred? "error-border" : null}></input>
                    </div>
                    <div>
                        <label>ZIP/Postal Code<span className="required-badge">•</span></label>
                        <input autoComplete="new-password" type="text" value={this.state.shippingAddressZipCode || ""} name="shippingAddressZipCode" onChange={this.onInputChange} className={this.state.shippingAddressZipCodeErrorOccurred? "error-border" : null}></input>
                    </div>
                    <div>
                        <label>State/Province<span className="required-badge">•</span></label>
                        <input autoComplete="new-password" type="text" value={this.state.shippingAddressRegion || ""} name="shippingAddressRegion" onChange={this.onInputChange} className={this.state.shippingAddressRegionErrorOccurred? "error-border" : null}></input>
                    </div>
                    <div>
                        <label>Country<span className="required-badge">•</span></label>
                        <input type="text" value={this.state.shippingAddressCountry || ""} name="shippingAddressCountry" onChange={this.onInputChange} className={this.state.shippingAddressCountryErrorOccurred? "error-border" : null}></input>
                    </div>
                    {showShippingUpdateButton ?
                        <div className="actions">
                            <button onClick={this.updateShipping} >
                                {shippingUpdateButtonTitle}
                            </button>
                        </div>
                    :
                        null
                    }
                    {this.state.shippingSectionPending? <ProgressOverlay /> : null }
                </section>

                <div className="hr-with-shadow">
                    <hr />
                </div>

            </div>
            {this.state.externalAccountSectionSaved &&
            this.state.ravenAccountSectionSaved &&
            (this.state.orderSectionSaved || this.ordersFulfillmentEnabled !== true) &&
            this.state.shippingSectionSaved ?
                <>
                    {this.state.newAccountTemporaryPassword ?
                        <div className="order-form-summary">
                            <label>New Account Temporary Password</label>
                            <input autoComplete="off"
                                type="text"
                                value={this.state.newAccountTemporaryPassword}
                                name="newAccountTemporaryPassword"
                                onClick={this.inputSelectAll}
                                onChange={() => {this.setState({newAccountTemporaryPassword: this.state.newAccountTemporaryPassword})}}></input>
                        </div>
                    :
                        null
                    }
                    <div className="actions">
                        <button onClick={this.props.onSuccess} >Done</button>
                    </div>
                </>
            :
                <div className="actions">
                    {renderForEditMode ?
                        <button className="destructive" onClick={this.onClose} >Close</button>//Close behaviour can be altered by setReloadOnDismiss
                    :
                        <>
                        <button className="destructive" onClick={this.onClose} >Cancel</button>
                        <button onClick={this.primaryFormSubmitAction} >{this.primaryActionName}</button>
                        </>
                    }
                    {this.state.externalAccountSectionPending ||
                    this.state.ravenAccountSectionPending ||
                    (this.state.orderSectionPending && this.ordersFulfillmentEnabled) ||
                    this.state.shippingSectionPending ?
                        <div className="action-disabled-overlay"></div>
                        :
                        null
                    }
                </div>
            }
            {this.state.confirmationDialog}
            </>
        )
    }
}
