import React from 'react';
import PropTypes from 'prop-types';
import globalconfig from '../../../../common/config';
import { ConfirmationDialog, ModalContainer } from '../../../../modals/Modal2';
import ProgressOverlay from '../../../../common/ProgressOverlay';
import RavenDataStore from '../../../../common/ravenDataStore';
import supporttoolIconRavenUnit from './images/supporttool-icon-raven-unit.png';
import supporttoolIconBeacon from './images/supporttool-icon-beacon.png';
import supporttoolIconDeleteBeacon from './images/supporttool-icon-delete-beacon.png';
import supporttoolIconCopyContent from './images/supporttool-icon-copy-content.png';
import supporttoolIconSimCard from './images/supporttool-icon-sim-card.png';
import supporttoolIconSimServiceActivated from './images/supporttool-icon-sim-service-activated.png';
import supporttoolIconShipment from './images/supporttool-icon-shipment.png';
import supporttoolIconLeftArrow from './images/supporttool-icon-left-arrow.png';
import supporttoolIconInfoWhite from './../../../raven/panels/Diagnostics/images/supporttool-icon-info-white.png';
import ZendeskContactWebWidget from './../../widgets/ZendeskContactWebWidget';
import { MAIN_FILTER_TYPES } from './../../ordersPage';
import ProgressSpinnerSmall from '../../../../common/ProgressSpinnerSmall';

import { ORDER_TYPES } from '../../OrderForm';

export const ORDER_STATUS = {
    NEW: "NEW",
    PENDING_RAVENS: "PENDING_RAVENS",
    PENDING_SIM_DETAILS: "PENDING_SIM_DETAILS",
    PENDING_SHIPMENT: "PENDING_SHIPMENT",
    COMPLETED: "COMPLETED",
    CANCELED: "CANCELED"
}

export const ACCESSORY_TYPES = {
    SIMPLE: "SIMPLE",
    BEACON: "BEACON"
}

const ratePlanNameForId = (ratePlans, ratePlanId) => {
    let ratePlanName = "Unknown";
    if (!ratePlanId && ratePlanId !== 0) {
        return ratePlanName;
    }
    if (ratePlans) {
        ratePlans.forEach((ratePlan) => {
            if (ratePlan.id === ratePlanId) {
                ratePlanName = `${ratePlan.name}, ${ratePlan.size}`;
            }
        });
    }
    return ratePlanName;
}

const itemTypeNameForId = (itemTypes, itemTypeId) => {
    let itemTypeName = "Unknown";
    if (!itemTypeId && itemTypeId !== 0) {
        return itemTypeName;
    }
    if (itemTypes) {
        itemTypes.forEach((itemType) => {
            if (itemType.id === itemTypeId) {
                itemTypeName = itemType.name;
            }
        });
    }
    return itemTypeName;
}

const shouldShowEditOrderItemsFeatures = (orderStatus) => {
    let showEditOrderItemsFeatures = false;
    switch (orderStatus) {
        case ORDER_STATUS.NEW:
        case ORDER_STATUS.PENDING_RAVENS:
        case ORDER_STATUS.PENDING_SIM_DETAILS:
            showEditOrderItemsFeatures = true;
            break;
        case ORDER_STATUS.PENDING_SHIPMENT:
        case ORDER_STATUS.COMPLETED:
            break;
        default:
            console.warn("ActiveOrdersTable showEditOrderItemsFeatures false for unrecognized order status " + this.props.order.status);
            break;
    }
    return showEditOrderItemsFeatures;
}


// The following exceptions break the 13-character string length validation for RVP (Raven 2) serial numbers
// These are prototype serial numbers for prototypes from this spreadsheet: https://docs.google.com/spreadsheets/d/10enMg984NqIQbxDSqiVDYp5e3IJ38Fg0gyk9JqusSDQ/edit#gid=1725976178
// There is a small chance these would end up being sent to third parties
const RAVEN2_SN_VALIDATION_EXCEPTIONS = [
    "1RVP40190013",
    "1RVP40190014",
    "1RVP40190019",
    "1RVP40190027",
    "1RVP40190030",
    "1RVP40190032",
    "1RVP40190021",
    "1RVP40190006",
    "1RVP40190025",
    "1RVP40190023",
    "1RVP40190000",
    "1RVP40190001",
    "1RVP40190009",
    "1RVP40190020",
    "1RVP40190022",
    "1RVP40190015",
    "1RVP40190026",
    "1RVP40190008",
    "1RVP40190010",
    "1RVP40190011",
    "1RVP40190033",
    "1RVP40190031",
    "1RVP18200011",
    "1RVP18200009",
    "1RVP18200013",
    "1RVP18200012",
    "1RVP18200018",
    "1RVP18200002",
    "1RVP24200069",
    "1RVP24200073",
    "1RVP24200070"
];

const RAVEN2_EXACT_LENGTH = 13;
const RAVEN1_EXACT_LENGTH = 12;


const INPUT_AUTO_FOCUS_CANDIDATE_ID_CORRESPONDING_STATUS_CLASS = "pending"; // roughed-in spot for status-specific decorations
const INPUT_AUTO_FOCUS_CANDIDATE_ORDER_ID_ATTRIBUTE = "data-order-id";
const INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_ID_PREFIX = "enclosure-serial-no-input-";
const INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE = "data-input-serial-number-auto-focus-candidate";
const INPUT_PROVIDER_ID_AUTO_FOCUS_CANDIDATE_ID_PREFIX = "provider-id-input-";
const INPUT_PROVIDER_ID_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE = "data-input-provider-id-auto-focus-candidate";
const INPUT_AUTO_FOCUS_CANDIDATE_ID_CORRESPONDING_STATUS_ICON_SUFFIX = "status-icon";

export default class ActiveOrdersTable extends React.PureComponent {

    constructor(props) {
        super(props);
        this.autoFocusedOrderItemInput = undefined;
        this.autoFocusedOrderItemRelatedStatusIcon = undefined;
        this.state = {
            orderInfoModal: null,
            accountInfoModal: null,
            supportTicketModal: null,
            updateExternalIdModal: null,
            updateRatePlanIdModal: null,
            confirmDialog: null,
            currentOrderId: null
            // REMINDER: state-driven modals are also listed in checkForActiveModalPrompt
        }
        this.autoFocusedOrderItem = undefined;
        this.autoFocusedOrderItemRelatedStatusIcon = undefined;
        this.autoFocusedOrderItemRelatedArrowImg = undefined;
        this.autoFocusedOrderRelatedStepButton = undefined;
    }

    static propTypes = {
        stage: PropTypes.string.isRequired,
        orders: PropTypes.array,
        ratePlans: PropTypes.array, // [{"id":"1","name":"3 year"},{"id":"2","name":"MTM"}]
        itemTypes: PropTypes.array, // [{"id":"RAVEN","name":"string","category":"DEVICE"}]
        mainFilter: PropTypes.string,
        totalItemsForCategory: PropTypes.func.isRequired,
        onEditOrderClick: PropTypes.func.isRequired,
        onEditAccountClick: PropTypes.func.isRequired,
        onEditShippingAddressClick: PropTypes.func.isRequired,
        onAddOrderItemClick: PropTypes.func.isRequired,
        onRemoveOrderItemClick: PropTypes.func.isRequired,
        onUpdateOrderAccessoriesClick: PropTypes.func.isRequired,
        updateOrderItem: PropTypes.func.isRequired,
        setOrderItem: PropTypes.func.isRequired,
        updateOrderStatusNext: PropTypes.func.isRequired,
        updateOrderStatusBack: PropTypes.func.isRequired,
        checkForMoreOrdersInProgress: PropTypes.bool.isRequired,
        allOrdersLoadedForMainFilter: PropTypes.bool.isRequired
    }

    updateAutoFocusCandidateHighlighting = () => {

        const checkForActiveModalPrompt = () => {
            if (this.state.orderInfoModal ||
                this.state.accountInfoModal ||
                this.state.supportTicketModal ||
                this.state.updateExternalIdModal || // At time of writing, this was the only modal with a "focus" command (∴ the only conflict)
                this.state.confirmDialog) {
                return true;
            }
            return false;
        }

        this.clearCurrentAutoFocusedOrderItemInput();

        let placeholder = "Scan Raven QR"; // default and placeholder for MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_RAVENS
        const statusClass = INPUT_AUTO_FOCUS_CANDIDATE_ID_CORRESPONDING_STATUS_CLASS;


        let selector = this.props.mainFilter === MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_RAVENS ? `[${INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE}]`: `[${INPUT_PROVIDER_ID_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE}]`;

        if(this.state.currentOrderId){
            selector += `[${INPUT_AUTO_FOCUS_CANDIDATE_ORDER_ID_ATTRIBUTE}="${this.state.currentOrderId}"]`;
        }

        let autoFocusCandidates = [...document.querySelectorAll(selector)];
        //const autoFocusCandidatesPendingSimDetails = [...document.querySelectorAll("[" + INPUT_PROVIDER_ID_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE + "]")];
        
        console.log("autoFocusCandidates", selector, autoFocusCandidates);

        switch (this.props.mainFilter) {
            case MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED:
                /* RAV-1778 is for vendor users' perspective
                // leaving this commented-out as guide for when we can support switching to Raven users perspective:
                if (autoFocusCandidatesPendingRavens.length > 0) {
                    autoFocusCandidates = autoFocusCandidatesPendingRavens;
                } else { */
                    placeholder = "Acivate SIM";
                    //autoFocusCandidates = autoFocusCandidatesPendingSimDetails;
                /*} */
                break;
            case MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_RAVENS:
                //autoFocusCandidates = autoFocusCandidatesPendingRavens;
                break;
            case MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_PROVIDER_IDS:
                placeholder = "Acivate SIM";
                //autoFocusCandidates = autoFocusCandidatesPendingSimDetails;
                break;
            case MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_SHIPMENT:
            case MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED:
                return;
            default:
                console.error("ActiveOrdersTable updateAutoFocusCandidateHighlighting invalid mainFilter in state, " + this.props.mainFilter);
                return;
        }        

        if (autoFocusCandidates.length === 0){

            if(!this.state.currentOrderId) return;

            const stepButton = document.querySelector(`.row[data-order-id="${this.state.currentOrderId}"] .complete-step button`);
            if(stepButton){
                this.autoFocusedOrderRelatedStepButton = stepButton;
                this.autoFocusedOrderRelatedStepButton.classList.add(statusClass);
                this.autoFocusedOrderRelatedStepButton.focus();
            }

            return;            
        }

        
        this.autoFocusedOrderItemInput = autoFocusCandidates[0];

        if(this.autoFocusedOrderItemInput.name.startsWith("BEACON")){
            placeholder = "Scan Mac Address";
        }
        this.autoFocusedOrderItemInput.placeholder =  placeholder;
        this.autoFocusedOrderItemInput.classList.add(statusClass);

        this.autoFocusedOrderItemRelatedArrowImg = document.createElement("IMG");
        this.autoFocusedOrderItemRelatedArrowImg.classList.add("next-step-pointer");
        this.autoFocusedOrderItemRelatedArrowImg.title = "Suggested next step";
        this.autoFocusedOrderItemRelatedArrowImg.classList.add(statusClass);
        this.autoFocusedOrderItemRelatedArrowImg.src = supporttoolIconLeftArrow;

        this.autoFocusedOrderItemInput.parentElement.appendChild(this.autoFocusedOrderItemRelatedArrowImg);

        if (checkForActiveModalPrompt()) { // checking if state changes are to show a prompt
            this.autoFocusedOrderItemInput.blur(); // relinquish focus
        } else {
            this.autoFocusedOrderItemInput.focus();
        }

        
        this.autoFocusedOrderItemRelatedStatusIcon = document.getElementById(this.autoFocusedOrderItemInput.id + INPUT_AUTO_FOCUS_CANDIDATE_ID_CORRESPONDING_STATUS_ICON_SUFFIX);
        if (this.autoFocusedOrderItemRelatedStatusIcon) {
            this.autoFocusedOrderItemRelatedStatusIcon.classList.add(statusClass);
        } else {
            console.error("ActiveOrdersTable updateAutoFocusCandidateHighlighting autoFocusedOrderItemRelatedStatusIcon not found");
        }

        console.log("autoFocusCandidates final", this.autoFocusedOrderItemInput, this.autoFocusedOrderItemRelatedStatusIcon, this.autoFocusedOrderItemRelatedArrowImg);
    }

    componentDidMount() {
        document.addEventListener('focus', this.handleFocus, true);
    }

    clearCurrentAutoFocusedOrderItemInput = () => {
        const statusClass = INPUT_AUTO_FOCUS_CANDIDATE_ID_CORRESPONDING_STATUS_CLASS;

        if(this.autoFocusedOrderRelatedStepButton){
            this.autoFocusedOrderRelatedStepButton.classList.remove(statusClass);
        }

        if (this.autoFocusedOrderItemRelatedArrowImg) {
            this.autoFocusedOrderItemRelatedArrowImg.remove();
        }

        if (this.autoFocusedOrderItemRelatedStatusIcon) {
            this.autoFocusedOrderItemRelatedStatusIcon.classList.remove(statusClass);
        }

        if(this.autoFocusedOrderItemInput){
            this.autoFocusedOrderItemInput.classList.remove(statusClass);
            this.autoFocusedOrderItemInput.placeholder = this.autoFocusedOrderItemInput.name.startsWith("BEACON") ? "Mac Address" : this.autoFocusedOrderItemInput.name.startsWith("unitSerial") ? "Raven unit" : '';
        }
    }

    handleFocus = (e) => {
        if (e.target.hasAttribute(INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE) || e.target.hasAttribute(INPUT_PROVIDER_ID_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE)) {
            const orderId = e.target.dataset.orderId;
            this.setState({currentOrderId: orderId});
        }
    }

    
    componentDidUpdate(prevProps) {
        if(prevProps.mainFilter !== this.props.mainFilter){
            this.setState({currentOrderId: null});
        }

        this.updateAutoFocusCandidateHighlighting();
    }

    onOrderInfoClick = (order) => {
        this.props.onEditOrderClick(order);
    }

    onAccountInfoClick = (order) => {
        this.props.onEditAccountClick(order);
    }

    onShippingInfoClick = (order) => {
        this.props.onEditShippingAddressClick(order);
    }

    onSupportTicketClick = (order) => {

        const dismissModal = () => {
            this.setState({supportTicketModal: null});
        };

        const user = window.user; // CognitoUtil.getUser(); provides an unauthenticated user (∴ temporarily applying window.user in CognitoUtil.refreshLogin())

        this.setState({
            supportTicketModal: <ZendeskContactWebWidget
                onClose={dismissModal}
                user={user}
                order={order}
            />
        });
    }

    editOrderItemExternalId = (order, orderItem) => {

        /*if (!orderItemId) {
            console.error("ActiveOrdersTableOrder editOrderItemExternalId missing or invalid orderItemId", orderItemId);
            return;
        }*/

        /*if (!externalId) {
            console.error("ActiveOrdersTableOrder editOrderItemExternalId missing or invalid externalId", externalId);
            return;
        }*/

        const title = "Activate SIM";

        const dismissModal = () => {
            this.setState({updateExternalIdModal: null});
        };

        const updateOrderItemExternalId = (externalId) => {
            if (externalId === "") { // assumption: this is a request to clear/delete an existing externalId
                this.props.setOrderItem(
                    order.orderId,
                    orderItem.orderItemId,
                    Object.assign(orderItem, {externalId: null})
                );
            } else {
                this.props.updateOrderItem({
                    orderId: order.orderId,
                    orderItemId: orderItem.orderItemId,
                    externalId: String(externalId)
                });    
            }
            dismissModal();
        }

        const ravenUnit = orderItem.ravenUnit? orderItem.ravenUnit : {};
        if (!ravenUnit.serial) {
            console.error("ActiveOrdersTable editOrderItemExternalId orderItem ravenUnit required");
            return;
        }

        if (!ravenUnit.iccid) {
            console.error("ActiveOrdersTable editOrderItemExternalId orderItem iccid required");
            return;
        }
        
        if (!ravenUnit.imei) {
            console.error("ActiveOrdersTable editOrderItemExternalId orderItem imei required");
            return;
        }

        this.setState({
            updateExternalIdModal: (
                <ModalContainer title={title} onClickClose={dismissModal}>
                    <ActiveOrdersTableExternalIdEditor
                        order={order}
                        ratePlans={this.props.ratePlans}
                        orderItem={orderItem}
                        updateOrderItemExternalId={updateOrderItemExternalId}
                        onCancel={dismissModal}
                    />
                </ModalContainer>
            )
        });
    }

    editOrderItemRatePlanId = (order, orderItem) => {
        
        const orderItemRatePlanId = orderItem.ratePlanId; // 1.1, 3.8, etc.
        const ratePlanType = this.props.ratePlans.find((ratePlan) => ratePlan.id === orderItemRatePlanId).size; // 5GB or 2GB
        const ratePlansByType = this.props.ratePlans.filter((ratePlan) => ratePlan.size === ratePlanType); // all rate plans of the same type

        const title = "Change Rate Plan";

        const dismissModal = () => {
            this.setState({updateRatePlanIdModal: null});
        };

        const updateOrderItemRatePlanId = (ratePlanId) => {
            this.props.updateOrderItem({
                orderId: order.orderId,
                orderItemId: orderItem.orderItemId,
                ratePlanId: String(ratePlanId)
            });
            dismissModal();
        }

        this.setState({
            updateRatePlanIdModal: (
                <ModalContainer title={title} smallDialogMode={true} onClickClose={dismissModal}>
                    <ActiveOrdersTableRatePlanIdEditor
                        order={order}
                        ratePlans={ratePlansByType}
                        orderItem={orderItem}
                        updateOrderItemRatePlanId={updateOrderItemRatePlanId}
                        onCancel={dismissModal}
                    />
                </ModalContainer>
            )
        });

    }

    promptToNotifyVendor = (orderNumber, localOrderIndex) => {

        const title = "Notify Vendor?";
        const message = "Each order item has a valid raven. You can now notify the vender to add the corresponding provider ids.";
        const nextButtonLabel = "Notify Vendor";

        const dismissModal = () => {
            this.setState({confirmDialog: null});    
        };

        const onSubmit = () => {
            dismissModal();
            this.props.updateOrderStatusNext(orderNumber);
            //this.props.updateOrderStatusBack(orderNumber);
        }

        this.setState({
            confirmDialog: <ConfirmationDialog  //TODO upgrade to custom content in ModalContainer to added a revert status button
                title={title}
                message={message}
                cancelButtonAction={dismissModal}
                cancelButtonTitle="Cancel"
                submitButtonAction={onSubmit}
                submitButtonTitle={nextButtonLabel}
            />
        })                
    }

    promptRequestToShip = (order, localOrderIndex) => {

        const orderNumber = order.orderNumber;

        const title = "Request to Ship Order?";

        const dismissModal = () => {
            this.setState({confirmDialog: null});    
        };

        const onSubmit = () => {
            dismissModal();
            this.props.updateOrderStatusNext(orderNumber);
            //this.props.updateOrderStatusBack(orderNumber);
        }

        const onEditShippingAddressClick = () => {
            dismissModal();
            this.props.onEditShippingAddressClick(order);
        }

        this.setState({
            confirmDialog: (
                <ModalContainer title={title} smallDialogMode={false} onClickClose={dismissModal}>
                    <ActiveOrdersTableShipOrderForm
                        stage={this.props.stage}
                        order={order}
                        onCancel={dismissModal}
                        onSubmit={onSubmit}
                        onEditShippingAddressClick={onEditShippingAddressClick}
                    />
                </ModalContainer>
            )
        })                
    }

    promptToShip = (order, localOrderIndex) => {

        const orderNumber = order.orderNumber;

        const title = "Ready to Ship?";

        const dismissModal = () => {
            this.setState({confirmDialog: null});    
        };

        const onSubmit = () => {
            dismissModal();
            this.props.updateOrderStatusNext(orderNumber);
            //this.props.updateOrderStatusBack(orderNumber);
        }

        const onEditShippingAddressClick = () => {
            dismissModal();
            this.props.onEditShippingAddressClick(order);
        }

        this.setState({
            confirmDialog: (
                <ModalContainer title={title} smallDialogMode={false} onClickClose={dismissModal}>
                    <ActiveOrdersTableShipOrderForm
                        stage={this.props.stage}
                        order={order}
                        onCancel={dismissModal}
                        onSubmit={onSubmit}
                        onEditShippingAddressClick={onEditShippingAddressClick}
                    />
                </ModalContainer>
            )
        });

    }

    updateOrderStatus = (order) => {

        const orderNumber = order.orderNumber;

        if (!orderNumber) {
            console.error("OrdersPage promptToUpdateOrderStatus invalid or missing orderNumber", orderNumber);
            return;
        }

        let localOrderIndex = this.props.orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            console.error("OrdersPage promptToUpdateOrderStatus order not found in state.");
            return;
        }

        switch (this.props.orders[localOrderIndex].status) {
            case ORDER_STATUS.NEW: 
            case ORDER_STATUS.PENDING_RAVENS:
                this.promptToNotifyVendor(orderNumber, localOrderIndex);
                break;
            case ORDER_STATUS.PENDING_SIM_DETAILS:
                this.promptRequestToShip(order, localOrderIndex);
                break;
            case ORDER_STATUS.PENDING_SHIPMENT:
                this.promptToShip(order, localOrderIndex);
                break;
            case ORDER_STATUS.COMPLETED:
                console.warn("ActiveOrdersTableOrderNextStepButton no prompt for status COMPLETED. This order is marked as shipped.");
                break;
            default:
                console.error("ActiveOrdersTableOrderNextStepButton unrecognized order status");
                break;
        }
    }

    setOrderItem = (orderId, orderItemId, orderItem) => {
        if(orderItem.ravenUnit === null){
            this.setState({currentOrderId: orderId});
        }

        if(orderItem.beaconUnit === null){
            this.setState({currentOrderId: orderId});
        }

        this.props.setOrderItem(orderId, orderItemId, orderItem);
    }

    render() {
        if (!this.props.orders) {
            return (
                <div className="loading-inline-container">
                    <div className="loading-activity-indicator"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            )
        }
        
        const filteredOrders = this.props.orders.filter( (order, index) => {
            switch (this.props.mainFilter) {
                case MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED:
                    if (order.status === ORDER_STATUS.COMPLETED ||
                        order.status === ORDER_STATUS.PENDING_RAVENS) { // RAV-1778 is for vendor users' perspective 
                        return null;
                    }
                    break;
                case MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_RAVENS:
                    if (order.status !== ORDER_STATUS.PENDING_RAVENS) {
                        return null;
                    }
                    break;
                case MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_PROVIDER_IDS:
                    if (order.status !== ORDER_STATUS.PENDING_SIM_DETAILS) {
                        return null;
                    }
                    break;
                case MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_SHIPMENT:
                    if (order.status !== ORDER_STATUS.PENDING_SHIPMENT) {
                        return null;
                    }
                    break;
                case MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED:
                    if (order.status !== ORDER_STATUS.COMPLETED) {
                        return null;
                    }
                    break;
                default:
                    break;
            }
            return true;
        });

        return (
            <>
            <section className="orders-table active">
                {filteredOrders.length > 0 ?
                
                    filteredOrders.map( (order, index) => {                        
                        return (
                            <ActiveOrdersTableOrder
                                key={index}
                                order={order}
                                ratePlans={this.props.ratePlans}
                                itemTypes={this.props.itemTypes}
                                totalItemsForCategory={this.props.totalItemsForCategory}
                                activeOrdersTableOrderIndex={index}
                                onEditOrderClick={this.props.onEditOrderClick}
                                onAddOrderItemClick={this.props.onAddOrderItemClick}
                                onRemoveOrderItemClick={this.props.onRemoveOrderItemClick}
                                onUpdateOrderAccessoriesClick={this.props.onUpdateOrderAccessoriesClick}
                                updateOrderItem={this.props.updateOrderItem}
                                setOrderItem={this.setOrderItem}
                                updateOrderStatus={this.updateOrderStatus}
                                editOrderItemExternalId={this.editOrderItemExternalId}
                                editOrderItemRatePlanId={this.editOrderItemRatePlanId}
                                onOrderInfoClick={this.onOrderInfoClick}
                                onAccountInfoClick={this.onAccountInfoClick}
                                onSupportTicketClick={this.onSupportTicketClick}
                            />
                        )
                    })
                :
                    !this.props.checkForMoreOrdersInProgress ?
                        <ActiveOrdersTableEmptyMessage mainFilter={this.props.mainFilter} />
                    :
                        null // don't show empty message (Done!) until pending requests are complete
                }
                    
            </section>
            {this.props.checkForMoreOrdersInProgress &&
                <div className="loading-inline-container">
                    <div className="loading-activity-indicator"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>        
            }
            {this.state.orderInfoModal}
            {this.state.accountInfoModal}
            {this.state.supportTicketModal}
            {this.state.updateExternalIdModal}
            {this.state.updateRatePlanIdModal}
            {this.state.confirmDialog}
            </>
        )
    }
}


class ActiveOrdersTableHeader extends React.PureComponent {

    constructor(props) {
        super(props);
        this.ordersPageOverflowScrollingDivId = undefined;
        this.activeOrdersTableHeaderReactReference = React.createRef();
        this.activeOrdersTableHeaderOffsetTop = undefined;
        this.activeOrdersTableHeaderIsSticky = false;
    }

    static propTypes = {
        sticky: PropTypes.bool
    }

    onScollEventHandler = () => {
        if (this.ordersPageOverflowScrollingDivId.scrollTop > this.activeOrdersTableHeaderOffsetTop) {
            if (!this.activeOrdersTableHeaderIsSticky) {
                this.activeOrdersTableHeaderIsSticky = true;
                const activeOrdersTableHeaderDisplayStyle = this.activeOrdersTableHeaderReactReference.current.style.display;
                this.activeOrdersTableHeaderReactReference.current.style.display = "none";
                this.ordersPageOverflowScrollingDivId.style.paddingTop = "26px";
                setTimeout(() => {
                    this.activeOrdersTableHeaderReactReference.current.classList.add("sticky");
                    this.activeOrdersTableHeaderReactReference.current.style.display = activeOrdersTableHeaderDisplayStyle;
                }, 1);
            }
        } else {
            if (this.activeOrdersTableHeaderIsSticky) {
                this.activeOrdersTableHeaderIsSticky = false;
                this.activeOrdersTableHeaderReactReference.current.classList.remove("sticky");
                this.ordersPageOverflowScrollingDivId.style.paddingTop = "0px";
            }
        }
    }

    componentDidMount() {
        if (this.props.sticky) {
            this.ordersPageOverflowScrollingDivId = document.getElementById("orders-page-overflow-scrolling-div-id");
            if (this.ordersPageOverflowScrollingDivId) {
                this.ordersPageOverflowScrollingDivId.addEventListener("scroll", this.onScollEventHandler, false);
                this.activeOrdersTableHeaderOffsetTop = this.activeOrdersTableHeaderReactReference.current.offsetTop;
            } else {
                console.warn("ActiveOrdersTableHeader ordersPage render div.orders-page missing id 'orders-page-overflow-scrolling-div-id' (not found)");
            }   
        }
    }

    componentWillUnmount() {
        if (this.props.sticky) {
            if (this.ordersPageOverflowScrollingDivId) {
                this.ordersPageOverflowScrollingDivId.removeEventListener('scroll', this.onScollEventHandler, false);
            }
        }
    }

    render() {
        return (
            <header ref={this.activeOrdersTableHeaderReactReference} className="row">
                <div className="order-number">Order</div>
                {/*<div className="order-date">Date</div>*/}
                <div className="account-name">Account</div>
                <div className="serial-number">Serial Number</div>
                <div className="iccid">ICCID</div>
                <div className="imei">IMEI</div>
                <div className="provider-id">{globalconfig.features.ravens.externalIdOptionalTitle || "Provider Id"}</div>
                <div className="rate-plan-name">Term</div>
                <div className="delete-item"></div>
                <div className="order-status">Status</div>
            </header>
        )
    }
}


class ActiveOrdersTableEmptyMessage extends React.PureComponent {
    static propTypes = {
        mainFilter: PropTypes.string
    }
    render() {
        let doneMessage = "";
        switch (this.props.mainFilter) {
            case MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED:
                doneMessage = "All orders have been shipped!";
                break;
            case MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_RAVENS:
                doneMessage = "All active orders have assigned ravens!";
                break;
            case MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_PROVIDER_IDS:
                doneMessage = "All active orders have provider ids!";
                break;
            }
        return (
            <div className="done-message">
                <h2>
                    Done!
                </h2>
                <h3>
                    {doneMessage}
                </h3>
            </div>
        )
    }
}

class ActiveOrdersTableExternalIdEditor extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            externalId: undefined,
            externalIdValidationError: undefined
        }
        
    }

    static propTypes = {
        order: PropTypes.object.isRequired,
        orderItem: PropTypes.object.isRequired,
        ratePlans: PropTypes.array.isRequired,
        updateOrderItemExternalId: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    }

    componentDidMount() {
        setTimeout(() => { // auto-
            const externalIdInput = document.getElementById("order-unit-details-external-id-input");
            if (externalIdInput) {
                externalIdInput.focus();
            }
        }, 500);
    }

    onInputChange = (event) => {

        let externalId = event.target.value;

        // two cases where user reverts changes
        if (event.target.value === "" && !this.props.orderItem.externalId ) { // user has cleared and there is no value currently set for this item
            externalId = undefined;

        } else if (event.target.value === this.props.orderItem.externalId) { // user has undone any any changes
            externalId = undefined;
        }

        this.setState({
            externalId: externalId
        });
    }

    validatePhoneNumber = (externalId) => { // TODO valdiation method determined  build settings/environment or something
    
        let simplifiedExternalId = externalId.trim();
    
        if (simplifiedExternalId === "") {
            if (this.props.orderItem.externalId) {
                // assumption: this is a request to clear/delete an existing externalId
                return simplifiedExternalId; // returning "" as valid
            }
            this.setState({
                externalIdValidationError: "Required"
            })
            return null;
        }

        simplifiedExternalId = simplifiedExternalId.replace(/-/g, ""); // remove hyphens

        if (isNaN(simplifiedExternalId)) {
            console.warn("ActiveOrdersTableExternalIdEditor validatePhoneNumber invalid - contains unknown characters");
            this.setState({
                externalIdValidationError: "Invalid mobile number format"
            })
            return null;
        }

        if (simplifiedExternalId.length !== 10) {
            console.warn("ActiveOrdersTableExternalIdEditor validatePhoneNumber invalid length");
            this.setState({
                externalIdValidationError: "Mobile number must be 10 digits"
            })
            return null;
        }

        this.setState({
            externalIdValidationError: null
        })
        return simplifiedExternalId;
    }

    onSubmit = (event) => {
        if (this.state.externalId === undefined) {
            return;
        }
        event.preventDefault();
        const validExternalId = this.validatePhoneNumber(this.state.externalId); // TODO valdiation method determined by build settings/environment or something
        if (validExternalId !== "" && !validExternalId) {
            return; // failed validation
        }
        this.props.updateOrderItemExternalId(validExternalId);
    }

    copyCorrespondingInput = (event) => {
        event.preventDefault(); // required because of form onSubmit
        let correspondingInput = undefined;
        switch (event.target.name) {
            case "account-external-id":
                correspondingInput = document.getElementById("order-unit-details-account-external-id-input");
                break;
            case "order-number":
                correspondingInput = document.getElementById("order-unit-details-order-number-input");
                break;
            case "serial":
                correspondingInput = document.getElementById("order-unit-details-serial-input");
                break;
            case "iccid":
                correspondingInput = document.getElementById("order-unit-details-iccid-input");
                break;
            case "imei":
                correspondingInput = document.getElementById("order-unit-details-imei-input");
                break;
            default:
                console.error("ActiveOrdersTable editOrderItemExternalId copyCorrespondingInput unrecognized corresponding button name");
                return;
        }
        if (!correspondingInput) {
            console.error("ActiveOrdersTable editOrderItemExternalId copyCorrespondingInput not found for " + event.target.name);
            return;
        }
        correspondingInput.disabled = false;
        correspondingInput.select();
        correspondingInput.setSelectionRange(0, 99999); // for mobile devices
        document.execCommand("copy");
        correspondingInput.disabled = true;
    }

    render() {

        let externalId = this.props.orderItem.externalId ? this.props.orderItem.externalId : ""; // inputs don't like undefineds
        let showSubmitButton = false;
        let submitButtonTitle = "Submit";
        let closeButtonTitle = "Close";
        
        if (this.state.externalId !== undefined) { // form has a change
            externalId = this.state.externalId;
            closeButtonTitle = "Cancel";
            showSubmitButton = true;
            if (this.state.externalId === "") { // onInputChange() has already checked/sanitized this against corresponding props undefined
                submitButtonTitle = "Submit (Delete Value)";
            }
        }

        let term = ratePlanNameForId(this.props.ratePlans, this.props.orderItem.ratePlanId);

        let simManagementExternalUrl = undefined;
        let simManagementExternalUrlTitle = "SIM Tool";
        if (globalconfig.features && globalconfig.features.ravens) {
            simManagementExternalUrl = globalconfig.features.ravens.simManagementExternalUrl;
            if (globalconfig.features.ravens.simManagementExternalUrlOptionalTitle) {
                simManagementExternalUrlTitle = globalconfig.features.ravens.simManagementExternalUrlOptionalTitle;
            }
        }
        let accountExternalIdTitle = "Account Number";
        if (globalconfig.features &&
            globalconfig.features.accounts &&
            globalconfig.features.accounts.externalIdOptionalTitle) {

            accountExternalIdTitle = globalconfig.features.accounts.externalIdOptionalTitle;
        }

        return (
            <form onSubmit={this.onSubmit} autoComplete="off">
            <ul className="order-unit-details">
                <li>
                    <label className="label large-dialog-mode">{accountExternalIdTitle}:</label>
                    <input className="value" id="order-unit-details-account-external-id-input" value={this.props.order.account.externalId} disabled={true} />
                    <button className="copy" name="account-external-id" onClick={this.copyCorrespondingInput} >Copy {accountExternalIdTitle}</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">Order No:</label>
                    <input className="value" id="order-unit-details-order-number-input" value={this.props.order.orderNumber} disabled={true} />
                    <button className="copy" name="order-number" onClick={this.copyCorrespondingInput} >Copy Order Number</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">Raven Serial:</label>
                    <input className="value" id="order-unit-details-serial-input" value={this.props.orderItem.ravenUnit.serial} disabled={true} />
                    <button className="copy" name="serial" onClick={this.copyCorrespondingInput} >Copy Serial</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">ICCID:</label>
                    <input className="value" id="order-unit-details-iccid-input" value={this.props.orderItem.ravenUnit.iccid} disabled={true} />
                    <button className="copy" name="iccid" onClick={this.copyCorrespondingInput} >Copy ICCID</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">IMEI:</label>
                    <input className="value" id="order-unit-details-imei-input" value={this.props.orderItem.ravenUnit.imei} disabled={true} />
                    <button className="copy" name="imei" onClick={this.copyCorrespondingInput} >Copy IMEI</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">Term:</label>
                    <input className="value" id="order-unit-details-term-input" value={term} disabled={true} />
                </li>
            </ul>
            { simManagementExternalUrl ?
                <div className="actions">
                    <a href={simManagementExternalUrl} target="_blank">{simManagementExternalUrlTitle} <i className="material-icons">launch</i></a>
                </div>
            :
                null
            }
            <div className="inputs">
                <input className="external-id" id="order-unit-details-external-id-input" value={externalId} placeholder={"Enter Mobile Number Here"} onChange={this.onInputChange} />
            </div>
            {this.state.externalIdValidationError ? 
                <div className="error-message">{this.state.externalIdValidationError}</div>
            :
                null
            }
            <div className="actions">
                <button className="destructive" onClick={this.props.onCancel} >{closeButtonTitle}</button>
                {showSubmitButton? <button type="submit">{submitButtonTitle}</button> : null }
            </div>
            </form>
        )
    }
}

class ActiveOrdersTableRatePlanIdEditor extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            ratePlanId: undefined
        }
    }

    onInputChange = (event) => {
        this.setState({ratePlanId: event.target.id});
    }

    static propTypes = {
        order: PropTypes.object.isRequired,
        orderItem: PropTypes.object.isRequired,
        ratePlans: PropTypes.array.isRequired,
        updateOrderItemRatePlanId: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    }

    render() {
        
        let cancelButtonTitle = "Cancel";
        let submitButtonTitle = "Apply";

        let ratePlanName = ratePlanNameForId(this.props.ratePlans, this.props.orderItem.ratePlanId);

        let message = "This unit is currently on the " + ratePlanName + " term.  Select a different term below and click apply.";

        if (!this.props.ratePlans) {
            console.error("ActiveOrdersTableRatePlanIdEditor invalid rate plans");
            this.props.onCancel();
            return null;
        }

        const ratePlanId = this.state.ratePlanId ? this.state.ratePlanId : this.props.orderItem.ratePlanId;

        return (
            <>
            <div className="message">
                {message}
            </div>
            <ul className="order-unit-details">
                {this.props.ratePlans.map((ratePlan, index) => {
                    return (<li key={index} >
                        <input
                            type="radio"
                            id={ratePlan.id}
                            name="rate-plan"
                            checked={ratePlan.id === ratePlanId}
                            value={ratePlan.id}
                            onChange={this.onInputChange}
                        />
                        <label className="radio-label" htmlFor={"rate-plan-id:" + ratePlan.id} disabled={false}>
                            {ratePlan.name + " , " + ratePlan.size}
                        </label>
                    </li>)
                })}
            </ul>
            <div className="actions">
                <button className="destructive" onClick={this.props.onCancel} >
                    {cancelButtonTitle}
                </button>
                {this.state.ratePlanId !== undefined && this.state.ratePlanId !== this.props.orderItem.ratePlanId ? 
                    <button onClick={() => {this.props.updateOrderItemRatePlanId(this.state.ratePlanId);}} >
                        {submitButtonTitle}
                    </button>
                :
                    null
                }
            </div>
            </>
        )
    }
}

class ActiveOrdersTableShipOrderForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            account: undefined,

            mutableOrderTypeFromProps: this.props.order ? this.props.order.orderType : undefined, // used for a geotab integration edge case

            name: undefined,
            nameErrorOccurred: undefined,

            shippingSectionPending: undefined,
            shippingSectionErrors: [],

            shippingAddressStreetLine1: undefined,
            shippingAddressStreetLine1ErrorOccurred: undefined,

            shippingAddressStreetLine2: undefined,
            shippingAddressStreetLine2ErrorOccurred: undefined,

            shippingAddressCity: undefined,
            shippingAddressCityErrorOccurred: undefined,

            shippingAddressZipCode: undefined,
            shippingAddressZipCodeErrorOccurred: undefined,

            shippingAddressRegion: undefined,
            shippingAddressRegionErrorOccurred: undefined,

            shippingAddressCountry: undefined,
            shippingAddressCountryErrorOccurred: undefined,

            trackingNumber: undefined,
            trackingNumberErrorMessage: undefined,
            trackingNumberPending: false,

            geotabIntegrationFound: undefined,
            geotabSectionPending: undefined,
            geotabDatabaseName: undefined,
            geotabDatabaseNameErrorOccurred: undefined,
            geotabDigDevices: undefined,
            geotabDigDevicesErrorOccurred: undefined
        }
        this.ravenDataStore = new RavenDataStore(() => {});
    }

    static propTypes = {
        stage: PropTypes.string.isRequired,
        order: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onEditShippingAddressClick: PropTypes.func.isRequired
    }

    componentDidMount() {

        const accountExternalId = this.props.order && this.props.order.account ? this.props.order.account.externalId : undefined;
        if (!accountExternalId) {
            console.error("ActiveOrdersTableShipOrderForm invalid order acount info", this.props.order);
            return;
        }
        this.getAccount(accountExternalId);
    }
    applyAccountToState = (account) => {

        const shippingSectionErrors = [];

        const name = account.primaryAddress && account.primaryAddress.name;
        let nameErrorOccurred = false;
        if (!name) {
            shippingSectionErrors.push("Account primary address name not found");
            nameErrorOccurred = true;
        }

        const shippingAddressStreetLine1 = account.primaryAddress && account.primaryAddress.address1;
        let shippingAddressStreetLine1ErrorOccurred = false;
        if (!shippingAddressStreetLine1) {
            shippingSectionErrors.push("Account primary address street address line 1 is missing");
            shippingAddressStreetLine1ErrorOccurred = true;
        }

        const shippingAddressStreetLine2 = account.primaryAddress && account.primaryAddress.address2;

        const shippingAddressCity = account.primaryAddress && account.primaryAddress.city;
        let shippingAddressCityErrorOccurred = false;
        if (!shippingAddressCity) {
            shippingSectionErrors.push("Account primary address city is missing");
            shippingAddressCityErrorOccurred = true;
        }

        const shippingAddressZipCode = account.primaryAddress && account.primaryAddress.postcode;
        let shippingAddressZipCodeErrorOccurred = false;
        if (!shippingAddressZipCode) {
            shippingSectionErrors.push("Account primary address zip/postal code is missing");
            shippingAddressZipCodeErrorOccurred = true;
        }

        const shippingAddressRegion = account.primaryAddress && account.primaryAddress.state;
        let shippingAddressRegionErrorOccurred = false;
        if (!shippingAddressRegion) {
            shippingSectionErrors.push("Account primary address region (state or provinc) is missing");
            shippingAddressRegionErrorOccurred = true;
        }

        const shippingAddressCountry = account.primaryAddress && account.primaryAddress.country;
        let shippingAddressCountryErrorOccurred = false;
        if (!shippingAddressCountry) {
            shippingSectionErrors.push("Account primary address country is missing");
            shippingAddressCountryErrorOccurred = true;
        }

        this.setState({
            account: account,

            shippingSectionPending: false,
            shippingSectionErrors: shippingSectionErrors,

            name: name,
            nameErrorOccurred: nameErrorOccurred,

            shippingAddressStreetLine1: shippingAddressStreetLine1,
            shippingAddressStreetLine1ErrorOccurred: shippingAddressStreetLine1ErrorOccurred,

            shippingAddressStreetLine2: shippingAddressStreetLine2,

            shippingAddressCity: shippingAddressCity,
            shippingAddressCityErrorOccurred: shippingAddressCityErrorOccurred,

            shippingAddressZipCode: shippingAddressZipCode,
            shippingAddressZipCodeErrorOccurred: shippingAddressZipCodeErrorOccurred,

            shippingAddressRegion: shippingAddressRegion,
            shippingAddressRegionErrorOccurred: shippingAddressRegionErrorOccurred,

            shippingAddressCountry: shippingAddressCountry,
            shippingAddressCountryErrorOccurred: shippingAddressCountryErrorOccurred
        });
    }

    getAccount = (accountExternalId) => {

        if (!accountExternalId) {
            console.error("ActiveOrdersTableShipOrderForm getAccount accountExternalId missing");
            return;
        }

        this.setState({
            shippingSectionPending: true,
        });

        return this.ravenDataStore.getAccount(this.props.stage, accountExternalId)
        .then(
            (account) => {
                //console.log(JSON.stringify(account));

                this.applyAccountToState(account);

                const accountIntegrations = account.integrations;
                let geotabIntegrationFound = false;

                if (accountIntegrations) {
                    for (let i = 0; i < accountIntegrations.length; i += 1) {
                        if (accountIntegrations[i].type === "GEOTAB") {
                            geotabIntegrationFound = true;
                            break;
                        }
                    }
                }

                if (geotabIntegrationFound) {
                    this.setState({
                        geotabIntegrationFound: geotabIntegrationFound
                    });
                    const orderNumber = this.props.order && this.props.order.orderNumber ? this.props.order.orderNumber : undefined;
                    if (!orderNumber) {
                        console.error("ActiveOrdersTableShipOrderForm invalid order info (missing orderNumber)", this.props.order);
                        return;
                    }
                    this.getGeotabIntegrationAndDevices(accountExternalId, orderNumber);    
                }



                return Promise.resolve(account);
            },
            (error) => {

                //console.log("OrderForm getAccount error occurred");
                console.error(error);
                const errorMessage = error && error.message ? error.message : "An error occurred";

                return Promise.reject(error);
            }
        );
    }

    /*
      A convenience function to check for changes between the cloud account details and integrations devices
      and with the local integration database name in state and the devices currently listed on the order

      returns an object with a cloudUpdateRequired property boolean and properties for a subsequent call if
      required for the current context (i.e. render does not care about the call properties, but
      onClickDownloadGeotabDeviceSerialNumbers and onSubmit do)
    */
    geotabIntegrationLocalEditsFound = () => {
        if (!this.state.account) {
            console.error("ActiveOrdersTableShipOrderForm checkAndSaveGeotabIntegration state account details missing");
            return;
        }

        const accountExternalId = this.state.account && this.state.account.accountId ? this.state.account.accountId : undefined;

        if (!accountExternalId) {
            console.error("ActiveOrdersTableShipOrderForm checkAndSaveGeotabIntegration state account details accountId missing");
            return;
        }

        // check current saved account integration for Geotab
        const accountIntegrations = this.state.account.integrations ? this.state.account.integrations : [];
        let setOrUpdateGeotabIntegrationIsRequired = false;
        let savedGeotabIntegration = undefined;
        accountIntegrations.forEach( (integration) => {
            if (integration.type === "GEOTAB") {
                if (integration.details) {
                    savedGeotabIntegration = integration;
                }
            }
        });

        if (savedGeotabIntegration) {
            if (savedGeotabIntegration.details.databaseName !== this.state.geotabDatabaseName) {
                setOrUpdateGeotabIntegrationIsRequired = true;
            }
        } else {
            setOrUpdateGeotabIntegrationIsRequired = true;
        }

        // get current saved integration devices for 
        let savedGeotabDeviceRavenSerialNumbers = [];
        
        if (this.state.geotabDigDevices) {
            for (let i = 0; i < this.state.geotabDigDevices.length; i += 1) {
                savedGeotabDeviceRavenSerialNumbers.push(this.state.geotabDigDevices[i].ravenSerial);
            }
        }

        // gather Raven serial numbers
        const orderItemsSerialNumbers = [];
        const orderItems = this.props.order && this.props.order.items ? this.props.order.items : undefined;

        if (!orderItems) {
            console.error("ActiveOrdersTableShipOrderForm checkAndSaveGeotabIntegration account order has no items", this.props.order);
            return;
        }

        orderItems.forEach( (item) => {

            if (!item.ravenUnit) return;

            if (savedGeotabDeviceRavenSerialNumbers.indexOf(item.ravenUnit.serial) === -1) {
                setOrUpdateGeotabIntegrationIsRequired = true;
            }

            orderItemsSerialNumbers.push(item.ravenUnit.serial);

        });

        // check if the order type is consistent with a geotab integration
        if ((this.state.mutableOrderTypeFromProps !== ORDER_TYPES.RAVEN_GT) && (this.state.mutableOrderTypeFromProps !== ORDER_TYPES.RAVEN_GTS)) {
            setOrUpdateGeotabIntegrationIsRequired = true;
        }

        if (orderItemsSerialNumbers.length === 0) { // WARNING - Configuring a geotab integration for an order does not support orders without any ravens
            console.warn("ActiveOrdersTableShipOrderForm checkAndSaveGeotabIntegration no ravenUnits found for order", this.props.order);
            setOrUpdateGeotabIntegrationIsRequired = false;
        }

        if (savedGeotabIntegration) {
            savedGeotabIntegration.devices = this.state.geotabDigDevices;
        }
        
        return {
            cloudUpdateRequired: setOrUpdateGeotabIntegrationIsRequired,
            savedGeotabIntegration: savedGeotabIntegration, // use this to mimic a response, useful if context is a promises.all
            accountExternalId: accountExternalId,
            geotabDatabaseName: this.state.geotabDatabaseName,
            orderItemsSerialNumbers: orderItemsSerialNumbers
        }
    }

    checkAndSaveGeotabIntegration = () => {

        const localEditsCheckResult = this.geotabIntegrationLocalEditsFound();

        if (!localEditsCheckResult) {
            console.error("ActiveOrdersTableShipOrderForm checkAndSaveGeotabIntegration geotabIntegrationLocalEditsFound error");
            return Promise.reject(new Error ("ActiveOrdersTableShipOrderForm checkAndSaveGeotabIntegration geotabIntegrationLocalEditsFound error"));
        } else if (localEditsCheckResult.cloudUpdateRequired) {

            return this.ravenDataStore.addAccountGeotabIntegration(this.props.stage, localEditsCheckResult.accountExternalId, localEditsCheckResult.geotabDatabaseName, this.props.order.orderNumber)
            .then(
                (addAccountGeotabIntegrationResponse) => {
                    return this.getAccount(localEditsCheckResult.accountExternalId) // sync local account state
                    .then(
                        (getAcountResponse) => { return Promise.resolve(addAccountGeotabIntegrationResponse)},
                        (getAccountError) => { console.error(getAccountError); }
                    );
                },
                (error) => {
                    console.error(error);
                    return Promise.reject(error);
                }
            );
        }
        
        this.setState({geotabSectionPending: false});
        return Promise.resolve(localEditsCheckResult.savedGeotabIntegration);

    };

    getGeotabIntegrationAndDevices = (accountExternalId, orderNumber) => {

        if (!accountExternalId) {
            console.error("ActiveOrdersTableShipOrderForm getGeotabIntegrationAndDevices accountExternalId missing");
            return;
        }

        this.setState({
            geotabSectionPending: true
        });

        Promise.all(
            [
                new Promise((resolve, reject) => {
                    return this.ravenDataStore.getAccountGeotabIntegration(this.props.stage, accountExternalId)
                    .then(
                        (response) => {
                            const details = response.details;

                            if (details) {
                                const databaseName = details.databaseName;
                                if (databaseName) {
                                    this.setState({
                                        geotabDatabaseName: databaseName
                                    });
                                }
                            }

                            return resolve(response.results);
                        },
                        (error) => {
                            console.error(error);
                            return reject(error);
                        }
                    )
                }),
                new Promise((resolve, reject) => {
                    return this.ravenDataStore.getOrderGeotabDigDevices(this.props.stage, orderNumber)
                    .then(
                        (response) => {
                            console.log(response);
                            const devicesWithGeotabSerialNumbers = response.results ? response.results : undefined;
                            this.setState({
                                geotabDigDevices: devicesWithGeotabSerialNumbers
                            });
                            return resolve(response);
                        },
                        (error) => {
                            console.error(error);
                            return reject(error);
                        }
                    )
                })
            ]
        )
        .then(
            (responses) => {

                this.setState({
                    geotabSectionPending: false,
                });        
            },
            (firstError) => {
                this.setState({
                    geotabSectionPending: false
                });        
                console.error(firstError);
            }
        );
    }

    copyCorrespondingInput = (event) => {
        event.preventDefault(); // required because of form onSubmit
        let correspondingInput = undefined;
        switch (event.target.name) {
            case "name":
                correspondingInput = document.getElementById("order-shipping-step-details-name");
                break;
            case "shippingAddressStreetLine1":
                correspondingInput = document.getElementById("order-shipping-step-details-shipping-address-street-line-1");
                break;
            case "shippingAddressStreetLine2":
                correspondingInput = document.getElementById("order-shipping-step-details-shipping-address-street-line-2");
                break;
            case "shippingAddressCity":
                correspondingInput = document.getElementById("order-shipping-step-details-shipping-address-city");
                break;
            case "shippingAddressZipCode":
                correspondingInput = document.getElementById("order-shipping-step-details-shipping-address-zip-code");
                break;
            case "shippingAddressRegion":
                correspondingInput = document.getElementById("order-shipping-step-details-shipping-address-region");
                break;
            case "shippingAddressCountry":
                correspondingInput = document.getElementById("order-shipping-step-details-shipping-address-country");
                break;
            default:
                console.error("ActiveOrdersTableOrderShipmentConfirmationStep copyCorrespondingInput unrecognized corresponding button name");
                return;
        }
        if (!correspondingInput) {
            console.error("ActiveOrdersTableOrderShipmentConfirmationStep copyCorrespondingInput not found for " + event.target.name);
            return;
        }
        correspondingInput.disabled = false;
        correspondingInput.select();
        correspondingInput.setSelectionRange(0, 99999); // for mobile devices
        document.execCommand("copy");
        correspondingInput.disabled = true;
    }

    onInputChange = (event) => {
        switch (event.target.name) {
            case "trackingNumber":
                this.setState({ trackingNumber: event.target.value });
                return;
            case "geotabDatabaseName":
                this.setState({ geotabDatabaseName: event.target.value });
                return;
            default:
                console.error("ActiveOrdersTableOrderShipmentConfirmationStep onInputChange unrecognized event.target.name", event.target.name);
                return;
        }
    }

    updateGeotabOrderType = (event) => {

        const orderNumber = this.props.order && this.props.order.orderNumber ? this.props.order.orderNumber : undefined;
        const orderType = this.state.mutableOrderTypeFromProps;
        const updatedOrderType = event.target.value;

        if (orderType === updatedOrderType) {
            console.warn("OrderForm renderForEditMode update order type requested to apply the same type (no change)");
            return;
        }

        const updateDelta = {
            orderType: updatedOrderType
        };

        this.setState({
            geotabSectionPending: true
        });

        this.ravenDataStore.updateOrder(this.props.stage, orderNumber, updateDelta)
        .then(
            (updatedOrder) => {

                this.setState({
                    mutableOrderTypeFromProps: updatedOrderType,
                    geotabSectionPending: false
                });

                if (this.props.setReloadOnDismiss) {
                    this.props.setReloadOnDismiss(true); // RAV-1843 basic support for both add and edit order modes for consistent refresh for saved edits.
                } else {
                    console.warn("OrderForm updateGeotabOrderType setReloadOnDismiss function not provided.");
                }

            },
            (error) => {
                const errorMessage = error.message ? error.message : "Unknown error occurred";
                this.setState({
                    geotabSectionPending: false,
                    geotabOrderTypeErrorMessage: errorMessage
                });
            }
        );
    }

    getGeotabDevicesCSVAndInitiateDownload = () => {
        const orderNumber = this.props.order && this.props.order.orderNumber ? this.props.order.orderNumber : undefined;
        if (!orderNumber) {
            console.error("ActiveOrdersTableShipOrderForm getGeotabDevicesCSVAndInitiateDownload invalid order info (missing orderNumber)", this.props.order);
            return;
        }
        this.setState({geotabSectionPending: true});

        this.ravenDataStore.getOrderGeotabDigDevices(this.props.stage, orderNumber, true)
        .then(
            (response) => {
                this.setState({geotabSectionPending: false});
                const dataUri = "data:text/csv;charset=utf-8," + response;
                window.open(dataUri);   
            },
            (error) => {
                this.setState({geotabSectionPending: false});
                console.error("ActiveOrdersTableShipOrderForm getGeotabDevicesCSVAndInitiateDownload getOrderGeotabDigDevices error", error);
            }
        );
    };

    onClickDownloadGeotabDeviceSerialNumbers = (event) => {
        if (!this.state.geotabDatabaseName) {
            console.error("ActiveOrdersTableOrderShipmentConfirmationStep onClickDownloadGeotabDeviceSerialNumbers geotabDatabaseName missing");
            return;
        }
        this.setState({geotabSectionPending: true});
        this.checkAndSaveGeotabIntegration()
        .then(
            (response) => {
                this.setState({geotabSectionPending: false});
                const responseIntegrationDetailsDatabaseName = response && response.details ? response.details.databaseName : undefined;
                if (!responseIntegrationDetailsDatabaseName) {
                    console.error("ActiveOrdersTableShipOrderForm onClickDownloadGeotabDeviceSerialNumbers checkAndSaveGeotabIntegration response geotab integration failed", response);
                } else {
                    this.getGeotabDevicesCSVAndInitiateDownload();
                }
            },
            (error) => {
                this.setState({geotabSectionPending: false});
                console.log(error);
            }
        )
    }

    onSubmit = () => { // only used for tracking number (ORDER_STATUS.PENDING_SHIPMENT)
        if (!this.state.trackingNumber || this.state.trackingNumber === "") {
            this.setState({
                trackingNumberErrorMessage: "Tracking Number is required"
            });
            return;
        }
        const updateDelta = {
            trackingNumber: this.state.trackingNumber
        }
        this.setState({trackingNumberPending: true});
        this.ravenDataStore.updateOrder(this.props.stage, this.props.order.orderNumber, updateDelta)
        .then(
            (updatedOrder) => {
                this.setState({trackingNumberPending: false});
                this.props.onSubmit();
            },
            (error) => {
                this.setState({
                    trackingNumberPending: false,
                    trackingNumberErrorMessage: error && error.message ? error.message : "An error occurred"
                });
            }
        );
    }

    render() {

        let message = "Each order item has a valid raven and provider id. You can now notify Raven Connected to ship the order.";
        let nextButtonLabel = "";

        let trackingNumberRequired = false;

        let showSubmitButton = false;

        const orderComment = this.props.order && this.props.order.comment ? this.props.order.comment : undefined;

        switch (this.props.order.status) {
            case ORDER_STATUS.PENDING_SIM_DETAILS:
                message = "Each order item has a valid raven and provider id. You can now notify Raven Connected to ship the order.";
                nextButtonLabel = "Request to Ship";
                showSubmitButton = true;
                break;
            case ORDER_STATUS.PENDING_SHIPMENT:
                message = "Add the tracking number for this shipment and then click 'Ship' to complete this order.";
                nextButtonLabel = "Ship";
                trackingNumberRequired = true;
                showSubmitButton = true;
            default:
                break;
        }

        let trackingNumber = this.state.trackingNumber;
        if (!trackingNumber && trackingNumber !== 0) {
            trackingNumber = "";
        }
        const trackingNumberLabel = "Tracking Number";

        let geotabDatabaseName = this.state.geotabDatabaseName;
        let geotabIntegrationSaveIsRequired = false;
        let geotabDatabaseNameSeemsValid = true;
        let geotabIntegrationTotalRavensInThisOrder = 0;

        if (this.state.geotabIntegrationFound) {

            const localEditsResults = this.geotabIntegrationLocalEditsFound();
            geotabIntegrationSaveIsRequired = localEditsResults.cloudUpdateRequired;
            geotabIntegrationTotalRavensInThisOrder = localEditsResults.orderItemsSerialNumbers ? localEditsResults.orderItemsSerialNumbers.length : 0;

            if (!geotabDatabaseName) {
                geotabDatabaseName = "";
                geotabDatabaseNameSeemsValid = false;
            } else if (geotabDatabaseName.length < 4) { // TODO confirm minimum database length in My Geotab or somewhere
                geotabIntegrationSaveIsRequired = true;
                geotabDatabaseNameSeemsValid = false;
            }
        }

        return (
            <>
            <div className="message">
                {message}
            </div>
            <ul className="order-unit-details">
                <li>
                    <label className="label large-dialog-mode">Name:</label>
                    <input className="value" id="order-shipping-step-details-name" value={this.state.name ? this.state.name : ""} disabled={true} />
                    <button className="copy" name="name" onClick={this.copyCorrespondingInput} >Copy Name</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">Street Address Line 1:</label>
                    <input className="value" id="order-shipping-step-details-shipping-address-street-line-1" value={this.state.shippingAddressStreetLine1 ? this.state.shippingAddressStreetLine1 : ""} disabled={true} />
                    <button className="copy" name="shippingAddressStreetLine1" onClick={this.copyCorrespondingInput} >Copy Street Address Line 1</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">Street Address Line 2:</label>
                    <input className="value" id="order-shipping-step-details-shipping-address-street-line-2" value={this.state.shippingAddressStreetLine2 ? this.state.shippingAddressStreetLine2 : ""} disabled={true} />
                    <button className="copy" name="shippingAddressStreetLine2" onClick={this.copyCorrespondingInput} >Copy Street Address Line 2</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">City:</label>
                    <input className="value" id="order-shipping-step-details-shipping-address-city" value={this.state.shippingAddressCity ? this.state.shippingAddressCity : ""} disabled={true} />
                    <button className="copy" name="shippingAddressCity" onClick={this.copyCorrespondingInput} >Copy City</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">ZIP/Postal Code:</label>
                    <input className="value" id="order-shipping-step-details-shipping-address-zip-code" value={this.state.shippingAddressZipCode ? this.state.shippingAddressZipCode : ""} disabled={true} />
                    <button className="copy" name="shippingAddressZipCode" onClick={this.copyCorrespondingInput} >Copy ZIP/Postal Code</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">State/Province:</label>
                    <input className="value" id="order-shipping-step-details-shipping-address-region" value={this.state.shippingAddressRegion ? this.state.shippingAddressRegion : ""} disabled={true} />
                    <button className="copy" name="shippingAddressRegion" onClick={this.copyCorrespondingInput} >Copy State/Province</button>
                </li>
                <li>
                    <label className="label large-dialog-mode">Country:</label>
                    <input className="value" id="order-shipping-step-details-shipping-address-country" value={this.state.shippingAddressCountry ? this.state.shippingAddressCountry : ""} disabled={true} />
                    <button className="copy" name="shippingAddressCountry" onClick={this.copyCorrespondingInput} >Copy Country</button>
                </li>
                {orderComment ?
                    <li>
                        <label className="label large-dialog-mode">Order Comment:</label>
                        <span className="comment">{orderComment}</span>
                    </li>
                :
                    null
                }
                {this.state.shippingSectionPending ? <ProgressOverlay /> : null }
            </ul>
            {trackingNumberRequired ?
                <div className="order-form small-dialog-mode">
                    <section>
                        <div className="error-message">{this.state.trackingNumberErrorMessage}</div>
                        <div>
                            <label>{trackingNumberLabel}</label>
                            <input type="text" value={trackingNumber} name="trackingNumber" onChange={this.onInputChange} />
                            {this.state.trackingNumberPending ? <ProgressOverlay /> : null}
                        </div>
                    </section>
                </div>
            :
                null
            }
            {this.state.geotabIntegrationFound && geotabIntegrationTotalRavensInThisOrder ? // only show the Geotab integration form if there are ravens on the order (don't show for accessories)
                <div className="order-form request-to-ship">
                    <header>
                        Complete The Geotab Configuration:
                    </header>
                    <section>
                        <div>
                            <label>{"Geotab Order Type"}<span className="required-badge">•</span></label>
                            <div className="order-form-radio-wrapper">
                                <input autoComplete="off" type="radio" name="orderType" checked={this.state.mutableOrderTypeFromProps === ORDER_TYPES.RAVEN_GT} value={ORDER_TYPES.RAVEN_GT} id={ORDER_TYPES.RAVEN_GT} onChange={this.updateGeotabOrderType} />
                                <label htmlFor={ORDER_TYPES.RAVEN_GT}>GT</label>
                            </div>
                            <div className="order-form-radio-wrapper">
                                <input autoComplete="off" type="radio" name="orderType" checked={this.state.mutableOrderTypeFromProps === ORDER_TYPES.RAVEN_GTS} value={ORDER_TYPES.RAVEN_GTS} id={ORDER_TYPES.RAVEN_GTS} onChange={this.updateGeotabOrderType} />
                                <label htmlFor={ORDER_TYPES.RAVEN_GTS}>GTS</label>
                            </div>
                            <div className="diagnostics">{/* using a diagnostics class wrapper to leverage Diagnostics panel CSS */}
                                <div className="info-icon">
                                    <img src={supporttoolIconInfoWhite} />
                                    <div className="tooltip-large">
                                        <span>
                                            {"Please select the type of ravens for this Geotab order. GT ravens are paired with Go9s. GTS ravens are stand-alone."}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="error-message">{this.state.geotabOrderTypeErrorMessage}</div>
                            {this.state.geotabSectionPending ? <ProgressOverlay /> : null}
                        </div>
                        <div>
                            <label>{"Step 1 - Geotab Database"}<span className="required-badge">•</span></label>
                            <input type="text" value={geotabDatabaseName} name="geotabDatabaseName" onChange={this.onInputChange} placeholder={"Enter Customer Geotab DB Name"} />
                            <div className="diagnostics">{/* using a diagnostics class wrapper to leverage Diagnostics panel CSS */}
                                <div className="info-icon">
                                    <img src={supporttoolIconInfoWhite} />
                                    <div className="tooltip-large">
                                        <span>
                                            {"Please insert Customer's Geotab Database name from MyAdmin > Device Admin > Select TELU01 > Look up Devices > Owned Databases"}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="error-message">{this.state.geotabDatabaseNameErrorMessage}</div>
                            {this.state.geotabSectionPending ? <ProgressOverlay /> : null}
                        </div>
                        <div>
                            <label>{"Step 2 - Export Serial Numbers"}<span className="required-badge">•</span></label>
                            <button disabled={!geotabDatabaseNameSeemsValid} onClick={this.onClickDownloadGeotabDeviceSerialNumbers}>Download Geotab Serial Numbers</button>
                            <div className="diagnostics">
                                <div className="info-icon">
                                    <img src={supporttoolIconInfoWhite} />
                                    <div className="tooltip-large">
                                        <span>
                                            Click to download a CSV of the Raven Geotab serial numbers to import for the customer.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {this.state.geotabSectionPending ? <ProgressOverlay /> : null}
                        </div>
                        <div>
                            <label>{"Step 3 "}<span className="required-badge">{"Reminder"}</span>{" - Add Geotab S/Ns to Customer's Account"}</label>
                            {this.state.trackingNumberPending ? <ProgressOverlay /> : null}
                            <div className="diagnostics">
                                <div className="info-icon">
                                    <img src={supporttoolIconInfoWhite} />
                                    <div className="tooltip-large">
                                        <span>
                                            Add using the <a href="https://docs.google.com/document/d/1YBiHzwO4NrqekWvFriBey5g-ayW3-lb1-O9YvIRQcD4/edit" target="_blank">Llama tool</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="error-message">{this.state.trackingNumberErrorMessage}</div>{/* onSubmit handles both "Request To Ship" and "Ship" ops; "Request To Ship" leverages the same trackingNumberErrorMessage plumbing as "Ship" */}
                    </section>
                </div>
            :
                null
            }
            <div className="actions">
                <button className="destructive" onClick={this.props.onCancel}>Cancel</button>
                <button className="destructive" onClick={this.props.onEditShippingAddressClick}>Edit Shipping Address</button>
                {showSubmitButton && !geotabIntegrationSaveIsRequired ?
                    <button onClick={trackingNumberRequired ? this.onSubmit : this.props.onSubmit }>{nextButtonLabel}</button>
                :
                    null
                }
            </div>
            </>
        )
    }
}

class ActiveOrdersTableOrderComment extends React.PureComponent {
    static propTypes = {
        order: PropTypes.object.isRequired, // for order.pending state
    }

    render() {
        if (!this.props.order.comment) {
            return null;
        }
        return (
            <section className="order-comment">
                <div>
                    <strong>Comment</strong>: {this.props.order.comment}
                </div>
            </section>
        )
    }
}
class ActiveOrdersTableOrderAccessories extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            itemTypes: this.props.itemTypes,
            totalRavens: undefined,
            accessoriesErrors: [],
            totalAccessories: undefined,
            totalAccessoriesErrorOccurred: undefined,
            totalSDCards: undefined, // included in totalAccessories
            accessoryItemTypesTotalsEditsFound: false
        }
    }

    static propTypes = {
        order: PropTypes.object.isRequired, // for order.pending state
        itemTypes: PropTypes.array, // [{"id":"RAVEN","name":"string","category":"DEVICE"}]
        setAccessoryItemTypesTotalsEditsFound: PropTypes.func.isRequired,
        onUpdateOrderAccessoriesClick: PropTypes.func.isRequired,
        totalItemsForCategory: PropTypes.func.isRequired
    }

    prefillForOrderEditMode = () => { // very similar concept to OrderForm.prefillForOrderEditMode

        if (!this.state.itemTypes) {
            console.warn("ActiveOrdersTableOrderAccessories prefillForOrderEditMode no itemTypes yet");
            return;
        }

        if (!this.props.order) {
            console.error("ActiveOrdersTableOrderAccessories prefillForOrderEditMode order not found");
            return;            
        }

        let totalRavens = 0;
        let totalAccessories = 0; // this.props.totalItemsForCategory(order.items, "ACCESSORY");
        let totalSDCards = 0;
        const accessoriesTotalsByTypeId = {};
        const beaconUnits = [];
        const beaconOrderItems = [];
        const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));

        this.props.order.items.forEach( (item) => {
            switch (item.itemType) {
                //case "RAVEN": (not supported by this form)
                case "RAVEN_PLUS":
                    totalRavens += 1;        
                    break;
                default:
                    let itemCategory = undefined;

                    for (let itemTypeIndex in itemTypes) {

                        if (itemTypes[itemTypeIndex].id === item.itemType) {

                            itemCategory = itemTypes[itemTypeIndex].category;
                            
                            if (["ACCESSORY", "BEACON"].includes(itemCategory)) {

                                if (!accessoriesTotalsByTypeId.hasOwnProperty(itemTypes[itemTypeIndex].id)) {
                                    accessoriesTotalsByTypeId[itemTypes[itemTypeIndex].id] = 0;
                                }
                                accessoriesTotalsByTypeId[itemTypes[itemTypeIndex].id] += 1;
                                totalAccessories += 1;
                                if (itemTypes[itemTypeIndex].id.startsWith("SD_CARD_")) {
                                    totalSDCards += 1;
                                }

                                if (itemCategory === "BEACON") {
                                    beaconOrderItems.push(item);
    
                                    if(item.beaconUnit && item.beaconUnit.macAddress) {
                                        beaconUnits.push(item.beaconUnit.macAddress);
                                    }
                                    else{
                                        beaconUnits.push("");                                    
                                    }
                                }

                                break;
                            }                            
                        }
                    }
                    if (itemCategory === "DEVICE") {
                        console.error("ActiveOrdersTableOrderAccessories prefillForOrderEditMode orderForEditMode items included unsupported ratePlan DEVICE " + item.itemType);
                    }
                    return;
            }
        });


        itemTypes.forEach( (itemType) => {
            if (!["ACCESSORY", "BEACON"].includes(itemType.category)) return;

            let itemTypeQuantity = 0;
            if (accessoriesTotalsByTypeId.hasOwnProperty(itemType.id)) {
                itemTypeQuantity = accessoriesTotalsByTypeId[itemType.id];
            }
            itemType.quantity = itemTypeQuantity;
            itemType.quantityFromProps = itemTypeQuantity; // used in this.accessoryItemTypesTotalsEditsFound() et al

            if (itemType.category === "BEACON") {
                itemType.units = beaconUnits;
                itemType.orderItems = beaconOrderItems;
            }
        })

        
        const accessoryItemTypesTotalsEditsFound = this.accessoryItemTypesTotalsEditsFound(itemTypes);

        const accessoriesErrors = [];
        if (totalRavens > totalSDCards) {
            accessoriesErrors.push("At least " + totalRavens + " SD Card(s) are required (1 per Raven)");
        }

        this.setState({
            totalRavens: totalRavens,
            totalAccessories: totalAccessories,
            totalSDCards: totalSDCards,
            itemTypes: itemTypes.filter((itemType) => itemType.active || itemType.quantity > 0), // Only active itemTypes or those with quantity > 0 (for previous orders that include inactive itemTypes)
            accessoryItemTypesTotalsEditsFound: accessoryItemTypesTotalsEditsFound,
            accessoriesErrors: accessoriesErrors
        });
        // update parent component state:
        this.props.setAccessoryItemTypesTotalsEditsFound(accessoryItemTypesTotalsEditsFound);
    }

    componentDidMount() {
        this.prefillForOrderEditMode();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.order !== prevProps.order) {
            this.prefillForOrderEditMode();
        }
    }

    submitMacAddress = (beaconOrderItem, macAddress) => {
        this.props.updateOrderItemBeaconMacAddress(beaconOrderItem, macAddress);
    }

    onInputChange = (event) => {

        if(this.props.order.status !== ORDER_STATUS.PENDING_RAVENS){
            console.warn("Only can edit accessories details IN PENDING_RAVENS STATUS");
            return;
        }

        const itemTypeId = event.target.name;
        const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));

        if(itemTypeId.startsWith("BEACON")){
            const beaconItemType = itemTypes.find((itemType) => {
                return itemType.id === 'BEACON';
            });

            if (!beaconItemType) {
                console.error("ActiveOrdersTableOrderAccessories onInputChange beaconItemType not found");
                return;
            }

            const [_, index] = itemTypeId.split("-");

            const macAddress = event.target.value;

            beaconItemType.units[index] = macAddress; // update the mac address in the state even if it's invalid right now, maybe user is typing and once complete it will be valid
            this.setState({
                itemTypes: itemTypes,
            });

            //  But don't submit it to server if it's invalid
            if(!this.validateMacAddress(macAddress)){
                return;
            }

            this.submitMacAddress(beaconItemType.orderItems[index], macAddress);            
        }
    }

    onCountChange = (event) => {

        const itemTypeId = event.target.name;
        const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));

        let totalAccessories = 0;
        let totalSDCards = 0;

        if (isNaN(event.target.value)) return; // check against non-numeric input

        itemTypes.forEach((itemType) => {
            if (itemType.id === itemTypeId) {
                itemType.quantity = parseInt(event.target.value);
            }

            if (itemType.quantity) {
                totalAccessories += itemType.quantity;
                
                if (itemType.id.startsWith("SD_CARD_")) {
                    totalSDCards += itemType.quantity;
                }
            }
        });
        
        const accessoryItemTypesTotalsEditsFound = this.accessoryItemTypesTotalsEditsFound(itemTypes);

        this.setState({
            totalAccessories: totalAccessories,
            totalSDCards: totalSDCards,
            itemTypes: itemTypes,
            accessoryItemTypesTotalsEditsFound: accessoryItemTypesTotalsEditsFound
        });

        // update parent component state:
        this.props.setAccessoryItemTypesTotalsEditsFound(accessoryItemTypesTotalsEditsFound);
    }

    accessorySimpleItemTypesFromState = () => {

        // If order is in one of these steps, show accessories with zero quantity
        // Else hide them
        const statusToIncludeAccessoriesWithZeroQuantity = [ORDER_STATUS.NEW, ORDER_STATUS.PENDING_RAVENS, ORDER_STATUS.PENDING_SIM_DETAILS];

        const includeAccessoriesWithZeroQuantity = statusToIncludeAccessoriesWithZeroQuantity.includes(this.props.order.status);
        
        const accessorySimpleItemTypes = [];

        if (!this.state.itemTypes) {
            console.warn("OrderForm accessoryItemTypesFromState no itemTypes yet");
        }
        else{
            this.state.itemTypes.forEach((itemType) => {
                if (itemType.category === "ACCESSORY") {
                    if (itemType.quantity > 0 || includeAccessoriesWithZeroQuantity) {
                        accessorySimpleItemTypes.push(itemType);
                    }
                }
            });
        }

        return accessorySimpleItemTypes;
    }

    accessoryBeaconsFromProps = () => {

        const beacons = [];

        if (!this.props.order) {
            console.warn("OrderForm accessoryBeaconsFromProps no order yet");
        }
        else{
            this.props.order.items.forEach((item) => {
                if (item.itemType === "BEACON") {
                    beacons.push(item);
                }
            });
        }

        return beacons;
    }

    accessoryItemTypesTotalsEditsFound = (itemTypes) => {
        let editsFound = false;
        itemTypes.forEach ( (itemType) => {
            if (itemType.quantity !== itemType.quantityFromProps) {
                editsFound = true;
            }
        });
        return editsFound;
    }

    accessoryTypeStatusClass = (itemTypeId) => {
        const itemType = this.state.itemTypes.find( (itemType) => {
            return itemType.id === itemTypeId;
        });

        if (!itemType) {
            return null;
        }

        if (itemType.quantity !== itemType.quantityFromProps) {
            return "pending";
        }

        if (!itemType.quantity) {
            return null;
        }

        //return "error"; // failed save or validation
        return "success"; // no changes found
    }

    beaconStatusClass = (index) => {
        const itemType = this.state.itemTypes.find((itemType) => {
            return itemType.id === 'BEACON';
        });

        if (!itemType) {
            return null;
        }

        if (!itemType.units) {
            return null;
        }

        if (!itemType.units[index]) {
            return null;
        }

        if(!this.validateMacAddress(itemType.units[index])) {
            return null;
        }

        //return "error"; // failed save or validation
        return "success"; // no changes found
    }

    undoChanges = () => {
        this.prefillForOrderEditMode();
    }

    addErrorMessage = (errorString) => {
        const errorIndex = this.state.accessoriesErrors.indexOf(errorString);
        
        if (errorIndex >= 0) return;

        this.setState({accessoriesErrors: [...this.state.accessoriesErrors, errorString]});
    }

    deleteErrorMessage = (errorString) => {
        const errorIndex = this.state.accessoriesErrors.indexOf(errorString);
        
        if (errorIndex === -1) return;

        this.setState({accessoriesErrors: this.state.accessoriesErrors.filter( (error) => error !== errorString)});
    }

    validateMacAddress = (macAddress) => {
        const errorMessage = "Invalid MAC Address";
        if(!macAddress || macAddress.length === 0) {
            this.deleteErrorMessage(errorMessage);
            return false; 
        }

        if(!/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(macAddress)){
            this.addErrorMessage(errorMessage);
            return false;
        }

        return true;
    }

    validateAccessoriesForm = () => {
        const errorMessage = "At least " + this.state.totalRavens + " SD Card(s) are required (1 per Raven)";

        if (this.state.totalRavens > this.state.totalSDCards) {
            this.addErrorMessage(errorMessage);
            return false;
        }

        this.deleteErrorMessage(errorMessage);
        return true;
    }

    updateOrderAccessories = () => {

        if (!this.validateAccessoriesForm()) {
            return;
        }

        const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));
        this.props.onUpdateOrderAccessoriesClick(this.props.order.orderNumber, itemTypes, this.undoChanges);
    }

    onAddBeacon = () => {
        const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));
        const beaconItemType = itemTypes.find( (itemType) => {
            return itemType.id === 'BEACON';
        });

        if (!beaconItemType) {
            console.error("ActiveOrdersTableOrderAccessories onAddBeacon beaconItemType not found");
            return;
        }

        this.props.onAddOrderItemClick(this.props.order.orderNumber, beaconItemType.id);
    }

    onDeleteBeacon = (index) => {
        
        const itemTypes = JSON.parse(JSON.stringify(this.state.itemTypes));
        const beaconItemType = itemTypes.find( (itemType) => {
            return itemType.id === 'BEACON';
        });

        if (!beaconItemType) {
            console.error("ActiveOrdersTableOrderAccessories onAddBeacon beaconItemType not found");
            return;
        }

        this.props.onRemoveOrderItemClick(this.props.order.orderNumber, beaconItemType.orderItems[index].orderItemId);
    }

    copyMacAddress = (event, macAddress) => {
        event.preventDefault();

        const icon = event.target;
        icon.classList.add('copied');

        navigator.clipboard.writeText(macAddress)
        .then(() => {
            setTimeout(() => {
                icon.classList.remove('copied');
            }, 500);
        })
        .catch((err) => {
            console.error('Failed to copy: ', err);
        });
    }

    inputSelectAll = (event) => {

        const target = event.target;

        setTimeout(() => {
            if(!target) return;
            if(target.value.length === 0) return;
            target.select();
        }, 100); // helps ensure a single click select all
    }

    render() { 
        
        // very similar to OrderForm.render order-accessories section
        const simpleItemTypes = this.accessorySimpleItemTypesFromState();

        /*
            In the first render of accessories, the state.itemTypes is not yet populated by quantity values from the order items.
            That is done in prefillForOrderEditMode() in componentDidMount() which is after the first render.
            So it can be used in second render but
            The updateAutoFocusCandidateHighlighting() function happens before the second render, so it can't see beacons fields and can't set focus on them if needed.
            So we need use props to render beacons fields in the first render.
        */
        const beaconsFromProps = this.accessoryBeaconsFromProps();
        const beaconsFromState = this.state.itemTypes.find( (itemType) => itemType.id === 'BEACON');
        const areBeaconsPopulatedInState = beaconsFromState && beaconsFromState.units && beaconsFromState.units.length > 0;
        const canEditBeacons = this.props.order.status === ORDER_STATUS.PENDING_RAVENS; // Beacons can be edited only in PENDING_RAVENS status
        const canAddBeacons = this.props.order.status === ORDER_STATUS.PENDING_RAVENS && this.props.order.orderType === ORDER_TYPES.RAVEN; // Beacons can be added only in PENDING_RAVENS status and for co-branded Raven orders
        const canDeleteBeacons = shouldShowEditOrderItemsFeatures(this.props.order.status);
        const showFieldsForBeacons = beaconsFromProps.length > 0;
        const showFieldsForSimpleItemTypes = simpleItemTypes.length > 0;

        if (!showFieldsForSimpleItemTypes && !showFieldsForBeacons) return null;

        let inputsDisabled = false;
        let showValidationErrors = true; // RAV-1842
        switch (this.props.order.status) {
            case ORDER_STATUS.NEW:
            case ORDER_STATUS.PENDING_RAVENS:
            case ORDER_STATUS.PENDING_SIM_DETAILS:
            case ORDER_STATUS.PENDING_SHIPMENT:
                break;
            case ORDER_STATUS.COMPLETED:
                inputsDisabled = true;
                showValidationErrors = false;
                break;
            default:
                console.warn("ActiveOrdersTableOrderAccessories render inputsDisabled/showValidationErrors check for unrecognized order status " + this.props.order.status);
                break;        
        }

        return (
            <section className="order-accessories">
                {/* Header */}
                <header className="row">
                    <div className="accessory-type-merged-rate-plan-name">
                        Accessories for order {this.props.order.orderNumber}:
                        {showValidationErrors && this.state.accessoriesErrors.length ?
                            <ul>{this.state.accessoriesErrors.map( (errorString, index) => {return <li className="error-message" key={index}>{errorString}</li>})}</ul>
                            :
                            null
                        }
                    </div>
                    <div className="order-status">
                    </div>
                </header>

                {/* Beacons */}
                {showFieldsForBeacons ?
                <>
                    {showFieldsForSimpleItemTypes &&
                        <div className='text-info text-right font-weight-bolder' style={{paddingRight: '301px', fontSize: '1.1rem'}}>Beacons</div>}{/* Only if there are other accessories besides beacons, we need this header to separate them */}
                    
                    {beaconsFromProps.map((beaconFromProps, index) => {
                        const isBeaconPopulatedInState = areBeaconsPopulatedInState && beaconsFromState.units[index] !== undefined;
                        const beaconMacAddress = isBeaconPopulatedInState ? beaconsFromState.units[index] : "";
                        const hasMacAddress = beaconMacAddress.length > 0;
                        const originallyHadMacAddress = beaconFromProps.beaconUnit && beaconFromProps.beaconUnit.macAddress && beaconFromProps.beaconUnit.macAddress.length > 0;
                        const pending = beaconFromProps.pending || false;
                        let showDeleteMacAddressButton = false;

                        // if beacon in state does not have mac address, 
                        // and beacon in props has mac address, show 'Apply Deletion' button
                        if(isBeaconPopulatedInState && !hasMacAddress){
                            if(originallyHadMacAddress){
                                showDeleteMacAddressButton = true;
                            }
                        }

                        let inputMacAddressAutoFocusCandidateProps = {};
                        let iconMacAddressStatusAutoFocusCandidateProps = {};
                        
                        inputMacAddressAutoFocusCandidateProps["data-order-id"] = this.props.order.orderId;
                        
                        if (!originallyHadMacAddress && !hasMacAddress) {
                            inputMacAddressAutoFocusCandidateProps[INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE] = true; // for parent component get-all using document.querySelectorAll('[INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE]');)
                            inputMacAddressAutoFocusCandidateProps.id = INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_ID_PREFIX + beaconFromProps.orderItemId;
                            iconMacAddressStatusAutoFocusCandidateProps.id = inputMacAddressAutoFocusCandidateProps.id +
                INPUT_AUTO_FOCUS_CANDIDATE_ID_CORRESPONDING_STATUS_ICON_SUFFIX;
                        }
                        
                        return (
                            <div key={index} className="row item">
                                <div className="accessory-type">
                                    Beacon #{index + 1}
                                </div>
                                <div className="mac-address rate-plan-name">
                                    <input autoComplete="off"
                                        value={beaconMacAddress}
                                        name={`BEACON-${index}`}
                                        onChange={this.onInputChange}
                                        onFocus={this.inputSelectAll}
                                        readOnly={!canEditBeacons}
                                        maxLength={17}
                                        type="text"
                                        placeholder="MAC Address"
                                        {...inputMacAddressAutoFocusCandidateProps}
                                    />
                                    {showDeleteMacAddressButton ?
                                        <div className="delete-item">
                                            <button type="button" onClick={() => {this.submitMacAddress(beaconFromProps, "")}} >Apply Deletion</button>
                                        </div>
                                    :
                                        null
                                    }
                                    {pending ? <ProgressOverlay /> : null }
                                </div>
                                <div className="order-status">
                                    <img src={supporttoolIconBeacon} className={this.beaconStatusClass(index)} title="Included" {...iconMacAddressStatusAutoFocusCandidateProps} />
                                    {canDeleteBeacons ? <img src={supporttoolIconDeleteBeacon} style={{filter: 'hue-rotate(0deg)', cursor: "pointer"}} onClick={() => this.onDeleteBeacon(index)} /> : <img src={supporttoolIconBeacon} style={{visibility:"hidden"}}/>}
                                    <img src={supporttoolIconCopyContent} style={{visibility: hasMacAddress ? "visible" : "hidden", cursor: "pointer"}} onClick={(e) => this.copyMacAddress(e, beaconMacAddress)} />
                                </div>
                            </div>
                        );
                    })}
                    <div className="row summary">
                        <div className="accessory-type">
                        </div>
                        <div className="rate-plan-name edit-order">
                            {canAddBeacons && <button
                                onClick={this.onAddBeacon}
                                type="button"
                            >Add Beacon</button>}
                        </div>
                        <div className="order-status">
                            <img src={supporttoolIconBeacon} style={{visibility:"hidden"}}/>
                            <img src={supporttoolIconBeacon} style={{visibility:"hidden"}}/>
                            <img src={supporttoolIconBeacon} style={{visibility:"hidden"}}/>
                        </div>
                    </div>
                </>
                : null
                }

                {/* Other Accessories */}
                 {/* Only if there are beacons, we show this header to separate beacons and other accessories */}
                 {/* But if there are no beacons, we show a 'Add Beacon' button */}
                {showFieldsForBeacons
                 ? showFieldsForSimpleItemTypes && <div className='text-info text-right font-weight-bolder' style={{paddingRight: '301px', fontSize: '1.1rem'}}>Other Accessories</div> 
                 : 
                 canAddBeacons && <div className="row summary">
                        <div className="accessory-type">
                        </div>
                        <div className="rate-plan-name edit-order">
                            <button
                                onClick={this.onAddBeacon}
                                type="button"
                            >Add Beacon</button>
                        </div>
                        <div className="order-status">
                            <img src={supporttoolIconBeacon} style={{visibility:"hidden"}}/>
                            <img src={supporttoolIconBeacon} style={{visibility:"hidden"}}/>
                            <img src={supporttoolIconBeacon} style={{visibility:"hidden"}}/>
                        </div>
                    </div>} 

                {simpleItemTypes.map((simpleItemType, index) => {
                    return (<div key={index} className="row item">
                        <div className="accessory-type">
                            {simpleItemType.name}
                        </div>
                        <div className="rate-plan-name">
                            <input autoComplete="off"
                                value={simpleItemType.quantity ? simpleItemType.quantity : ""}
                                name={simpleItemType.id}
                                onChange={this.onCountChange}
                                disabled={inputsDisabled}
                                type="text"
                                placeholder="none"
                            />
                        </div>
                        <div className="order-status">
                            <img src={supporttoolIconShipment} className={this.accessoryTypeStatusClass(simpleItemType.id)} title="Included"/>
                            <img src={supporttoolIconShipment} style={{visibility:"hidden"}}/>
                            <img src={supporttoolIconShipment} style={{visibility:"hidden"}}/>
                        </div>
                    </div>);
                })}
                <div className="row item">
                    <div className="accessory-type">
                        Total Accessories {this.state.totalRavens ? " (requires at least " + this.state.totalRavens + " SD cards)" : null}<span className="required-badge">•</span>:
                    </div>
                    <div className="rate-plan-name">{this.state.totalAccessories}</div>
                    <div className="order-status"></div>
                </div>
                {this.state.accessoryItemTypesTotalsEditsFound ?
                    <div className="row summary">
                        <div className="accessory-type edit-order">
                            <button type="button destructive" onClick={this.undoChanges}>Undo</button>
                            <button type="button" onClick={this.updateOrderAccessories}>Update Accessories</button>
                        </div>
                        <div className="order-status"></div>
                    </div>
                :
                    null
                }
                <div className="order-status"></div>
                {this.props.order.pending ? <ProgressOverlay /> : null }
            </section>
        )
    }
}
class ActiveOrdersTableOrder extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            accessoryItemTypesTotalsEditsFound: false
        }
    }

    static propTypes = {
        order: PropTypes.object.isRequired,
        ratePlans: PropTypes.array, // [{"id":"1","name":"3 year"},{"id":"2","name":"MTM"}]
        itemTypes: PropTypes.array, // [{"id":"RAVEN","name":"string","category":"DEVICE"}]
        totalItemsForCategory: PropTypes.func.isRequired,
        activeOrdersTableOrderIndex: PropTypes.number.isRequired, // Assumption: enables quick lookups in model
        updateOrderItem: PropTypes.func.isRequired,
        setOrderItem: PropTypes.func.isRequired,
        updateOrderStatus: PropTypes.func.isRequired,
        editOrderItemExternalId: PropTypes.func.isRequired,
        onEditOrderClick: PropTypes.func.isRequired,
        onAddOrderItemClick: PropTypes.func.isRequired,
        onRemoveOrderItemClick: PropTypes.func.isRequired,
        onUpdateOrderAccessoriesClick: PropTypes.func.isRequired,
        onOrderInfoClick: PropTypes.func.isRequired,
        onAccountInfoClick: PropTypes.func.isRequired,
        onSupportTicketClick: PropTypes.func.isRequired,
    };

    updateOrderItemUnitSerial = (orderItem, unitSerial) => {

        const orderItemId = orderItem.orderItemId;

        if (!orderItemId) {
            console.error("ActiveOrdersTableOrder updateOrderItemUnitSerial orderItem missing or invalid orderItemId", orderItem);
            return;
        }

        if (unitSerial === "") { // assumption: this is a request to clear/delete an existing ravenUnit
            this.props.setOrderItem(
                this.props.order.orderId,
                orderItem.orderItemId,
                Object.assign(orderItem, {ravenUnit: null})
            );
            return;
        }

        if (!unitSerial) {
            console.error("ActiveOrdersTableOrder updateOrderItemUnitSerial missing or invalid unitSerial", unitSerial);
            return;
        }

        this.props.updateOrderItem({
            orderId: this.props.order.orderId,
            orderItemId: orderItemId,
            ravenUnit: {
                serial: unitSerial
            }
        });
    }

    updateOrderItemBeaconMacAddress = (orderItem, macAddress) => {

        const orderItemId = orderItem.orderItemId;

        if (!orderItemId) {
            console.error("ActiveOrdersTableOrder updateOrderItemBeaconMacAddress orderItem missing or invalid orderItemId", orderItem);
            return;
        }

        if (macAddress === "") { // assumption: this is a request to clear/delete an existing beacon
            this.props.setOrderItem(
                this.props.order.orderId,
                orderItem.orderItemId,
                Object.assign(orderItem, {beaconUnit: null})
            );
            return;
        }

        if (!macAddress) {
            console.error("ActiveOrdersTableOrder updateOrderItemBeaconMacAddress missing or invalid macAddress", macAddress);
            return;
        }

        this.props.updateOrderItem({
            orderId: this.props.order.orderId,
            orderItemId: orderItemId,
            beaconUnit: {
                macAddress: macAddress
            }
        });
    }

    editOrderItemExternalId = (orderItem) => {
        this.props.editOrderItemExternalId(this.props.order, orderItem);
    }

    editOrderItemRatePlanId = (orderItem) => {
        this.props.editOrderItemRatePlanId(this.props.order, orderItem);
    }

    onRemoveOrderItemClick = (orderItemId) => {
        this.props.onRemoveOrderItemClick(this.props.order.orderNumber, orderItemId);
    }

    setAccessoryItemTypesTotalsEditsFound = (editsFound) => {
        this.setState({
            accessoryItemTypesTotalsEditsFound: editsFound
        })
    }

    render() {

        // RAV-4781 Don't show "Add Row" in "Pending Provider IDs" status
        const showEditOrderItemsFeatures = this.props.order.status === ORDER_STATUS.PENDING_RAVENS; //shouldShowEditOrderItemsFeatures(this.props.order.status);
        const totalDevices = this.props.totalItemsForCategory(this.props.order.items, "DEVICE");

        return (
            <>
            <ActiveOrdersTableHeader />
            <div data-order-id={this.props.order.orderId} className="row">

                <div className="order-number">
                    <button type="button" onClick={() => {this.props.onOrderInfoClick(this.props.order); }}>
                        {this.props.order.orderNumber || this.props.order.orderId} ({totalDevices})
                    </button>
                    <button type="button" onClick={() => {this.props.onSupportTicketClick(this.props.order); }}>
                        <span className="material-icons">help</span>
                    </button>
                </div>
                {/*<div className="order-date">
                    {this.props.order.date.toLocaleDateString()}
                </div>*/}
                <div className="account-name">
                    <button type="button" onClick={() => {this.props.onAccountInfoClick(this.props.order); }}>
                        {this.props.order.account.externalId || this.props.order.account.email}
                    </button>
                    <button type="button" onClick={() => {this.props.onSupportTicketClick(this.props.order); }}>
                        <span className="material-icons">help</span>
                    </button>

                </div>

                <section className="order-items">
                    <header className="row">
                        <div className="order-status">
                            Summary status
                        </div>
                    </header>
                    {this.props.order.items.map( (item, index) => {
                        return <ActiveOrdersTableOrderItem
                            key={item.orderItemId + "-" + index}
                            item={item}
                            orderStatus={this.props.order.status}
                            orderId={this.props.order.orderId}
                            ratePlans={this.props.ratePlans}
                            updateOrderItemUnitSerial={this.updateOrderItemUnitSerial}
                            editOrderItemExternalId={this.editOrderItemExternalId}
                            editOrderItemRatePlanId={this.editOrderItemRatePlanId}
                            onRemoveOrderItemClick={this.onRemoveOrderItemClick}
                        />
                    })}
                    <footer className="row">
                        <div className="edit-order">
                            {showEditOrderItemsFeatures ? 
                                <button type="button" onClick={() => {this.props.onAddOrderItemClick(this.props.order.orderNumber)}}>Add Row</button>
                            :
                                null
                            }
                            {this.props.order.pending ? <ProgressOverlay /> : null}
                        </div>
                    </footer>
                </section>
            </div>
            <div data-order-id={this.props.order.orderId} className="row">
                <ActiveOrdersTableOrderComment
                    order={this.props.order}
                />
                <ActiveOrdersTableOrderAccessories
                    order={this.props.order}
                    itemTypes={this.props.itemTypes}
                    setAccessoryItemTypesTotalsEditsFound={this.setAccessoryItemTypesTotalsEditsFound}
                    totalItemsForCategory={this.props.totalItemsForCategory}
                    onUpdateOrderAccessoriesClick={this.props.onUpdateOrderAccessoriesClick}
                    updateOrderItemBeaconMacAddress={this.updateOrderItemBeaconMacAddress}
                    onRemoveOrderItemClick={this.props.onRemoveOrderItemClick}
                    onAddOrderItemClick={this.props.onAddOrderItemClick}
                />
            </div>
            <div data-order-id={this.props.order.orderId} className="row">
                <section className="order-summary">
                    <footer className="row">
                        <div className="edit-order"></div>
                        <div className="complete-step">
                            <ActiveOrdersTableOrderNextStepButton
                                order={this.props.order}
                                updateOrderStatus={this.props.updateOrderStatus}
                                accessoryItemTypesTotalsEditsFound={this.state.accessoryItemTypesTotalsEditsFound}
                            />
                        </div>
                    </footer>
                </section>
            </div>
            </>
        )
    }
}

class ActiveOrdersTableOrderNextStepButton extends React.PureComponent {

    static propTypes = {
        order: PropTypes.object.isRequired,
        accessoryItemTypesTotalsEditsFound: PropTypes.bool.isRequired,
        updateOrderStatus: PropTypes.func.isRequired
    }

    labelForStatus = () => {
        switch (this.props.order.status) {
            case ORDER_STATUS.NEW:
            case ORDER_STATUS.PENDING_RAVENS: return "Notify Vendor";
            case ORDER_STATUS.PENDING_SIM_DETAILS: return "Request to Ship";
            case ORDER_STATUS.PENDING_SHIPMENT: return "Ship";
            case ORDER_STATUS.COMPLETED: return "Completed";
            default:
                console.error("ActiveOrdersTableOrderNextStepButton labelForStatus unrecognized order status");
                break;
        }
    }

    buttonDisabledForStatus = () => {
        let foundItemWithErrors = false;
        let foundBeaconWithoutUnitSerial = false;
        let foundItemWithoutRavenUnitSerial = false;
        let foundItemWithoutExternalId = false;
        let orderDoesNotHaveEnoughSDCards = false;
        let totalSDCards = 0;
        let totalRavens = 0;
        this.props.order.items.forEach( (item) => {
            if (item.errors) {
                foundItemWithErrors = true;
            }
            if (item.itemType.startsWith("SD_CARD_")) {
                totalSDCards += 1;
            } else if (item.itemType.startsWith("BEACON")) {
                if (!item.beaconUnit || !item.beaconUnit.macAddress) {
                    foundBeaconWithoutUnitSerial = true;
                }            
            } else if (item.itemType.startsWith("RAVEN")) {
                totalRavens += 1;
                if (!item.ravenUnit || !item.ravenUnit.serial) {
                    foundItemWithoutRavenUnitSerial = true;
                }
                if (!item.externalId) {
                    foundItemWithoutExternalId = true;
                }    
            } else {
                console.warn("ActiveOrdersTableOrderNextStepButton unknown item type " + item.itemType);
            }
        });

        if (totalRavens > totalSDCards) {
            orderDoesNotHaveEnoughSDCards = true;
        }

        if (orderDoesNotHaveEnoughSDCards) {
            return true;
        }

        if (this.props.accessoryItemTypesTotalsEditsFound) {
            return true;
        }

        switch (this.props.order.status) {
            case ORDER_STATUS.NEW:
            case ORDER_STATUS.PENDING_RAVENS:
                if (foundItemWithoutRavenUnitSerial || foundBeaconWithoutUnitSerial) {
                    return true;
                }
                return foundItemWithErrors;
            case ORDER_STATUS.PENDING_SIM_DETAILS:
                if (foundItemWithoutExternalId) {
                    return true;
                }
                return foundItemWithErrors;
            case ORDER_STATUS.PENDING_SHIPMENT:
                return foundItemWithErrors;
            case ORDER_STATUS.COMPLETED:
                return true;
            default:
                console.error("ActiveOrdersTableOrderNextStepButton buttonDisabledForStatus unrecognized order status, " + this.props.order.status);
                return true;
        }
    }

    render() {
        return (
            <>
            <button type="button" disabled={this.buttonDisabledForStatus()} onClick={() => {this.props.updateOrderStatus(this.props.order)}}>{this.labelForStatus()}</button>
            {this.props.order.pending ? <ProgressOverlay /> : null}
            </>
        )
    }
}

class ActiveOrdersTableOrderItem extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            // these items properties below remain undefined unless user input:
            unitSerial: undefined,
            unitSerialValidationError: undefined,
            iccid: undefined,
            iccidValidationError: undefined,
            imei: undefined,
            imeiValidationError: undefined,
            externalId: undefined,
            ratePlanId: undefined
        }
    }

    static propTypes = {
        item: PropTypes.object.isRequired,
        orderStatus: PropTypes.string.isRequired,
        orderId: PropTypes.string.isRequired,
        ratePlans: PropTypes.array, // [{"id":"1","name":"3 year"},{"id":"2","name":"MTM"}]
        updateOrderItemUnitSerial: PropTypes.func.isRequired,
        onRemoveOrderItemClick: PropTypes.func.isRequired,
        editOrderItemExternalId: PropTypes.func.isRequired,
        editOrderItemRatePlanId: PropTypes.func.isRequired
    }

    componentDidUpdate() { //(prevProps, prevState, snapshot)
        const itemErrors = this.props.item && this.props.item.errors ? this.props.item.errors : {}; // fallback to empty object to make checks easier below
        const unitSerialFromProps = this.props.item.ravenUnit && this.props.item.ravenUnit.serial;
        if (this.state.unitSerial === unitSerialFromProps) {
            this.setState({
                unitSerial: undefined
            });
        }
        if (this.state.externalId === this.props.item.externalId) {
            this.setState({
                externalId: undefined
            });
        }
        if (this.state.ratePlanId === this.props.item.ratePlanId) {
            this.setState({
                ratePlanId: undefined
            });
        }
    }

    serialNumberIsReadyForAutoSubmit = (unitSerial) => {

        if (unitSerial.includes("RVP")) {  // Raven v2
            if (unitSerial.length === RAVEN2_EXACT_LENGTH) return true;
            return false;
        } else if (unitSerial.includes("RVN")) { // Raven v1
            if (unitSerial.length === RAVEN1_EXACT_LENGTH) return true;
            return false;
        }

        if (unitSerial.length > RAVEN1_EXACT_LENGTH) { // unitSerial is missing RVP/RVN and seems obviously invalid
            return true; // Assumption: this will trigger validation error due to submit attempt
        }

        return false;
    }

    /* As per Shing's description of Raven S/N formats for Raven Classic and Plus
        Currently on Raven v1.0 the format is "1RVNwwyynnnn"  
        1 = site (different manufacturing site will have a different number), #1 is for Flex Austin.
        RVN = Raven v1.0
        ww = week number
        yy = year last 2 digits
        nnnn = unique serial number
        example: 1RVN14190099, manufactured 14th week of 2019, and is the 99th unit manufactured that week.  

        My proposal for Cannoli is as follows "1RVPwwyynnnnc":    
        1 = site (different manufacturing site will have a different number)
        RVP = Raven v1.5 Cannoli (use ASCII code representation to convert to number format)
        ww = week number
        yy = year last 2 digits
        nnnn = unique serial number
        c = check digit, similar to credit card
    */
    validateSerialNumber = (unitSerial) => {

        if (RAVEN2_SN_VALIDATION_EXCEPTIONS.includes(unitSerial)) return true;

        const upperCasedEnclosureSerialNo = unitSerial.toUpperCase();
        let firmwareType = upperCasedEnclosureSerialNo.slice(1,4);
        let unitSerialNumericComponents = [];
    
        switch (firmwareType) {
            case "RVP":
                unitSerialNumericComponents = upperCasedEnclosureSerialNo.split("RVP");
                break;
            case "RVN":
                unitSerialNumericComponents = upperCasedEnclosureSerialNo.split("RVN");
                break;
            default:
                console.warn("ActiveOrdersTableOrderItem validateSerialNumber firmwareType is invalid", unitSerial);
                this.setState({
                    unitSerialValidationError: "Invalid"
                });
                return false;
        }
            
        const siteId = unitSerialNumericComponents[0];
        const weekYearUniqueSN = unitSerialNumericComponents[1];
    
        if (siteId === "") {
            console.warn("ActiveOrdersTableOrderItem validateSerialNumber siteId not found", unitSerial);
            this.setState({
                unitSerialValidationError: "Invalid"
            });
            return false;
        }
        
        if (isNaN(siteId)) {
            console.warn("ActiveOrdersTableOrderItem validateSerialNumber siteId invalid format", unitSerial);
            this.setState({
                unitSerialValidationError: "Invalid"
            });
            return false;
        }

        if (siteId.length > 1) {
            console.warn("ActiveOrdersTableOrderItem validateSerialNumber siteId is invalid length", unitSerial);
            this.setState({
                unitSerialValidationError: "Invalid"
            });
            return false;        
        }

        if (weekYearUniqueSN === "") {
            console.warn("ActiveOrdersTableOrderItem validateSerialNumber weekYearUniqueSN not found", unitSerial);
            this.setState({
                unitSerialValidationError: "Invalid"
            });
            return false;
        }

        if (isNaN(weekYearUniqueSN)) {
            console.warn("ActiveOrdersTableOrderItem validateSerialNumber weekYearUniqueSN invalid format", unitSerial);
            this.setState({
                unitSerialValidationError: "Invalid"
            });
            return false;
        }

        switch (firmwareType) {
            case "RVP":
                if (weekYearUniqueSN.length !== 9) { // weekYearUniqueSN + single check digit
                    console.warn("ActiveOrdersTableOrderItem validateSerialNumber weekYearUniqueSN + check digit is invalid length for Raven Plus", unitSerial);
                    this.setState({
                        unitSerialValidationError: "Invalid"
                    });    
                    return false;
                }
                break;
            case "RVN":
                if (weekYearUniqueSN.length !== 8) {
                    console.warn("ActiveOrdersTableOrderItem validateSerialNumber weekYearUniqueSN is invalid length for Raven Classic", unitSerial);
                    this.setState({
                        unitSerialValidationError: "Invalid"
                    });    
                    return false;
                }
                break;
            default:
                this.setState({
                    unitSerialValidationError: "Invalid"
                });
                return false;
        }
        this.setState({unitSerialValidationError: null});
        return true;
    }

    submitSerialNumber = (unitSerial) => {
        this.props.updateOrderItemUnitSerial(this.props.item, unitSerial);
    }

    onInputChange = (event) => {
        const ravenUnitFromProps = this.props.item.ravenUnit || {};
        switch (event.target.name) {
            case "unitSerial":
                if (this.props.orderStatus !== ORDER_STATUS.PENDING_RAVENS) {
                    const ravenUnitFromProps = this.props.item.ravenUnit || {};
                    this.setState({unitSerial: ravenUnitFromProps.serial});
                    break;
                }
                this.setState({
                    unitSerial: event.target.value.toUpperCase().trim(),
                    unitSerialValidationError: null
                });
                if (this.serialNumberIsReadyForAutoSubmit(event.target.value)) {
                    if (this.validateSerialNumber(event.target.value)) {
                        this.submitSerialNumber(event.target.value);
                    }
                }
                break;
            case "iccid": this.setState({iccid: ravenUnitFromProps.iccid}); break; // disable changes, but in a way to allow selectAll+copy
            case "imei": this.setState({imei: ravenUnitFromProps.imei}); break; // disable changes, but in a way to allow selectAll+copy
            case "externalId": this.setState({externalId: this.props.item.externalId}); break; // disable changes, but in a way to allow selectAll+copy
            case "ratePlanName": this.setState({ratePlanId: this.props.item.ratePlanId}); break; // disable changes, but in a way to allow selectAll+copy
            default: console.error("ActiveOrdersTableOrderItem onInputChange invalid input name " + event.target.name);
        }
    }

    onExternalIdClick = (event) => {
        const ravenUnit = this.props.item.ravenUnit ? this.props.item.ravenUnit : {};
        if (!ravenUnit.serial) {
            this.setState({
                unitSerialValidationError: "Required"
            });
            setTimeout(() => {
                this.setState({
                    unitSerialValidationError: null
                });
            }, 1000);
            return;
        }
        if (!ravenUnit.iccid) {
            this.setState({
                iccidValidationError: "Required"
            });
            setTimeout(() => {
                this.setState({
                    iccidValidationError: null
                });
            }, 1000);
            return;
        }
        if (!ravenUnit.imei) {
            this.setState({
                imeiValidationError: "Required"
            });
            setTimeout(() => {
                this.setState({
                    imeiValidationError: null
                });
            }, 1000);
            return;
        }
        this.props.editOrderItemExternalId(this.props.item);
    }

    onRatePlanNameClick = (event) => {
        this.props.editOrderItemRatePlanId(this.props.item);
    }

    inputSelectAll = (event) => {

        const target = event.target;

        setTimeout(() => {
            if (!target) return;
            if(target.value.length === 0) return;
            target.select();
        }, 100); // helps ensure a single click select all
    }

    serialNumberStatusClass = () => {

        if (!this.props.item) return null; // not known

        if (this.props.item.errors && this.props.item.errors.unitSerial) {
            return "error";
        }

        if (!this.props.item.ravenUnit || !this.props.item.ravenUnit.serial) return null; // not present

        // TODO maybe basic validation (check format of the string) to return "error" if needed
        return "success";
    }

    simCardStatusClass = () => {

        if (!this.props.item) return null; // not known

        const ravenUnitFromProps = this.props.item.ravenUnit || {};

        if (this.props.item.errors) { 
            if (this.props.item.errors.iccid) {
                return "error";
            }
            if (this.props.item.errors.imei) {
                return "error";
            }
        }

        if (ravenUnitFromProps.iccid && ravenUnitFromProps.imei) {
            return "success"; // both present
        }

        if (ravenUnitFromProps.iccid || ravenUnitFromProps.imei) {
            if (!ravenUnitFromProps.ravenUnit.serial) return "error"; // one missing
        } 

        return null;
    }

    externalIdStatusClass = () => {

        if (!this.props.item) return null; // not known

        if (this.props.item.errors && this.props.item.errors.externalId) {
            return "error";
        }

        if (!this.props.item.externalId) return null; // not known

        // TODO maybe basic validation (check format of the string) to return "error" if needed
        return "success";
    }

    render () {

        if (this.props.item.itemType !== "RAVEN_PLUS") {
            if (this.props.item.itemType === "RAVEN") {
                console.warn("ActiveOrdersTableOrderItem does not support Raven Classic yet.  Omitting.");
            }
            return null;
        }

        const showEditOrderItemsFeatures = shouldShowEditOrderItemsFeatures(this.props.orderStatus);

        let unitSerial = null;
        const ravenUnitFromProps = this.props.item.ravenUnit || {};
        const unitSerialFromProps = ravenUnitFromProps.serial;
        let showDeleteUnitSerialButton = false;
        if (this.state.unitSerial) { // state takes precedence (user input)
            unitSerial = this.state.unitSerial;
        } else if (unitSerialFromProps) {
            if (this.state.unitSerial === "") {
                showDeleteUnitSerialButton = true;
            } else {
                unitSerial = this.props.item.ravenUnit.serial;
            }
        }
        const iccid = this.state.iccid? this.state.iccid : ravenUnitFromProps.iccid;
        const imei = this.state.imei? this.state.imei : ravenUnitFromProps.imei;
        const externalId = this.state.externalId? this.state.externalId : this.props.item.externalId;
        const ratePlanId = this.state.ratePlanId? this.state.ratePlanId : this.props.item.ratePlanId;
        let ratePlanName = ratePlanNameForId(this.props.ratePlans, ratePlanId);

        let inputSerialNumberAutoFocusCandidateProps = {};
        let iconSerialNumberStatusAutoFocusCandidateProps = {};
        let inputExternalIdAutoFocusCandidateProps = {};
        let iconExternalIdStatusAutoFocusCandidateProps = {};
        inputSerialNumberAutoFocusCandidateProps["data-order-id"] = this.props.orderId;
        inputExternalIdAutoFocusCandidateProps["data-order-id"] = this.props.orderId;

        if (!this.props.item.ravenUnit || !this.props.item.ravenUnit.serial) {
            inputSerialNumberAutoFocusCandidateProps[INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE] = true; // for parent component get-all using document.querySelectorAll('[INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE]');)
            inputSerialNumberAutoFocusCandidateProps.id = INPUT_SERIAL_NUMBER_AUTO_FOCUS_CANDIDATE_ID_PREFIX + this.props.item.orderItemId;
            iconSerialNumberStatusAutoFocusCandidateProps.id = inputSerialNumberAutoFocusCandidateProps.id +
                INPUT_AUTO_FOCUS_CANDIDATE_ID_CORRESPONDING_STATUS_ICON_SUFFIX;
        }
        if (!this.props.item.externalId) {
            inputExternalIdAutoFocusCandidateProps[INPUT_PROVIDER_ID_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE] = true; // for parent component get-all using document.querySelectorAll('[INPUT_PROVIDER_ID_AUTO_FOCUS_CANDIDATE_DATA_ATTRIBUTE]');)
            inputExternalIdAutoFocusCandidateProps.id = INPUT_PROVIDER_ID_AUTO_FOCUS_CANDIDATE_ID_PREFIX + this.props.item.orderItemId;
            iconExternalIdStatusAutoFocusCandidateProps.id = inputExternalIdAutoFocusCandidateProps.id +
                INPUT_AUTO_FOCUS_CANDIDATE_ID_CORRESPONDING_STATUS_ICON_SUFFIX;

        }

        const inputSerialNumber = <input
            name="unitSerial"
            type="text"
            autoComplete="off"
            placeholder="Raven Unit"
            value={unitSerial ? unitSerial : ""}
            onChange={this.onInputChange}
            onFocus={this.inputSelectAll}
            {...inputSerialNumberAutoFocusCandidateProps}
        />;
        const iconSerialNumberStatus = <img
            src={supporttoolIconRavenUnit}
            className={this.serialNumberStatusClass()}
            {...iconSerialNumberStatusAutoFocusCandidateProps}
            title="Serial Number"
        />;

        const inputExternalId = <input
            name="externalId"
            type="text"
            autoComplete="off"
            value={externalId ? externalId : ""}
            disabled={this.props.orderStatus !== ORDER_STATUS.PENDING_SIM_DETAILS}
            onChange={this.onInputChange}
            onFocus={this.inputSelectAll}
            onClick={this.onExternalIdClick}
            {...inputExternalIdAutoFocusCandidateProps}
        />;
        const iconExternalIdStatus = <img
            src={supporttoolIconSimServiceActivated}
            className={this.externalIdStatusClass()}
            {...iconExternalIdStatusAutoFocusCandidateProps}
            title="Provider Ready"
        />;

        const inputIccid = <input name="iccid" type="text" autoComplete="off" value={iccid? iccid : ""} disabled={iccid? false : true} onChange={this.onInputChange} onClick={this.inputSelectAll} />;
        const inputImei = <input name="imei" type="text" autoComplete="off" value={imei? imei : ""} disabled={imei? false : true} onChange={this.onInputChange} onClick={this.inputSelectAll} />;
        const inputRatePlanName = <input
            name="ratePlanName"
            type="text"
            autoComplete="off"
            value={ratePlanName? ratePlanName : ""}
            disabled={!ratePlanName? true : false}
            onChange={this.onInputChange}
            onClick={this.onRatePlanNameClick}
        />;

        let serialNumberClass = "serial-number";
        if (this.props.orderStatus !== ORDER_STATUS.PENDING_RAVENS) {
            serialNumberClass += " read-only";
        }
        let serialNumberError = undefined;
        let iccidClass = "iccid read-only";
        let iccidError = undefined;
        let imeiClass = "imei read-only";
        let imeiError = undefined;
        let externalIdClass = "provider-id";
        let externalIdError = undefined;
        let ratePlanNameClass = "rate-plan-name";
        let ratePlanNameError = undefined;
        let deleteItemClass = "delete-item";
        let deleteItemError = undefined;
        if (this.props.item && this.props.item.errors) {
            if (this.props.item.errors.unitSerial) {
                serialNumberClass += " error";
                serialNumberError = this.props.item.errors.unitSerial;
            }
            if (this.props.item.errors.iccid) {
                iccidClass += " error";
                iccidError = this.props.item.errors.iccid;
            }
            if (this.props.item.errors.imei) {
                imeiClass += " error";
                imeiError = this.props.item.errors.imei;
            }
            if (this.props.item.errors.externalId) {
                externalIdClass += " error";
                externalIdError = this.props.item.errors.externalId;
            }
            if (this.props.item.errors.ratePlanId) { {/* TODO placeholder/mockup value */}
                ratePlanNameClass += " error";
                ratePlanNameError = this.props.item.errors.ratePlanId;
            }
            if (this.props.item.errors.delete) { {/* TODO placeholder/mockup value */}
                deleteItemClass += " error";
                deleteItemError = this.props.item.errors.ratePlanId;
            }
        }
        if (this.state.unitSerialValidationError) {
            serialNumberClass += " error";
            serialNumberError = this.state.unitSerialValidationError;
        }
        if (this.state.iccidValidationError) {
            iccidClass += " error";
            iccidError = this.state.iccidValidationError;
        }
        if (this.state.imeiValidationError) {
            imeiClass += " error";
            imeiError = this.state.imeiValidationError;
        }

        return (
            <div className="row item">
                <div className={serialNumberClass}>
                    {inputSerialNumber}
                    {showDeleteUnitSerialButton ?
                        <div className="delete-item">
                            <button type="button" onClick={() => {this.submitSerialNumber("")}} >Apply (Delete S/N)</button>
                            <ul className="error-messages">
                                {/*true ? <li className="error-message">{delete request error}</li> : null*/}
                            </ul>
                        </div>
                    :
                        null
                    }
                    <ul className="error-messages">
                    {serialNumberError ? (<li className="error-message">{serialNumberError}</li>) : null }
                    </ul>
                    {this.props.item.pending ? <ProgressOverlay /> : null }
                </div>
                <div className={iccidClass}>
                    {inputIccid}
                    <ul className="error-messages">
                    {iccidError ? (<li className="error-message">{iccidError}</li>) : null }
                    </ul>
                    {this.props.item.pending ? <ProgressOverlay /> : null }
                </div>
                <div className={imeiClass}>
                    {inputImei}
                    <ul className="error-messages">
                    {imeiError ? (<li className="error-message">{imeiError}</li>) : null }
                    </ul>
                    {this.props.item.pending ? <ProgressOverlay /> : null }
                </div>
                <div className={externalIdClass}>
                    {inputExternalId}
                    <ul className="error-messages">
                    {externalIdError ? (<li className="error-message">{externalIdError}</li>) : null }
                    </ul>
                    {this.props.item.pending ? <ProgressOverlay /> : null }
                </div>
                <div className={ratePlanNameClass}>
                    {inputRatePlanName}
                    <ul className="error-messages">
                    {ratePlanNameError ? (<li className="error-message">{ratePlanNameError}</li>) : null }
                    </ul>
                    {this.props.item.pending ? <ProgressOverlay /> : null }
                </div>
                <div className={deleteItemClass}>
                    {showEditOrderItemsFeatures ?
                        <>
                        <button type="button" onClick={() => {this.props.onRemoveOrderItemClick(this.props.item.orderItemId)}}>delete</button>
                        <ul className="error-messages">
                        {deleteItemError ? (<li className="error-message">{deleteItemError}</li>) : null }
                        </ul>
                        {this.props.item.pending ? <ProgressOverlay /> : null }
                        </>
                    :
                        null
                    }
                </div>
                <div className="order-status">
                    {iconSerialNumberStatus}
                    <img src={supporttoolIconSimCard} className={this.simCardStatusClass()} title="SIM Card"/>
                    {iconExternalIdStatus}
                    {this.props.item.pending ? <ProgressOverlay /> : null }
                </div>
            </div>
        )
    }
}
